import {Draft} from 'immer'
import {cloneDeep} from 'lodash-es'

import {
  APITranscript,
  APITranscriptSlice,
  APITranscriptToken,
  TranscriptEleType,
  TranscriptSelection,
} from '../types/types'

import getDefaultTranscript from './getDefaultTranscript'

/**
 * Returns the total duration of the transcript from zero to the end of the latest-ocurring slice
 * NOTE: Slices do not have to be in order and their bounds do not have to be contiguous.
 */
export function getDuration(transcript: APITranscript): number {
  return transcript.sliceMeta.reduce(
    (acc, slice) => Math.max(acc, slice.startMs + slice.durationMs),
    0,
  )
}

/**
 * Mark tokens in the partial slice as partial and recent if applicable.
 *
 * the AddTranscript message adds an isPartial prop to the slice but this needs to be
 * propagated to the tokens for styling. isRecent is added to the tokens that were not
 * in the prior partial slice
 *
 */
export function markRecentPartialSlice(
  newSlice: APITranscriptSlice,
  previousSlice?: APITranscriptSlice,
): APITranscriptSlice {
  if (!newSlice.isPartial) return newSlice

  const markedSlice = cloneDeep(newSlice)
  const previousSliceLength = previousSlice ? previousSlice.tokenMeta.length : 0
  markedSlice.tokenMeta.forEach((_, i) => {
    markedSlice.tokenMeta[i].isPartial = true
    if (previousSliceLength && i >= previousSliceLength) {
      markedSlice.tokenMeta[i].isRecent = true
    }
  })
  return markedSlice
}

/** Immutably append a new slice to a transcript */
export function appendTranscriptSlice(
  transcript: APITranscript | undefined,
  slice: APITranscriptSlice,
): APITranscript {
  if (!transcript)
    return {
      ...getDefaultTranscript(),
      sliceMeta: [slice],
      transcript: slice.transcript,
    }

  const nextTranscript = {
    ...transcript,
    sliceMeta: [...transcript.sliceMeta],
  }

  // remove an existing partial slice
  let removedPartialSlice: APITranscriptSlice | undefined
  if (nextTranscript.sliceMeta[nextTranscript.sliceMeta.length - 1]?.isPartial) {
    removedPartialSlice = nextTranscript.sliceMeta.pop()
    if (removedPartialSlice) {
      nextTranscript.transcript = nextTranscript.transcript
        .substring(0, nextTranscript.transcript.lastIndexOf(removedPartialSlice.transcript))
        .trim()
    }
  }

  // mark the slice tokens as recent or partial if applicable
  const newSlice = markRecentPartialSlice(slice, removedPartialSlice)

  // if the slice is completely empty, add a placeholder token
  if (newSlice.tokenMeta.length === 0) {
    newSlice.tokenMeta.push({
      transcript: ' ',
      startMs: newSlice.startMs,
      durationMs: 1,
      accuracy: 1,
    })
    newSlice.transcript = ' '
  }

  // append the new slice
  nextTranscript.sliceMeta.push(newSlice)

  // update full text transcript key
  nextTranscript.transcript = `${nextTranscript.transcript} ${newSlice.transcript}`

  // add to speakers if not already present
  if (!nextTranscript.speakers[newSlice.speakerId]) {
    nextTranscript.speakers[newSlice.speakerId] = {
      name: `Speaker ${newSlice.speakerId}`,
    }
  }

  return nextTranscript
}

/** Check if the transcript has no transcribed speech */
export function hasNoSpeech(transcript?: APITranscript): boolean {
  return !transcript || !transcript?.sliceMeta.some((slice) => slice.transcript.trim())
}

/** Check if the time bounds of the slice contain the timestamp */
export function isTimeInSlice(timeMs: number | undefined, slice: APITranscriptSlice): boolean {
  return (
    timeMs !== undefined && slice.startMs <= timeMs && slice.startMs + slice.durationMs > timeMs
  )
}

/**
 * Convert transcript to pre-formatted text that looks like:
 *
 * @returns
 * ```
 * Speaker 0
 * Hi, my name is John Smith.
 *
 * Speaker 1
 * Hi John, I am Jane Doe.
 * ```
 */
export function prettyText(transcript?: APITranscript): string {
  if (!transcript) return ''

  const {speakers} = transcript

  return transcript.sliceMeta.reduce((acc, {speakerId, transcript: text}) => {
    // skip empty slice
    if (!text.trim()) return acc
    // append next slice text with speaker label
    return `${acc}${speakers[speakerId].name}\n${text}\n\n`
  }, '')
}

/**
 * Convert a JSON pointer to token location object
 *
 * @example
 * indexFromPath('/slice_meta/1/token_meta/2') => {sliceIndex: 1, tokenIndex: 2}
 */
export function indexFromPath(path: string): {sliceIndex: number; tokenIndex: number} {
  const match = path.match(
    /^\/?(?:sliceMeta|slice_meta){1}\/(\d+)\/(?:tokenMeta|token_meta){1}\/(\d+)/,
  )
  if (!match) throw new Error(`indexFromPath mismatch: ${path}`)

  return {sliceIndex: parseInt(match[1], 10), tokenIndex: parseInt(match[2], 10)}
}

/**
 * Get the token at the specified time. When `closest=true` and the time does not fall
 * within a token, the closest token will be returned.
 */
export function getTokenAtTime(
  transcript: APITranscript,
  timeMs: number,
  closest?: boolean,
): [token: APITranscriptToken, tokenIndex: number, sliceIndex: number] | null
export function getTokenAtTime(
  transcriptBySpeaker: APITranscriptSlice[],
  timeMs: number,
  closest?: boolean,
): [token: APITranscriptToken, tokenIndex: number, sliceIndex: number] | null
export function getTokenAtTime(
  transcriptOrSliceMeta: APITranscript | APITranscriptSlice[],
  timeMs: number,
  closest = true,
): [token: APITranscriptToken, tokenIndex: number, sliceIndex: number] | null {
  const transcriptBySpeaker = Array.isArray(transcriptOrSliceMeta)
    ? transcriptOrSliceMeta
    : transcriptOrSliceMeta.sliceMeta

  if (
    !transcriptBySpeaker.length ||
    transcriptBySpeaker.every((slice) => slice.tokenMeta.length === 0)
  )
    return null

  // find the current slice index
  let currentSliceIndex = 0
  let currentSlice = transcriptBySpeaker[currentSliceIndex]
  while (
    currentSlice.startMs + currentSlice.durationMs < timeMs &&
    currentSliceIndex < transcriptBySpeaker.length - 1
  ) {
    currentSliceIndex += 1
    currentSlice = transcriptBySpeaker[currentSliceIndex]
  }

  const prevSlice = transcriptBySpeaker[Math.max(0, currentSliceIndex - 1)]
  // if the time falls between two slices use the slice that is closer
  if (timeMs - (prevSlice.startMs + prevSlice.durationMs) < currentSlice.startMs - timeMs) {
    if (closest) {
      currentSlice = prevSlice
      currentSliceIndex = Math.max(0, currentSliceIndex - 1)
    } else {
      return null
    }
  }

  // find the current token index
  let currentTokenIndex = 0
  let currentToken = currentSlice.tokenMeta[currentTokenIndex]
  if (!currentToken) return null
  while (
    currentToken.startMs + currentToken.durationMs < timeMs &&
    currentTokenIndex < currentSlice.tokenMeta.length - 1
  ) {
    currentTokenIndex += 1
    currentToken = currentSlice.tokenMeta[currentTokenIndex]
  }

  const prevToken =
    transcriptBySpeaker[currentSliceIndex].tokenMeta[Math.max(0, currentTokenIndex - 1)]

  if (!prevToken && !currentToken) return null

  // if the time falls between two tokens use the token that is closer
  if (
    prevToken &&
    timeMs - (prevToken.startMs + prevToken.durationMs) < currentToken.startMs - timeMs
  ) {
    if (closest) {
      currentToken = prevToken
    } else {
      return null
    }
  }

  return [currentToken, currentTokenIndex, currentSliceIndex]
}

/**
 * Creates a slashy string path from a slice and optional token index
 *
 * @returns 'sliceMeta/1/tokenMeta/2'
 */
export function toTranscriptPath(sliceIndex: number, tokenIndex?: number): string {
  return `sliceMeta/${sliceIndex}${tokenIndex !== undefined ? `/tokenMeta/${tokenIndex}` : ''}`
}

/** Extract either the anchorNode or focusNode from window.selection based on LTR reading direction */
function normalizedSelectionNode(
  selection: Selection,
  position: 'start' | 'end' = 'end',
): {selectionNode: Node | null; selectionOffset: number | null} {
  if (!selection.anchorNode || !selection.focusNode)
    return {selectionNode: null, selectionOffset: null}

  // user can select backwards or forwards, so figure out which direction to choose the
  // correct focus/anchor node respectively for ltr reading direction
  const documentPosition = selection.anchorNode.compareDocumentPosition(selection.focusNode)
  let leftToRight = false
  // documentPosition == 0 if nodes are the same
  if (
    (!documentPosition && selection.anchorOffset > selection.focusOffset) ||
    documentPosition === Node.DOCUMENT_POSITION_PRECEDING
  )
    leftToRight = true

  let selectionNode: Node
  let selectionOffset: number
  if ((leftToRight && position === 'start') || (!leftToRight && position === 'end')) {
    selectionNode = selection.focusNode
    selectionOffset = selection.focusOffset
  } else {
    selectionNode = selection.anchorNode
    selectionOffset = selection.anchorOffset
  }

  return {selectionNode, selectionOffset}
}

/**
 * Gets the nearest transcript element, any element that has a data-type ('token', 'token-space, 'slice'), from a selection.
 * Since the selection can be a range, the position option determines whether to get the path
 * for the element at the start or end of the range.
 *
 * @param selection window.selection
 * @param position 'start' or 'end' of the selection as based on LTR reading direction. Defaults to 'end'
 * @returns Nearest html element with a transcript type data attribute
 */
export function getNearestTranscriptEleFromSelection(
  selection: Selection,
  position: 'start' | 'end' = 'end',
): HTMLElement | null {
  if (!selection.anchorNode || !selection.focusNode) return null

  const {selectionNode} = normalizedSelectionNode(selection, position)
  if (!selectionNode) return null

  let selectionEle = selectionNode.parentElement
  while (selectionEle && !selectionEle.dataset.type) {
    selectionEle = selectionEle.parentElement
  }
  return selectionEle
}

/**
 * Get the token path from a selection including the text character offset.
 * Since the selection can be a range, the position option determines whether to get the path
 * for the token at the start or end of the range.
 * NOTE: the selection is still valid if it borders a token but is within a token-space.
 *
 * @param selection window.selection
 * @param position 'start' or 'end' of the selection as based on LTR reading direction. Defaults to 'end'
 * @returns ['sliceMeta', '1', 'tokenMeta', '2', '0'] or null if the selection is not a token
 */
export function getTokenPathFromSelection(
  selection: Selection,
  position: 'start' | 'end' = 'end',
): string[] | null {
  if (!selection.anchorNode || !selection.focusNode) return null

  const normalizedSelection = normalizedSelectionNode(selection, position)
  const {selectionNode} = normalizedSelection
  const {selectionOffset} = normalizedSelection

  // we're assuming the selection node is always a text node within a token or token-space
  if (!selectionNode || selectionNode.nodeType !== Node.TEXT_NODE) return null
  let selectionEle = selectionNode.parentElement
  if (!selectionEle) return null
  const {type} = selectionEle?.dataset || {}
  if (!(type === 'token-space' || type === 'token')) return null

  // if the selection is a token-space, we need to get the token path from the previous token
  if (type === 'token-space') {
    selectionEle = selectionEle.previousElementSibling as HTMLElement
  }

  const tokenPath = [...(selectionEle.dataset.path || '').split('/')]
  if (!tokenPath.length) return null
  tokenPath.push(`${selectionOffset}`)

  return tokenPath
}

/**
 * Reads from window.getSelection() to return an abstracted representation of the selection
 * within the transcript
 *
 * If the selection is a caret, the start and end will be the same
 * If the selection is not within the transcript, returns null
 */
export function getTranscriptSelection(): TranscriptSelection | null {
  const windowSelection = window.getSelection()

  if (!windowSelection) return null

  const transcriptSelection: TranscriptSelection = {
    type: windowSelection?.type === 'Range' ? 'Range' : 'Caret',
    start: null,
    end: null,
  }

  const positions: ('start' | 'end')[] = ['start', 'end']
  positions.forEach((position) => {
    if (transcriptSelection.type === 'Caret' && position === 'end') {
      transcriptSelection.end = cloneDeep(transcriptSelection.start)
      return
    }

    const tokenPath = getTokenPathFromSelection(windowSelection, position)
    const selectionEle = getNearestTranscriptEleFromSelection(windowSelection, position)
    let selectionType = selectionEle?.dataset.type as TranscriptEleType
    let sliceIndex = tokenPath ? parseInt(tokenPath[1], 10) : 0
    let tokenIndex = tokenPath ? parseInt(tokenPath[3], 10) : 0
    let textOffset = tokenPath ? parseInt(tokenPath[4], 10) : 0

    // if we don't have an exact token path but we do have a selected slice
    // we can set to the beginning of the slice
    if (!tokenPath && selectionEle) {
      selectionType = 'token'
      sliceIndex = parseInt(selectionEle.dataset.path?.split('/')[1] || '', 10)
      tokenIndex = 0
      textOffset = 0
    }
    if (!selectionType) return

    transcriptSelection[position] = {
      type: selectionType,
      sliceIndex,
      tokenIndex,
      textOffset,
    }
  })

  return transcriptSelection
}

/**
 * Gets the plain text from all the tokens and slices within the transcript selection.
 * Slices will be separated by newlines (\n)
 */
export function getTextFromTranscriptSelection(
  transcript: APITranscript,
  transcriptSelection: TranscriptSelection | null,
): string {
  if (!transcriptSelection) return ''

  const {start, end, type} = transcriptSelection
  if (!start || !end || type === 'Caret') return ''

  const {
    sliceIndex: startSliceIndex,
    tokenIndex: startTokenIndex,
    textOffset: startTextOffset,
    type: startType,
  } = start
  const {
    sliceIndex: endSliceIndex,
    tokenIndex: endTokenIndex,
    textOffset: endTextOffset,
    type: endType,
  } = end

  // selection is within a single token
  // t[oken]1 -> oken
  // [token1] -> token1
  if (
    startSliceIndex === endSliceIndex &&
    startTokenIndex === endTokenIndex &&
    startType === 'token' &&
    endType === 'token'
  ) {
    const token = transcript.sliceMeta[startSliceIndex].tokenMeta[startTokenIndex]
    return token.transcript.slice(startTextOffset, endTextOffset)
  }

  // selection is a single space
  // token1[ ]token2 -> ' '
  if (
    startType === 'token-space' &&
    endType === 'token-space' &&
    startTokenIndex === endTokenIndex &&
    startSliceIndex === endSliceIndex &&
    startTextOffset === 0 &&
    endTextOffset === 1
  ) {
    return ' '
  }

  let text = ''
  try {
    for (let sliceIndex = startSliceIndex; sliceIndex <= endSliceIndex; sliceIndex += 1) {
      const currentSlice = transcript.sliceMeta[sliceIndex]

      for (
        let tokenIndex = startSliceIndex === sliceIndex ? startTokenIndex : 0;
        tokenIndex <
        (sliceIndex === endSliceIndex ? endTokenIndex + 1 : currentSlice.tokenMeta.length);
        tokenIndex += 1
      ) {
        const currentToken = currentSlice.tokenMeta[tokenIndex]

        if (
          sliceIndex === startSliceIndex &&
          tokenIndex === startTokenIndex &&
          startType === 'token-space'
        ) {
          text += `${startTextOffset === 0 ? ' ' : ''}`
        } else if (
          sliceIndex === endSliceIndex &&
          tokenIndex === endTokenIndex &&
          endType === 'token-space'
        ) {
          text +=
            ((startTokenIndex !== endTokenIndex || startSliceIndex !== endSliceIndex) &&
            tokenIndex > 0 &&
            startType !== 'token-space'
              ? ' '
              : '') +
            (startSliceIndex === endSliceIndex && startTokenIndex === endTokenIndex
              ? currentToken.transcript.slice(startTextOffset)
              : currentToken.transcript) +
            (endTextOffset === 1 ? ' ' : '')
        } else if (sliceIndex === startSliceIndex && tokenIndex === startTokenIndex) {
          text += currentToken.transcript.slice(startTextOffset)
        } else if (sliceIndex === endSliceIndex && tokenIndex === endTokenIndex) {
          text += `${(startTokenIndex !== endTokenIndex || startSliceIndex !== endSliceIndex) && tokenIndex > 0 ? ' ' : ''}${currentToken.transcript.slice(
            0,
            endTextOffset,
          )}`
        } else {
          text += `${tokenIndex > 0 ? ' ' : ''}${currentToken.transcript}`
        }
      }

      if (sliceIndex !== endSliceIndex && startSliceIndex !== endSliceIndex) text += '\n'
    }
  } catch (e) {
    // ignore errors, probably from a selection that does not match the underlying transcript
    // return as much valid text as we can
  }

  return text
}

export interface IndexedToken {
  token: APITranscriptToken
  sliceIndex: number
  tokenIndex: number
}
/**
 * Returns a flat array with all the token values and ids (sliceIndex + tokenIndex) for a given token range to allow for
 * easier iteration across slice boundaries
 * { startToken: {sliceIndex: 0; tokenIndex: 1}, endToken: {sliceIndex: 1; tokenIndex: 1} }
 * will return the indexes and values of the tokens located at [0,1], [0,2], [1,0], [1, 1]
 */
export function getTokenRange(
  transcript: APITranscript | Draft<APITranscript>,
  range: {
    startToken: {sliceIndex: number; tokenIndex: number}
    endToken: {sliceIndex: number; tokenIndex: number}
  },
): IndexedToken[] {
  const flat: IndexedToken[] = []
  const {startToken, endToken} = range

  for (let {sliceIndex} = startToken; sliceIndex <= endToken.sliceIndex; sliceIndex += 1) {
    const currentSlice = transcript.sliceMeta[sliceIndex]

    for (
      let tokenIndex = startToken.sliceIndex === sliceIndex ? startToken.tokenIndex : 0;
      tokenIndex <
      (sliceIndex === endToken.sliceIndex
        ? endToken.tokenIndex + 1
        : currentSlice.tokenMeta.length);
      tokenIndex += 1
    ) {
      flat.push({token: currentSlice.tokenMeta[tokenIndex], sliceIndex, tokenIndex})
    }
  }
  return flat
}
