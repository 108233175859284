import {useState, useEffect} from 'react'

/**
 * appends a div to document.body to be used as a portal container
 * will remove the div on unmount or when enabled = false
 */
export default function usePortalContainer(enabled = true): HTMLDivElement | null {
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    let container: HTMLDivElement
    if (enabled) {
      container = document.createElement('div')
      container.className = 'popover-container'
      document.body.appendChild(container)
      setPortalContainer(container)
    }

    return () => {
      try {
        if (container) document.body.removeChild(container)
      } catch (e) {
        // ignore
      }

      setPortalContainer(null)
    }
  }, [enabled])

  return portalContainer
}
