/**
 * Generate a pretty timestamp from milliseconds
 *
 * @example formatTimestamp(1500) // 0:01.500
 * @example formatTimestamp(60000) // 1:00
 * @example formatTimestamp(60000, true) // 1:00.000
 * @example formatTimestamp(3600000) // 1:00:00
 */
export function formatTimestamp(timeMs: number, showMs = true): string {
  let milliseconds = timeMs
  const hours = Math.floor(milliseconds / (1000 * 60 * 60))
  milliseconds %= 1000 * 60 * 60
  const minutes = Math.floor(milliseconds / (1000 * 60))
  milliseconds %= 1000 * 60
  const seconds = Math.floor(milliseconds / 1000)
  milliseconds %= 1000

  const hoursStr = hours < 10 ? `0${hours}` : hours
  const minutesStr = hours > 0 && minutes < 10 ? `0${minutes}` : minutes
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds
  const millisecondsStr = milliseconds.toString().padStart(3, '0')

  return `${hours > 0 ? `${hoursStr}:` : ''}${minutesStr}:${secondsStr}${showMs ? `.${millisecondsStr}` : ''}`
}
