import {Button, InputGroup} from '@kensho/neo'
import {cloneDeep, throttle} from 'lodash-es'
import {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useLogger} from '@kensho/lumberjack'

import {APITranscript, SpeakerInfo} from '../../../../../types/types'
import {produceOperation} from '../../../../../utils/transcriptPatchUtils'
import {useDispatchEditOperation} from '../../DispatchEditOperationProvider'
import {TranscriptPermissionsContext} from '../../../TranscriptPermissionsProvider'

interface Props {
  id?: number
  speaker?: SpeakerInfo
  transcript: APITranscript
  onClose: () => void
}

export default function SpeakerEditor(props: Props): React.ReactNode {
  const {id, speaker, transcript, onClose} = props
  const {dispatchEditOperation} = useDispatchEditOperation()
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const log = useLogger()
  const [draftSpeaker, setDraftSpeaker] = useState(() =>
    speaker ? cloneDeep(speaker) : {name: ''},
  )
  const [duplicateSpeakerName, setDuplicateSpeakerName] = useState(false)
  const [touched, setTouched] = useState<Partial<Record<keyof SpeakerInfo, boolean>>>({})
  const [validation, setValidation] = useState<Partial<Record<keyof SpeakerInfo, string>>>({})
  const canSave = Object.keys(touched).length > 0 && Object.keys(validation).length === 0

  const validateThrottled = useMemo(
    () =>
      throttle((draft: SpeakerInfo) => {
        let nextValidation: Partial<Record<keyof SpeakerInfo, string>> = {}
        if (!draft.name || draft.name.trim() === '') {
          nextValidation = {...nextValidation, name: 'Name is required'}
        }

        setValidation(nextValidation)
      }, 1000),
    [],
  )

  const checkDuplicateSpeakerNameThrottled = useMemo(
    () =>
      throttle(
        (name = '') =>
          setDuplicateSpeakerName(
            Object.entries(transcript.speakers).some(
              ([speakerId, transcriptSpeaker]) =>
                speakerId !== `${id}` &&
                transcriptSpeaker.name.trim().toLowerCase() === name.trim().toLowerCase(),
            ),
          ),
        1000,
      ),
    [transcript.speakers, id],
  )

  const onSave = useCallback(() => {
    if (!transcript) return
    if (!transcriptPermissions.edit) return
    if (!canSave) return

    const nextSpeaker = {
      name: draftSpeaker.name.trim(),
      // if input is cleared out, set the role field to undefined instead of assigning an empty string
      ...(draftSpeaker.role && !!draftSpeaker.role.trim() && {role: draftSpeaker.role.trim()}),
    }

    if (id === undefined) {
      // create new speaker
      dispatchEditOperation(
        produceOperation({
          action: {type: 'add-speaker', data: nextSpeaker},
          transcript,
          transcriptSelection: null,
          log,
        }),
      )
    } else {
      // edit existing speaker
      dispatchEditOperation(
        produceOperation({
          action: {type: 'update-speaker', speakerId: id, data: nextSpeaker},
          transcript,
          transcriptSelection: null,
          log,
        }),
      )
    }

    setTouched({})
    setValidation({})
    setDuplicateSpeakerName(false)
    onClose()
  }, [
    transcript,
    transcriptPermissions,
    dispatchEditOperation,
    id,
    draftSpeaker,
    log,
    onClose,
    canSave,
  ])

  const onCancel = useCallback(() => {
    setTouched({})
    setValidation({})
    setDuplicateSpeakerName(false)
    setDraftSpeaker(speaker ? cloneDeep(speaker) : {name: ''})
    onClose()
  }, [speaker, onClose])

  useEffect(() => {
    if (Object.keys(touched).length === 0) return
    validateThrottled(draftSpeaker)
    checkDuplicateSpeakerNameThrottled(draftSpeaker.name)
  }, [draftSpeaker, validateThrottled, checkDuplicateSpeakerNameThrottled, touched])

  return (
    <div
      className="flex w-72 flex-col gap-4 px-6 py-8"
      onKeyDown={(event) => {
        if (event.key === 'Enter') onSave()
      }}
    >
      <InputGroup
        autoFocus
        label="Speaker name"
        isInvalid={!!(touched.name && validation.name)}
        errorText={validation.name}
        value={draftSpeaker.name}
        description={
          duplicateSpeakerName && touched.name ? 'This speaker already exists.' : undefined
        }
        onChange={(event) => {
          setTouched({...touched, name: true})
          setDraftSpeaker({...draftSpeaker, name: event.target.value})
        }}
      />
      <InputGroup
        label="Speaker role"
        isInvalid={!!validation.role}
        errorText={validation.role}
        value={draftSpeaker.role || ''}
        onChange={(event) => {
          setTouched({...touched, role: true})
          setDraftSpeaker({...draftSpeaker, role: event.target.value})
        }}
      />

      <footer className="mt-8 flex justify-end gap-4">
        <Button minimal onClick={onCancel}>
          Cancel
        </Button>
        <Button
          intent="primary"
          onClick={onSave}
          disabled={!canSave || !transcriptPermissions.edit}
        >
          Save
        </Button>
      </footer>
    </div>
  )
}
