import {USER_COLORS, UserColor} from '../core/transcription/constants'

export default function getUserColor(userId: string): UserColor {
  let hash = ''
  for (let i = 0; i < userId.length; i += 1) {
    hash += userId.charCodeAt(i)
  }
  const colorIndex = parseInt(hash, 10) % USER_COLORS.length
  return USER_COLORS[colorIndex]
}
