import {useRef, useCallback} from 'react'

/**
 * Hook for managing a monotonically increasing sequence of numbers.
 *
 * @returns [getNextValue, reset] Function for getting the next sequence value and for resetting the sequence to 0
 */
export default function useSequence(): [() => number, () => void] {
  const sequenceRef = useRef(0)
  const getNextValue = useCallback(() => {
    const value = sequenceRef.current
    sequenceRef.current += 1
    return value
  }, [])

  const resetValue = useCallback(() => {
    sequenceRef.current = 0
  }, [])

  return [getNextValue, resetValue]
}
