import {useContext, useMemo} from 'react'

import {APITranscriptToken, TranscriptSelection} from '../../../types/types'
import Popover from '../../../components/Popover'
import useClickOutside from '../../../hooks/useClickOutside'
import SpellCheckContext from '../../../providers/SpellCheckContext'
import {DICTIONARIES} from '../../account/dictionaries'

import ReviewSpelling from './review/ReviewSpelling'

interface Props {
  sliceIndex: number
  tokenIndex: number
  token: APITranscriptToken
  onClose: () => void
}

function TokenPopover(props: Props): React.ReactNode {
  const {sliceIndex, tokenIndex, token, onClose} = props
  const clickOutsideRef = useClickOutside(() => onClose())
  const spellCheck = useContext(SpellCheckContext)
  const dictionaryResource = DICTIONARIES.find((d) => d.langCode === spellCheck.langCode)

  const transcriptSelection = useMemo<TranscriptSelection>(
    () => ({
      type: 'Range',
      start: {
        type: 'token',
        sliceIndex,
        tokenIndex,
        textOffset: 0,
      },
      end: {
        type: 'token',
        sliceIndex,
        tokenIndex,
        textOffset: token.transcript.length,
      },
    }),
    [sliceIndex, tokenIndex, token],
  )

  return (
    <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0">
      <Popover
        target={<div />}
        isOpen
        onClose={onClose}
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 25],
            },
          },
        ]}
      >
        <div ref={clickOutsideRef}>
          <div className="flex h-[260px] w-[380px] flex-col">
            <header className="flex h-12 items-center justify-between border-b p-4">
              <h2>Spelling ({dictionaryResource?.label} dictionary)</h2>
            </header>
            <ReviewSpelling
              transcriptSelection={transcriptSelection}
              onComplete={() => onClose()}
            />
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default TokenPopover
