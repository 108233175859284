import {createContext, useContext, ReactNode} from 'react'

import {EditorOperation} from '../../../types/types'

type DispatchEditOperationType = (operation: EditorOperation, undoable?: boolean) => void

interface EditOperationProviderProps {
  children: ReactNode
  dispatchEditOperation: DispatchEditOperationType
}

const DispatchEditOperationContext = createContext<DispatchEditOperationType | null>(null)

export function DispatchEditOperationProvider({
  children,
  dispatchEditOperation,
}: EditOperationProviderProps): ReactNode {
  return (
    <DispatchEditOperationContext.Provider value={dispatchEditOperation}>
      {children}
    </DispatchEditOperationContext.Provider>
  )
}

export const useDispatchEditOperation = (): {
  dispatchEditOperation: DispatchEditOperationType
} => {
  const dispatchEditOperation = useContext(DispatchEditOperationContext)
  if (dispatchEditOperation === null) {
    throw new Error('useDispatchEditOperation must be used within a EditOperationProvider')
  }
  return {dispatchEditOperation}
}
