const TIME_FORMAT = Intl.DateTimeFormat('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
})

const DATE_FORMAT = Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
})

/** convert datetime string to format "h:mm A, MM/DD/YYYY" */
export default function prettyDateTime(isoTime: string): string {
  const date = new Date(Date.parse(isoTime))
  return `${TIME_FORMAT.format(date)}, ${DATE_FORMAT.format(date)}`
}
