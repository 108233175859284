import {cloneDeep, omit} from 'lodash-es'
import {useCallback, useMemo, useState} from 'react'

import {API_ENDPOINT} from '../constants'
import {
  AsyncStatus,
  BatchHumanTranscriptionConfiguration,
  BatchMachineTranscriptionConfiguration,
} from '../types/types'
import parseError from '../utils/parseError'

import useAPIFetch from './useAPIFetch'

interface UploadBatchOptions {
  file: File
  /* TODO: v2 API config options */
  options?: BatchMachineTranscriptionConfiguration | BatchHumanTranscriptionConfiguration
}

type UploadBatchRequest = [
  status: AsyncStatus,
  execute: (options: UploadBatchOptions) => Promise<string>,
  cancel: () => void,
]

/**
 * Submit a file for batch transcription via POST multipart/form-data
 * Returns a unique ID for the submitted transcription job
 */
export default function useUploadBatch(): UploadBatchRequest {
  const apiFetch = useAPIFetch()
  const [status, setStatus] = useState<AsyncStatus>('idle')

  const uploadBatch = useCallback(
    ({file, options}: UploadBatchOptions) => {
      setStatus('pending')

      const formData = new FormData()
      formData.append('media', file)

      const serializableOptions = cloneDeep(omit(options, ['hotwords']))

      let hotwords = {}
      if (options && 'hotwords' in options) {
        hotwords = {
          hotwords: [...(options.hotwords || [])],
        }
      }

      formData.append('options', JSON.stringify({...serializableOptions, ...hotwords}))

      return apiFetch
        .execute(`${API_ENDPOINT}/transcription`, {
          method: 'POST',
          body: formData,
        })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((r) => {
              let parsedError
              try {
                parsedError = parseError(r)
              } catch {
                // if parsing the JSON fails, throw the status code instead
                throw parseError(response.status)
              }

              throw parsedError
            })
          }

          return response.json()
        })
        .then((response: {transcription_id: string}) => {
          setStatus('success')
          return response.transcription_id
        })
        .catch((error: Error | DOMException) => {
          if ((error as DOMException).name === 'AbortError') throw error
          setStatus('error')
          const clientError = parseError(error)
          throw clientError
        })
    },
    [apiFetch],
  )

  const cancel = useCallback(() => {
    apiFetch.cancel()
    setStatus('idle')
  }, [apiFetch])

  return useMemo<UploadBatchRequest>(
    () => [status, uploadBatch, cancel],
    [status, uploadBatch, cancel],
  )
}
