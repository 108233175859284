const rtf = new Intl.RelativeTimeFormat('en')
/**
 * Given a date in the future, return a string describing the amount of time
 * until the date, rounded to the largest whole unit.
 * E.G "1 day", "1 year", "2 years"
 *
 * @param date A date that has occurred in the future
 */
export default function prettyRoundedDuration(date: string | number | Date): string {
  const future = new Date(date)
  const now = new Date()
  const duration = future.getTime() - now.getTime()

  if (duration <= 0) {
    return '0 seconds'
  }

  const unitBuckets: [Intl.RelativeTimeFormatUnit, number][] = [
    ['year', 365 * 24 * 60 * 60 * 1000],
    ['month', 30 * 24 * 60 * 60 * 1000],
    ['day', 24 * 60 * 60 * 1000],
    ['hour', 60 * 60 * 1000],
    ['minute', 60 * 1000],
    ['second', 1000],
  ]
  for (const [unit, millisecondsInUnit] of unitBuckets) {
    const value = Math.floor(duration / millisecondsInUnit)
    if (value >= 1) {
      return rtf.format(value, unit).replace('in ', '')
    }
  }

  return '0 seconds'
}
