import {Callout} from '@kensho/neo'

import useMultiplayerContext from '../../../hooks/useMultiplayerContext'

export default function ReadOnlyCallout(): React.ReactNode {
  const {error, connectionError} = useMultiplayerContext()

  let infoText = 'This transcript is currently read-only. No changes will be saved.'

  if (error?.type === 'error' && error.payload.code === 'S2T-EDT-001') {
    infoText =
      'The transcript is read-only because you are connected with an outdated editor version. Please refresh the page to continue editing.'
  } else if (error) {
    infoText =
      'The transcript is read-only due to an error. Please refresh the page to continue editing.'
  } else if (connectionError) {
    infoText =
      'The transcript is read-only due to a connection error. Please refresh the page to continue editing.'
  }

  return <Callout title="Read-only mode">{infoText}</Callout>
}
