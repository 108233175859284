import {Icon, Tooltip} from '@kensho/neo'
import clsx from 'clsx'

import {mapKey} from '../hooks/useKeyboardShortcut'
import getIsMac from '../utils/isMac'

import {Shortcut} from './shortcutRegistration'

// Displaying shortcuts might be less confusing for the user if we incorporate
// https://developer.mozilla.org/en-US/docs/Web/API/Keyboard/getLayoutMap
// to display the correct key for the user's keyboard layout
// however, this is not supported in all browsers
export function prettyShortcutKey(key: string): React.ReactNode {
  const mappedKey = mapKey(key)

  switch (mappedKey) {
    case 'Period':
      return '.'
    case 'Comma':
      return ','
    case 'Slash':
      return '/'
    case 'Minus':
      return '-'
    case 'Equal':
      return '+'
    case 'Semicolon':
      return ';'
    case 'Quote':
      return "'"
    case 'Space':
      return 'Space'
    case 'Backquote':
      return '`'
    case 'Backslash':
      return '\\'
    case 'BracketLeft':
      return '['
    case 'BracketRight':
      return ']'
    case 'Right':
      return '→'
    case 'Left':
      return '←'
    case 'Mod':
      return getIsMac() ? '⌘' : 'CTRL'
    case 'Shift':
      return getIsMac() ? '⇧' : 'SHIFT'
    case 'Ctrl':
      return getIsMac() ? '⌃' : 'CTRL'
    case 'Alt':
      return getIsMac() ? '⌥' : 'ALT'
    case 'Left-click':
      return <Icon icon="CursorArrowRaysIcon" size="small" />
    default:
      return mappedKey
  }
}

export default function ShortcutSection({
  name,
  shortcuts,
}: {
  name: string
  shortcuts: [string, Shortcut][]
}): React.ReactNode {
  return (
    <section>
      <div className="mb-2 text-sm text-gray-500">{name}</div>
      <ul className="flex flex-col gap-4">
        {shortcuts.map(([shortcutName, shortcut]) => (
          <li className="flex items-center justify-between" key={shortcutName}>
            <div className="flex flex-row items-center gap-x-3">
              <div className="text-sm">{shortcut.description}</div>
              {shortcut.info && (
                <Tooltip content={shortcut.info}>
                  <Icon icon="InformationCircleIcon" size="small" />
                </Tooltip>
              )}
            </div>
            <div className="flex gap-1">
              {(shortcut.keysLabel || shortcut.keys).map((key, i) =>
                key === '/' ? (
                  /* eslint-disable-next-line react/no-array-index-key */
                  <div className="flex h-8 items-center justify-center" key={`${key}:${i}`}>
                    /
                  </div>
                ) : (
                  <div
                    className={clsx(
                      'flex h-8 min-w-8 items-center justify-center rounded bg-blue-50 px-1',
                      !shortcut.keysLabel && 'capitalize',
                    )}
                    key={key}
                  >
                    {prettyShortcutKey(key)}
                  </div>
                ),
              )}
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}
