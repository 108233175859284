import {useContext, useState} from 'react'
import {Dialog, Tooltip, Icon} from '@kensho/neo'

import SiteAnalyticsContext from '../../../../providers/SiteAnalyticsContext'

const IMPROVE_ACCURACY = 0.7

interface AudioQualityProps {
  accuracy: number
}

interface Quality {
  quality: number
  name: string
  color: string
  backgroundColor: string
  description?: string
}

const qualities: Quality[] = [
  {
    quality: 0.4,
    name: 'Bad',
    color: '#C42B3D',
    backgroundColor: '#FDE2D3',
    description: 'Please review the audio and try again or upload a different file',
  },
  {
    quality: 0.7,
    name: 'Low',
    color: '#925500',
    backgroundColor: '#FEF5CB',
  },
  {
    quality: 0.8,
    name: 'Medium',
    color: '#1635A2',
    backgroundColor: '#D4E5FD',
  },
  {
    quality: 1,
    name: 'High',
    color: '#0C7618',
    backgroundColor: '#E2F9D3',
  },
]

export default function AudioQuality(props: AudioQualityProps): React.ReactNode {
  const {accuracy} = props

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const analytics = useContext(SiteAnalyticsContext)

  const audioQualityScore = qualities.find(({quality}) => accuracy < quality) || qualities[3]

  return (
    <div>
      <div className="flex items-center gap-2">
        <h4 className="flex items-center text-gray-500">Your Audio</h4>
        <Tooltip
          content={
            <div>
              <p>These scores are based on the quality of your original audio:</p>
              <ul className="ml-4 w-full p-0">
                <li>80-100% - High Quality</li>
                <li>70-79% - Medium Quality</li>
                <li>40-69% - Low Quality</li>
                <li>0-39% - Bad Quality</li>
              </ul>
            </div>
          }
          position="right"
        >
          <Icon icon="InformationCircleIcon" />
        </Tooltip>
      </div>
      <div
        className="mx-0 my-2 flex flex-col gap-1 rounded-lg p-4"
        style={{
          backgroundColor: audioQualityScore.backgroundColor,
          color: audioQualityScore.color,
        }}
      >
        <span className="text-lg font-bold">
          {audioQualityScore.name} Quality ({Math.floor(accuracy * 100)}%)
        </span>
        {audioQualityScore.description && <span>{audioQualityScore.description}</span>}
      </div>
      {accuracy < IMPROVE_ACCURACY && (
        <span
          className="mx-0 my-1 cursor-pointer text-brand-700"
          onClick={() => {
            analytics.sendEvent('view_audio_quality_info')
            setIsDialogOpen(true)
          }}
          onKeyDown={() => setIsDialogOpen(true)}
        >
          How to improve your audio
        </span>
      )}
      <Dialog
        backdrop="light"
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        title="How to increase speech accuracy"
      >
        <p className="mb-4">
          The quality of your audio can affect the results of your transcriptions with multiple
          inaccuracies.
        </p>
        <p className="mb-4">
          Here are some helpful suggestions to increase the quality of your transcriptions. Prior to
          uploading your audio, please be sure of the following:
        </p>
        <ul className="flex flex-col gap-3 px-4">
          <li>
            <strong>Limit background noises:</strong> make sure that your audio has little to no
            background noises that disrupt your speakers.
          </li>
          <li>
            <strong>Overlapping dialog:</strong> Scribe AI might not be able to distinguish speakers
            who are speaking over each other, resulting in inaccuracies or audio that is not
            transcribable at all.
          </li>
          <li>
            <strong>Use microphones and headsets:</strong> using proper audio equipment rather than
            relying on your computer’s built-in microphone can help enhance the clarity of your
            audio.
          </li>
          <li>
            <strong>Use Custom Vocabulary:</strong> in addition to enhancing your transcription, use
            our Custom Vocabulary feature to teach Scribe AI names or any specific jargon to
            increase accuracy.
          </li>
        </ul>
      </Dialog>
    </div>
  )
}
