import {useCallback, useMemo, useState} from 'react'

import {API_ENDPOINT} from '../constants'
import {APITranscript, AsyncStatus, TranscriptFormat} from '../types/types'
import camelCaseDeep from '../utils/camelCaseDeep'
import parseError from '../utils/parseError'

import useAPIFetch from './useAPIFetch'

interface GetTranscriptOptions {
  transcriptId: string
  accept?: TranscriptFormat
  forceBlob?: boolean
}

type GetTranscriptRequest = [
  status: AsyncStatus,
  execute: (options: GetTranscriptOptions) => Promise<APITranscript | Blob>,
  cancel: () => void,
]

/** GET a transcript result in the specified format */
export default function useGetTranscript(abortDuplicateRequests = true): GetTranscriptRequest {
  const apiFetch = useAPIFetch(abortDuplicateRequests)
  const [status, setStatus] = useState<AsyncStatus>('idle')

  const getTranscript = useCallback(
    (options: GetTranscriptOptions): Promise<APITranscript | Blob> => {
      const {transcriptId, accept = 'application/json', forceBlob} = options

      setStatus('pending')

      return apiFetch
        .execute(`${API_ENDPOINT}/transcription/${transcriptId}`, {
          headers: {
            Accept: accept,
          },
        })
        .then((response: Response): Promise<APITranscript | Blob> => {
          if (!response.ok) {
            return response.json().then((r) => {
              let parsedError
              try {
                parsedError = parseError(r)
              } catch {
                // if parsing the JSON fails, throw the status code instead
                throw parseError(response.status)
              }

              throw parsedError
            })
          }

          const contentType =
            (response.headers.get('Content-Type') as TranscriptFormat | null) || ''

          if (
            forceBlob ||
            contentType.startsWith('text/plain') ||
            contentType.startsWith('text/vtt') ||
            contentType.startsWith(
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            )
          ) {
            return response.blob()
          }
          return response.json().then((json) => camelCaseDeep(json) as APITranscript)
        })
        .then((value: APITranscript | Blob) => {
          setStatus('success')
          return value
        })
        .catch((error: Error | DOMException) => {
          if ((error as DOMException).name === 'AbortError') throw error
          setStatus('error')
          const clientError = parseError(error)
          throw clientError
        })
    },
    [apiFetch],
  )

  const cancel = useCallback(() => {
    apiFetch.cancel()
    setStatus('idle')
  }, [apiFetch])

  return useMemo<GetTranscriptRequest>(
    () => [status, getTranscript, cancel],
    [status, getTranscript, cancel],
  )
}
