import {useCallback, useEffect, useState} from 'react'
import {isEqual} from 'lodash-es'

import {HIDConnectionEvent, HIDDevice, HIDInputReportEvent} from '../types/types'

import useLocalStorageState from './useLocalStorageState'

let preventContextMenu: boolean = false

interface HIDFilters {
  vendorId: number
  productId: number
}

// The vendorId read only property of the USBDevice interface is the official usb.org-assigned vendor ID.
// Foot pedals are standardized throughout all transcribers.
// https://developer.mozilla.org/en-US/docs/Web/API/HIDDevice/vendorId
// https://developer.mozilla.org/en-US/docs/Web/API/HIDDevice/productId
const VEC_FOOTPEDAL: HIDFilters = {vendorId: 1523, productId: 255}

// Specifically for the VEC USB Footpedal: Infinity USB-3: https://veccorp.com/foot-controls.html
export default function useFootPedal(
  skipForward: () => void,
  skipBackward: () => void,
  play: () => void,
): {
  connectDevice: () => Promise<void>
  disconnectDevice: () => Promise<void>
  connected: boolean
} {
  const [selectedDevice, setSelectedDevice] = useState<HIDDevice | null>(null)
  const [previouslyConnected, setPreviouslyConnected] = useLocalStorageState<HIDFilters | null>(
    'hid_connected',
    null,
  )

  const connectDevice = useCallback(
    async (allowRequest = true): Promise<void> => {
      if (!('hid' in navigator && navigator.hid)) return

      let [device] = await navigator.hid.getDevices({filters: [VEC_FOOTPEDAL]})

      if (!device && allowRequest)
        [device] = await navigator.hid.requestDevice({filters: [VEC_FOOTPEDAL]})

      if (!device) {
        setPreviouslyConnected(null)
        return
      }

      if (!device.opened) {
        await device.open()
      }
      setSelectedDevice(device)
      setPreviouslyConnected(VEC_FOOTPEDAL)
    },
    [setPreviouslyConnected],
  )

  const disconnectDevice = useCallback(async (): Promise<void> => {
    await selectedDevice?.close()
    setSelectedDevice(null)
    setPreviouslyConnected(null)
  }, [setPreviouslyConnected, selectedDevice])

  // re-open connection if user has previously connected without prompting again
  useEffect(() => {
    if (previouslyConnected) {
      connectDevice(false)
    }
  }, [previouslyConnected, connectDevice])

  useEffect(() => {
    const preventDefaultContextMenu = (e: MouseEvent): void => {
      if (preventContextMenu) {
        e.preventDefault()
        preventContextMenu = false
      }
    }
    const handleManualDisconnect = (e: HIDConnectionEvent): void => {
      if (
        e.device.productId === selectedDevice?.productId &&
        e.device.vendorId === selectedDevice?.vendorId
      ) {
        setPreviouslyConnected(null)
      }
    }

    const handleFootPedalInput = (e: HIDInputReportEvent): void => {
      if (!document.hasFocus()) return

      const {data} = e
      const value = data.getUint8(0)

      if (value === 1) {
        skipBackward()
      } else if (value === 2) {
        preventContextMenu = true
        play()
      } else if (value === 4) {
        skipForward()
      }
    }

    if (selectedDevice) {
      selectedDevice.addEventListener('inputreport', handleFootPedalInput)
    }

    document.addEventListener('contextmenu', preventDefaultContextMenu)

    navigator.hid?.addEventListener('disconnect', handleManualDisconnect)

    return (): void => {
      if (selectedDevice) {
        selectedDevice.removeEventListener('inputreport', handleFootPedalInput)
      }
      document.removeEventListener('contextmenu', preventDefaultContextMenu)
      navigator.hid?.removeEventListener('disconnect', handleManualDisconnect)
    }
  }, [
    connectDevice,
    disconnectDevice,
    play,
    selectedDevice,
    skipBackward,
    skipForward,
    setPreviouslyConnected,
  ])

  return {connectDevice, disconnectDevice, connected: isEqual(previouslyConnected, VEC_FOOTPEDAL)}
}
