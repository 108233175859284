import {Button} from '@kensho/neo'
import {chunk} from 'lodash-es'
import {memo} from 'react'
import clsx from 'clsx'

import {APITranscriptSlice, APITranscriptToken, Stage} from '../../../types/types'
import getSpeakerColor from '../../../utils/getSpeakerColor'
import prettyDuration from '../../../utils/prettyDuration'
import {toTranscriptPath} from '../../../utils/transcriptUtils'
import useMultiplayerContext from '../../../hooks/useMultiplayerContext'

import KeyQuestionToggle from './KeyQuestionToggle'
import SpeakerLabel from './SpeakerLabel'
import TranscriptTokenBatch from './TranscriptTokenBatch'
import UserPresenceGutter from './UserPresenceGutter'

// average words per line * number of lines
const TOKEN_BATCH_SIZE = 15 * 20

interface TranscriptSliceProps {
  slice: APITranscriptSlice
  stage: Stage
  sliceIndex: number
  currentTimeMs?: number
  showPlayControls: boolean
  onClickToken: (token: APITranscriptToken) => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  hasSelectionBoundary: boolean | null
  visibleBatches: Record<string, boolean>
}

function TranscriptSlice(props: TranscriptSliceProps): React.ReactNode {
  const {
    stage,
    slice,
    sliceIndex,
    currentTimeMs,
    onClickToken,
    seekMedia,
    showPlayControls,
    hasSelectionBoundary,
    visibleBatches,
  } = props
  const color = getSpeakerColor(slice.speakerId)

  // find the users on the current sliceIndex
  const {connectedUsers, sessionId} = useMultiplayerContext()
  const sliceUsers = connectedUsers.filter((user) => {
    if (user.sessionId === sessionId) return false
    if (!user.cursorPosition) return false
    if (user.cursorPosition.start && user.cursorPosition.end) {
      return (
        user.cursorPosition.start.sliceIndex === sliceIndex ||
        user.cursorPosition.end.sliceIndex === sliceIndex ||
        (sliceIndex > user.cursorPosition?.start.sliceIndex &&
          sliceIndex < user.cursorPosition?.end.sliceIndex)
      )
    }
    return false
  })

  return (
    <div
      data-type="slice"
      data-slice-index={sliceIndex}
      data-path={toTranscriptPath(sliceIndex)}
      className="mb-10 flex w-full gap-2"
    >
      <div contentEditable="false" tabIndex={-1} suppressContentEditableWarning>
        <UserPresenceGutter users={sliceUsers} />
      </div>

      <div className="flex w-full max-w-[680px] flex-col">
        <div
          className="flex w-full items-center justify-between gap-2"
          contentEditable="false"
          suppressContentEditableWarning
        >
          <SpeakerLabel slice={slice} sliceIndex={sliceIndex} speakerId={slice.speakerId} />
          <div className="flex flex-shrink-0 items-center gap-4">
            <Button
              onClick={() =>
                seekMedia({
                  timeSeconds: slice.tokenMeta[0].startMs / 1000,
                  play: true,
                  scroll: false,
                })
              }
              rightIcon={showPlayControls ? 'PlayIcon' : undefined}
              minimal
              disabled={!showPlayControls}
              size="small"
            >
              {prettyDuration(Math.floor((slice.tokenMeta[0]?.startMs || slice.startMs) / 1000))}
            </Button>
            <KeyQuestionToggle sliceIndex={sliceIndex} slice={slice} stage={stage} />
          </div>
        </div>
        <p
          className={clsx(
            'slice-tokens relative m-0 w-full py-1 text-base',
            sliceUsers.length > 0 && 'text-gray-400',
          )}
          contentEditable
          suppressContentEditableWarning
        >
          {chunk(slice.tokenMeta, TOKEN_BATCH_SIZE).map((tokens, batchIndex) => (
            <TranscriptTokenBatch
              // keep tokens rendered if they contain a selection boundary
              // so that scrolling away and switching to lower LOD rendering doesn't lose the selection
              forceTokens={hasSelectionBoundary}
              key={tokens[0].startMs}
              tokenMeta={tokens}
              tokenOffsetIndex={batchIndex * TOKEN_BATCH_SIZE}
              sliceIndex={sliceIndex}
              currentTimeMs={currentTimeMs}
              color={color}
              onClickToken={onClickToken}
              stage={stage}
              isLast={slice.tokenMeta[slice.tokenMeta.length - 1] === tokens[tokens.length - 1]}
              visibleBatches={visibleBatches}
            />
          ))}
        </p>
      </div>
    </div>
  )
}

export default memo(TranscriptSlice)
