import {Button, ButtonProps, Dialog} from '@kensho/neo'

interface UnloadWarningDialogProps {
  isOpen: boolean
  onClose: () => void
  content: React.ReactNode
  title: string
  primaryButtonProps: ButtonProps
  secondaryButtonProps: ButtonProps
}

export default function UnloadWarningDialog(props: UnloadWarningDialogProps): React.ReactNode {
  const {onClose, content, isOpen, title, primaryButtonProps, secondaryButtonProps} = props

  return (
    <Dialog backdrop="light" isOpen={isOpen} onClose={onClose} title={title}>
      {content}
      <div className="mt-6 flex justify-end gap-4">
        <Button {...secondaryButtonProps}>{secondaryButtonProps.children}</Button>
        <Button {...primaryButtonProps}>{primaryButtonProps.children}</Button>
      </div>
    </Dialog>
  )
}
