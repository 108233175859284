import {ButtonLink} from '@kensho/neo'
import {useContext} from 'react'

import useLoginURL from '../hooks/useLoginURL'
import SiteAnalyticsContext from '../providers/SiteAnalyticsContext'

export default function SignInPrompt(): React.ReactNode {
  const loginPaths = useLoginURL()
  const analytics = useContext(SiteAnalyticsContext)

  return (
    <div>
      <div className="flex flex-col">
        <ButtonLink
          href={loginPaths.URL}
          forceServer
          intent="primary"
          size="large"
          onClick={() =>
            analytics.sendEvent(
              'login',
              {method: 'user_login'},
              {targeting: true, performance: true},
            )
          }
        >
          Sign in with Kensho
        </ButtonLink>
      </div>

      <div className="relative flex items-center py-5">
        <div className="flex-grow border-t border-gray-200" />
        <span className="mx-4 flex-shrink text-gray-400">OR</span>
        <div className="flex-grow border-t border-gray-200" />
      </div>
      <div className="flex flex-col">
        <ButtonLink
          href={loginPaths.MARKETPLACE_IDP_URL}
          forceServer
          size="large"
          onClick={() =>
            analytics.sendEvent('login', {method: 'sp_login'}, {targeting: true, performance: true})
          }
        >
          Sign in with S&P Marketplace
        </ButtonLink>
      </div>
    </div>
  )
}
