import {RectSegment, RectAnnotationStyle, RectCorner, RectEdge} from '../types'

function normalizeRectCorner(
  corner: number | Partial<RectCorner<number>> | undefined,
  defaultValue: number,
  max: number,
): RectCorner<number> {
  if (corner && typeof corner === 'object') {
    return {
      topLeft: Math.min(max, corner.topLeft ?? 0),
      topRight: Math.min(max, corner.topRight ?? 0),
      bottomLeft: Math.min(max, corner.bottomLeft ?? 0),
      bottomRight: Math.min(max, corner.bottomRight ?? 0),
    }
  }
  return {
    topLeft: Math.min(max, corner ?? defaultValue),
    topRight: Math.min(max, corner ?? defaultValue),
    bottomLeft: Math.min(max, corner ?? defaultValue),
    bottomRight: Math.min(max, corner ?? defaultValue),
  }
}

function normalizeRectEdge<T>(
  edge: T | Partial<RectEdge<T>> | undefined,
  {
    defaultValueForNew,
    defaultValueForNewWithEdge,
    defaultValueForExisting,
    transform: transformOrUndefined,
  }: {
    defaultValueForNew?: T
    defaultValueForNewWithEdge?: (edgedir: keyof RectEdge<T>) => T
    defaultValueForExisting: T
    transform?: (value: T) => T
  },
): RectEdge<T> {
  const transform = transformOrUndefined || ((v) => v)
  if (
    edge &&
    typeof edge === 'object' &&
    ('top' in edge || 'right' in edge || 'bottom' in edge || 'left' in edge)
  ) {
    return {
      top: transform(edge.top ?? defaultValueForExisting),
      right: transform(edge.right ?? defaultValueForExisting),
      bottom: transform(edge.bottom ?? defaultValueForExisting),
      left: transform(edge.left ?? defaultValueForExisting),
    }
  }
  if (typeof edge === 'number' || typeof edge === 'string') {
    return {
      top: transform ? transform(edge) : edge,
      right: transform ? transform(edge) : edge,
      bottom: transform ? transform(edge) : edge,
      left: transform ? transform(edge) : edge,
    }
  }
  if (typeof defaultValueForNewWithEdge === 'function') {
    return {
      top: defaultValueForNewWithEdge('top'),
      right: defaultValueForNewWithEdge('right'),
      bottom: defaultValueForNewWithEdge('bottom'),
      left: defaultValueForNewWithEdge('left'),
    }
  }

  if (typeof defaultValueForNew !== 'undefined') {
    return {
      top: defaultValueForNew,
      right: defaultValueForNew,
      bottom: defaultValueForNew,
      left: defaultValueForNew,
    }
  }

  throw new Error('No defaults')
}

// Given a position of the border and the edge width, return the offset we should move to align
// adjacent edge
function offsetForEdge(
  borderPosition: RectAnnotationStyle['borderPosition'],
  adjacentEdgeWidth: number,
): number {
  if (borderPosition === 'outside') return 0
  if (borderPosition === 'center') return adjacentEdgeWidth / 2
  if (borderPosition === 'inside') return adjacentEdgeWidth
  return 0
}

function edgeHasAdjacentEdge(edge: keyof RectEdge<number>, edgeWidths: RectEdge<number>): boolean {
  if (edge === 'top') return Boolean(edgeWidths.left || edgeWidths.right)
  if (edge === 'right') return Boolean(edgeWidths.top || edgeWidths.bottom)
  if (edge === 'bottom') return Boolean(edgeWidths.left || edgeWidths.right)
  if (edge === 'left') return Boolean(edgeWidths.top || edgeWidths.bottom)
  return false
}

function edgeHasStraightCorner(
  edge: keyof RectEdge<number>,
  borderRadius: RectCorner<number>,
): boolean {
  if (edge === 'top') return borderRadius.topLeft === 0 || borderRadius.topRight === 0
  if (edge === 'right') return borderRadius.topRight === 0 || borderRadius.bottomRight === 0
  if (edge === 'bottom') return borderRadius.bottomLeft === 0 || borderRadius.bottomRight === 0
  if (edge === 'left') return borderRadius.topLeft === 0 || borderRadius.bottomLeft === 0
  return false
}

function edgeShouldRenderCornerTriangles(
  edge: keyof RectEdge<number>,
  edgeWidths: RectEdge<number>,
  borderRadius: RectCorner<number>,
): boolean {
  return edgeHasAdjacentEdge(edge, edgeWidths) && edgeHasStraightCorner(edge, borderRadius)
}

function edgeShouldRenderCornerTriangleAtCorner(
  edge: keyof RectEdge<number>,
  corner: keyof RectCorner<number>,
  edgeWidths: RectEdge<number>,
  borderRadius: RectCorner<number>,
): boolean {
  // If the corner is rounded we don't need these corners
  if (borderRadius[corner]) return false
  if (edge === 'top') return corner === 'topLeft' ? edgeWidths.left > 0 : edgeWidths.right > 0
  if (edge === 'right') return corner === 'topRight' ? edgeWidths.top > 0 : edgeWidths.bottom > 0
  if (edge === 'bottom') return corner === 'bottomLeft' ? edgeWidths.left > 0 : edgeWidths.right > 0
  if (edge === 'left') return corner === 'topLeft' ? edgeWidths.top > 0 : edgeWidths.bottom > 0
  return false
}

function arrayEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

function allSame<T>(edge: RectEdge<T>): boolean {
  return edge.top === edge.right && edge.top === edge.bottom && edge.top === edge.left
}
function allSameArray(edge: RectEdge<number[]>): boolean {
  return (
    arrayEqual(edge.top, edge.right) &&
    arrayEqual(edge.top, edge.bottom) &&
    arrayEqual(edge.top, edge.left)
  )
}

function drawSinglePath(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  borderRadius: RectCorner<number>,
  borderPosition: NonNullable<RectAnnotationStyle['borderPosition']>,
  pass?: 'outside-render',
): void {
  ctx.beginPath()

  // Top
  ctx.moveTo(x + borderRadius.topLeft, y)
  ctx.lineTo(x + width - borderRadius.topRight, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + borderRadius.topRight)

  // Right
  ctx.lineTo(x + width, y + height - borderRadius.bottomRight)
  ctx.quadraticCurveTo(x + width, y + height, x + width - borderRadius.bottomRight, y + height)

  // Bottom
  ctx.lineTo(x + borderRadius.bottomLeft, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - borderRadius.bottomLeft)

  // Left
  ctx.lineTo(x, y + borderRadius.topLeft)
  ctx.quadraticCurveTo(x, y, x + borderRadius.topLeft, y)

  ctx.closePath()

  if (pass === 'outside-render') {
    ctx.stroke()
  } else if (borderPosition === 'center') {
    // Center is the browser default
    ctx.fill()
    ctx.stroke()
  } else if (borderPosition === 'inside') {
    ctx.save()
    // Set the border twice as big since the default is centered, this will make the inside the right width
    ctx.lineWidth *= 2

    // Clip around the drawn path so the outside border is removed
    ctx.clip()

    ctx.fill()
    ctx.stroke()
    ctx.restore()
  } else if (borderPosition === 'outside') {
    // 2 pass to draw: 1. fill, 2. draw outside border at different position/scale
    ctx.fill()
    drawSinglePath(
      ctx,
      x - ctx.lineWidth / 2,
      y - ctx.lineWidth / 2,
      width + ctx.lineWidth,
      height + ctx.lineWidth,
      borderRadius,
      borderPosition,
      'outside-render',
    )
  }
}

function drawComplexPath(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  borderRadius: RectCorner<number>,
  borderWidth: RectEdge<number>,
  borderDashed: RectEdge<number[]>,
  borderColor: RectEdge<string>,
  backgroundColor: string,
  borderPosition: NonNullable<RectAnnotationStyle['borderPosition']>,
): void {
  const half = Math.PI
  const eighth = Math.PI / 4
  const quarter = Math.PI / 2

  function draw(edge: keyof RectEdge<number>, drawingBorders = false): void {
    if (drawingBorders) ctx.beginPath()
    if (edge === 'top') {
      if (borderRadius.topLeft)
        ctx.arc(
          x + borderRadius.topLeft,
          y + borderRadius.topLeft,
          borderRadius.topLeft,
          -half + eighth,
          -quarter,
        )
      else if (drawingBorders) ctx.moveTo(x + offsetForEdge(borderPosition, borderWidth.left), y)
      else ctx.moveTo(x, y)

      ctx.lineTo(
        x +
          width -
          borderRadius.topRight -
          (drawingBorders ? offsetForEdge(borderPosition, borderWidth.right) : 0),
        y,
      )
      if (borderRadius.topRight)
        ctx.arc(
          x + width - borderRadius.topRight,
          y + borderRadius.topRight,
          borderRadius.topRight,
          -quarter,
          -eighth,
        )
      else if (drawingBorders) ctx.moveTo(x + width, y)
    } else if (edge === 'right') {
      if (borderRadius.topRight)
        ctx.arc(
          x + width - borderRadius.topRight,
          y + borderRadius.topRight,
          borderRadius.topRight,
          -eighth,
          0,
        )
      else if (drawingBorders)
        ctx.moveTo(x + width, y + offsetForEdge(borderPosition, borderWidth.top))
      ctx.lineTo(
        x + width,
        y +
          height -
          borderRadius.bottomRight -
          (drawingBorders ? offsetForEdge(borderPosition, borderWidth.bottom) : 0),
      )
      if (borderRadius.bottomRight)
        ctx.arc(
          x + width - borderRadius.bottomRight,
          y + height - borderRadius.bottomRight,
          borderRadius.bottomRight,
          0,
          eighth,
        )
    } else if (edge === 'bottom') {
      if (borderRadius.bottomRight)
        ctx.arc(
          x + width - borderRadius.bottomRight,
          y + height - borderRadius.bottomRight,
          borderRadius.bottomRight,
          eighth,
          quarter,
        )
      else if (drawingBorders)
        ctx.moveTo(x + width - offsetForEdge(borderPosition, borderWidth.right), y + height)
      ctx.lineTo(
        x +
          borderRadius.bottomLeft +
          (drawingBorders ? offsetForEdge(borderPosition, borderWidth.left) : 0),
        y + height,
      )
      if (borderRadius.bottomLeft)
        ctx.arc(
          x + borderRadius.bottomLeft,
          y + height - borderRadius.bottomLeft,
          borderRadius.bottomLeft,
          quarter,
          quarter + eighth,
        )
    } else if (edge === 'left') {
      if (borderRadius.bottomLeft)
        ctx.arc(
          x + borderRadius.bottomLeft,
          y + height - borderRadius.bottomLeft,
          borderRadius.bottomLeft,
          quarter + eighth,
          quarter + eighth + eighth,
        )
      else if (drawingBorders)
        ctx.moveTo(x, y + height - offsetForEdge(borderPosition, borderWidth.bottom))
      ctx.lineTo(x, y + borderRadius.topLeft + offsetForEdge(borderPosition, borderWidth.top))
      if (borderRadius.topLeft)
        ctx.arc(
          x + borderRadius.topLeft,
          y + borderRadius.topLeft,
          borderRadius.topLeft,
          -half,
          -half + eighth,
        )
    }

    if (borderColor[edge] && borderWidth[edge] && drawingBorders) {
      ctx.strokeStyle = borderColor[edge]
      ctx.lineWidth = borderWidth[edge] * (borderPosition === 'center' ? 1 : 2)
      ctx.setLineDash(borderDashed[edge])
      ctx.stroke()

      // Draw corner triangles to connect edges
      if (edgeShouldRenderCornerTriangles(edge, borderWidth, borderRadius)) {
        if (edge === 'top') {
          ctx.fillStyle = borderColor.top
          ctx.beginPath()
          if (edgeShouldRenderCornerTriangleAtCorner(edge, 'topRight', borderWidth, borderRadius)) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.right)
            const offsetY = offsetForEdge(borderPosition, borderWidth.top)
            ctx.moveTo(x + width - offsetX, y - borderWidth.top + offsetY)
            ctx.lineTo(x + width + borderWidth.right - offsetX, y - borderWidth.top + offsetY)
            ctx.lineTo(x + width - offsetX, y + offsetY)
            ctx.closePath()
          }
          if (edgeShouldRenderCornerTriangleAtCorner(edge, 'topLeft', borderWidth, borderRadius)) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.left)
            const offsetY = offsetForEdge(borderPosition, borderWidth.top)
            ctx.moveTo(x - borderWidth.left + offsetX, y - borderWidth.top + offsetY)
            ctx.lineTo(x + offsetX, y - borderWidth.top + offsetY)
            ctx.lineTo(x + offsetX, y + offsetY)
          }
          ctx.fill()
        } else if (edge === 'right') {
          ctx.beginPath()
          ctx.fillStyle = borderColor.right
          if (edgeShouldRenderCornerTriangleAtCorner(edge, 'topRight', borderWidth, borderRadius)) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.right)
            const offsetY = offsetForEdge(borderPosition, borderWidth.top)
            ctx.moveTo(x + width - offsetX, y + offsetY)
            ctx.lineTo(x + width + borderWidth.right - offsetX, y - borderWidth.top + offsetY)
            ctx.lineTo(x + width + borderWidth.right - offsetX, y + offsetY)
            ctx.closePath()
          }
          if (
            edgeShouldRenderCornerTriangleAtCorner(edge, 'bottomRight', borderWidth, borderRadius)
          ) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.right)
            const offsetY = offsetForEdge(borderPosition, borderWidth.bottom)
            ctx.moveTo(x + width - offsetX, y + height - offsetY)
            ctx.lineTo(x + width + borderWidth.right - offsetX, y + height - offsetY)
            ctx.lineTo(
              x + width + borderWidth.right - offsetX,
              y + height + borderWidth.bottom - offsetY,
            )
          }
          ctx.fill()
        } else if (edge === 'bottom') {
          ctx.beginPath()
          ctx.fillStyle = borderColor.bottom
          if (
            edgeShouldRenderCornerTriangleAtCorner(edge, 'bottomLeft', borderWidth, borderRadius)
          ) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.left)
            const offsetY = offsetForEdge(borderPosition, borderWidth.bottom)
            ctx.moveTo(x + offsetX, y + height - offsetY)
            ctx.lineTo(x + offsetX, y + height + borderWidth.bottom - offsetY)
            ctx.lineTo(x - borderWidth.left + offsetX, y + height + borderWidth.bottom - offsetY)
            ctx.closePath()
          }
          if (
            edgeShouldRenderCornerTriangleAtCorner(edge, 'bottomRight', borderWidth, borderRadius)
          ) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.right)
            const offsetY = offsetForEdge(borderPosition, borderWidth.bottom)
            ctx.moveTo(x + width - offsetX, y + height - offsetY)
            ctx.lineTo(
              x + width + borderWidth.right - offsetX,
              y + height + borderWidth.bottom - offsetY,
            )
            ctx.lineTo(x + width - offsetX, y + height + borderWidth.bottom - offsetY)
          }
          ctx.fill()
        } else if (edge === 'left') {
          ctx.beginPath()
          ctx.fillStyle = borderColor.left
          if (edgeShouldRenderCornerTriangleAtCorner(edge, 'topLeft', borderWidth, borderRadius)) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.left)
            const offsetY = offsetForEdge(borderPosition, borderWidth.top)
            ctx.moveTo(x - borderWidth.left + offsetX, y + offsetY)
            ctx.lineTo(x - borderWidth.left + offsetX, y - borderWidth.top + offsetY)
            ctx.lineTo(x + offsetX, y + offsetY)
            ctx.closePath()
          }
          if (
            edgeShouldRenderCornerTriangleAtCorner(edge, 'bottomLeft', borderWidth, borderRadius)
          ) {
            const offsetX = offsetForEdge(borderPosition, borderWidth.left)
            const offsetY = offsetForEdge(borderPosition, borderWidth.bottom)
            ctx.moveTo(x + offsetX, y + height - offsetY)
            ctx.lineTo(x - borderWidth.left + offsetX, y + height - offsetY)
            ctx.lineTo(x - borderWidth.left + offsetX, y + height + borderWidth.bottom - offsetY)
          }
          ctx.fill()
        }
      }
    }
  }

  function drawBorders(): void {
    draw('top', true)
    draw('right', true)
    draw('bottom', true)
    draw('left', true)
  }

  function drawBackground(): void {
    if (!backgroundColor) return
    ctx.beginPath()
    draw('top')
    draw('right')
    draw('bottom')
    draw('left')
    ctx.closePath()
    ctx.fillStyle = backgroundColor
    ctx.fill()
  }

  if (borderPosition === 'center') {
    // This is browser default
    drawBackground()
    drawBorders()
  } else if (borderPosition === 'inside') {
    // Double up border width, then clip the extra border outside the rect,
    // leaving just the border inside
    ctx.save()
    ctx.beginPath()
    draw('top')
    draw('right')
    draw('bottom')
    draw('left')
    ctx.clip()
    drawBackground()
    drawBorders()
    ctx.restore()
  } else if (borderPosition === 'outside') {
    drawBackground()

    // Create a clip area around the path using evenodd rule so border is only drawn outside of shape
    ctx.save()
    ctx.beginPath()
    // Required for evenodd clipping to be outside instead of inside the shape:
    // This is just a bounding rect bigger than the shape + borders
    ctx.rect(
      Math.max(0, x - borderWidth.left),
      Math.max(0, y - borderWidth.top),
      x + width + (borderWidth.left + borderWidth.right),
      y + height + borderWidth.top + borderWidth.bottom,
    )
    draw('top')
    draw('right')
    draw('bottom')
    draw('left')
    ctx.clip('evenodd')

    drawBorders()
    ctx.restore()
  }
}

export default function drawRect(
  ctx: CanvasRenderingContext2D,
  segment: RectSegment,
  style: RectAnnotationStyle,
): void {
  const {x, y, width, height} = segment

  // support gradients here as well
  const backgroundColor = style.backgroundColor ?? ''

  const borderColor = normalizeRectEdge(style.borderColor, {
    defaultValueForNew: '',
    defaultValueForExisting: '',
  })

  // Limit radius/border widths to half to not break things (drawing inside/outside requires this max)
  // Drawing inside/outside is done by doubling the stroke and clearing the inside/outside, but
  // if doubling overlaps the opposite sided border we get artifacts.
  //
  // This could limit to just the direction (left/right border by only width, but then we'd could have
  // uneven border widths, horizontal or vertically
  const max = Math.min(width / 2, height / 2)
  const borderWidth = normalizeRectEdge(style.borderWidth, {
    defaultValueForExisting: 0,
    defaultValueForNewWithEdge: (edge) => (borderColor[edge] ? 1 : 0),
    transform: (value) => Math.min(max, value),
  })

  const borderRadius = normalizeRectCorner(style.borderRadius, 0, max)
  const borderDashed = normalizeRectEdge(style.borderDashed, {
    defaultValueForExisting: [],
    defaultValueForNew: [],
  })
  const hasBorder =
    (borderWidth.bottom && borderColor.bottom) ||
    (borderWidth.top && borderColor.top) ||
    (borderWidth.right && borderColor.right) ||
    (borderWidth.left && borderColor.left)
  const hasCornerRadius =
    borderRadius.topLeft ||
    borderRadius.topRight ||
    borderRadius.bottomLeft ||
    borderRadius.bottomRight

  // Short circuit to single rect fill if no border, corner radius
  if (!hasBorder && !hasCornerRadius) {
    if (backgroundColor) {
      ctx.fillStyle = backgroundColor
      ctx.fillRect(x, y, width, height)
    }
    return
  }

  const {borderPosition = 'center'} = style

  if (allSame(borderWidth) && allSameArray(borderDashed) && allSame(borderColor)) {
    // Optimized drawing: draw one path since they are the same colors, widths, dash pattern
    // This also makes better looking connections as the corner triangles aren't needed
    ctx.lineWidth = borderWidth.top
    ctx.setLineDash(borderDashed.top)
    ctx.strokeStyle = borderColor.top || 'transparent'
    ctx.fillStyle = backgroundColor || 'transparent'

    const shouldOffsetForOddWidthLines = borderWidth.top % 2 !== 0
    if (shouldOffsetForOddWidthLines) ctx.translate(-0.5, -0.5)

    drawSinglePath(ctx, x, y, width, height, borderRadius, borderPosition)

    if (shouldOffsetForOddWidthLines) ctx.translate(0.5, 0.5)
  } else {
    // Need to separately draw each edge, and another path to fill
    // TODO: This currently doesn't account for drawing sharp straight lines by offsetting odd width lines by 1/2 pixel
    drawComplexPath(
      ctx,
      x,
      y,
      width,
      height,
      borderRadius,
      borderWidth,
      borderDashed,
      borderColor,
      backgroundColor,
      borderPosition,
    )
  }
}
