/**
 * Given a past date returns a string describing when in the past the date occurred
 * e.g Yesterday at 10:27 AM
 *
 * @param modified A date that has occurred in the past
 */
export default function prettyModified(modified: string): string {
  /**
   * Extract the relative day from the timestamp
   *
   * @param date A date that has occurred in the past
   * @returns When the date occurred in relation to now
   * Occurred, Description
   * Today, Today
   * Yesterday, Yesterday
   * Within the last 7 days, <Monday, Tuesday, ..., Sunday>
   * Greater than the last 7 days, <January 1, ..., December 31>
   */
  function getPrefix(date: Date): string {
    const today = new Date()
    if (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    ) {
      return 'Today'
    }
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)
    if (
      date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate()
    ) {
      return 'Yesterday'
    }
    const lastWeek = new Date(today)
    lastWeek.setDate(today.getDate() - 7)
    if (
      date.getFullYear() === lastWeek.getFullYear() &&
      date.getMonth() === lastWeek.getMonth() &&
      date.getDate() >= lastWeek.getDate()
    ) {
      return `${date.toLocaleDateString('en-US', {
        weekday: 'long',
      })}`
    }
    return `${date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    })}`
  }
  const date = new Date(modified)
  return `${getPrefix(date)} at ${date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`
}
