import ErrorBoundary from '../components/ErrorBoundary'
import Footer from '../components/Footer'
import Header from '../components/Header'

interface BasePageProps {
  children: React.ReactNode
  header?: boolean
  footer?: boolean
}

export default function BasePage(props: BasePageProps): React.ReactNode {
  const {children, header = true, footer = true} = props

  return (
    <div className="flex min-h-screen flex-col">
      {header && <Header />}
      <div className="flex-1">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
      {footer && <Footer />}
    </div>
  )
}
