import {useContext} from 'react'

import Skeleton from '../../../components/Skeleton'
import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import {
  APITranscript,
  AsyncStatus,
  Mode,
  Stage,
  TranscriptMetadata,
  TranscriptSelection,
  TranscriptionConfiguration,
} from '../../../types/types'
import prettyDateTime from '../../../utils/prettyDateTime'
import {OperationAction} from '../../../utils/transcriptPatchUtils'
import {TranscriptNameContextProvider} from '../../historicalTranscripts/HistoricalTranscriptsTable/TranscriptName/TranscriptNameContext'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'
import useMultiplayerContext from '../../../hooks/useMultiplayerContext'

import ReadOnlyCallout from './ReadOnlyCallout'
import Toolbar from './Toolbar'
import TranscriptName from './TranscriptName'
import UserPresenceList from './UserPresenceList'

interface TranscriptHeaderProps {
  mode?: Mode
  metadata?: TranscriptMetadata
  stage: Stage
  transcript?: APITranscript
  transcriptId?: string
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  onEditOperationError: (error: Error, action: OperationAction) => void
  transcriptSelection: TranscriptSelection | null
  updateTranscriptSelection: UpdateTranscriptSelectionType
  scrollTranscriptToTime: (timeMs: number) => Promise<void>
  undo: () => void
  redo: () => void
  backgroundAlignerStatus: AsyncStatus
  syncScrollToTime: boolean
  setSyncScrollToTime: React.Dispatch<React.SetStateAction<boolean>>
  scrollTranscriptToCurrentTime: () => void
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  currentTime: number
  setPaused: React.Dispatch<React.SetStateAction<boolean>>
  hasMedia: boolean
  scrollTranscriptToSelection: (
    selection: TranscriptSelection,
    options?: {top?: number; bottom?: number},
  ) => Promise<void>
}

function TranscriptHeader(props: TranscriptHeaderProps): React.ReactNode {
  const {
    stage,
    mode,
    metadata,
    transcript,
    transcriptId,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    onEditOperationError,
    transcriptSelection,
    updateTranscriptSelection,
    scrollTranscriptToTime,
    undo,
    redo,
    backgroundAlignerStatus,
    syncScrollToTime,
    setSyncScrollToTime,
    seekMedia,
    currentTime,
    setPaused,
    hasMedia,
    scrollTranscriptToSelection,
  } = props

  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const {connectedUsers, authenticated, sessionId} = useMultiplayerContext()

  if (mode !== 'REALTIME' && !metadata) {
    return (
      <div className="ml-20">
        <Skeleton height={20} width={200} />
      </div>
    )
  }

  return (
    <div className="sticky top-0 z-10 bg-white pl-16">
      {metadata?.expires && (
        <span
          className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm text-gray-600"
          title={`Available until ${prettyDateTime(metadata.expires)}`}
        >
          Available until {prettyDateTime(metadata.expires)}
        </span>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-4">
          <div className="min-w-0 overflow-hidden">
            <TranscriptNameContextProvider key={metadata?.id}>
              {metadata && mode !== 'REALTIME' ? (
                <TranscriptName name={metadata.name} id={metadata.id} />
              ) : (
                <TranscriptName
                  name={transcriptionConfiguration.name || ''}
                  id={transcriptId || ''}
                  setTranscriptionConfiguration={setTranscriptionConfiguration}
                />
              )}
            </TranscriptNameContextProvider>
          </div>
          <div className="mr-3">
            {authenticated && connectedUsers.length > 0 && (
              <UserPresenceList
                connectedUsers={connectedUsers}
                currentUserSessionId={sessionId}
                scrollTranscriptToSelection={scrollTranscriptToSelection}
                transcript={transcript}
              />
            )}
          </div>
        </div>

        {transcriptPermissions.edit && (
          <Toolbar
            stage={stage}
            metadata={metadata}
            mode={mode}
            transcript={transcript}
            transcriptId={transcriptId}
            onEditOperationError={onEditOperationError}
            transcriptSelection={transcriptSelection}
            updateTranscriptSelection={updateTranscriptSelection}
            scrollTranscriptToTime={scrollTranscriptToTime}
            undo={undo}
            redo={redo}
            backgroundAlignerStatus={backgroundAlignerStatus}
            syncScrollToTime={syncScrollToTime}
            setSyncScrollToTime={setSyncScrollToTime}
            scrollTranscriptToCurrentTime={() => scrollTranscriptToTime(currentTime)}
            seekMedia={seekMedia}
            currentTime={currentTime}
            setPaused={setPaused}
            hasMedia={hasMedia}
          />
        )}
        {!transcriptPermissions.edit && transcript && <ReadOnlyCallout />}
      </div>
    </div>
  )
}

export default TranscriptHeader
