import {createContext} from 'react'

import {CookieConsentOptions} from '../components/CookieBanner/CookieConsentDialogContents'
import {CONSENT_COOKIE_DEFAULTS} from '../constants'

export type AnalyticsEventDetails = Record<string, unknown>

export interface AnalyticsProvider {
  name: string
  consentRequiredToEnable: CookieConsentOptions
  enable: () => void
  disable: () => void
  sendEvent?: (eventName: string, eventDetails?: AnalyticsEventDetails) => void
  setSessionContext?: (name: string, key: string, value: string) => void
}

export interface AnalyticsContextInterface {
  consent: CookieConsentOptions
  setConsent: (cookie: CookieConsentOptions) => void
  sendEvent: (
    eventName: string,
    eventDetails?: AnalyticsEventDetails,
    consentFilter?: Partial<CookieConsentOptions>,
  ) => void
  setSessionContext: (
    name: string,
    key: string,
    value: string,
    consentFilter?: Partial<CookieConsentOptions>,
  ) => void
}

export default createContext<AnalyticsContextInterface>({
  consent: CONSENT_COOKIE_DEFAULTS,
  setConsent: () => null,
  sendEvent: () => null,
  setSessionContext: () => null,
})
