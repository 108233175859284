import {UNKNOWN_SPEAKER_COLOR, SPEAKER_COLORS} from '../core/transcription/constants'

export default function getSpeakerColor(
  speakerId: number | string,
  unknownColor = UNKNOWN_SPEAKER_COLOR,
): string {
  const id = Number(speakerId)
  return id === undefined || id === -1
    ? unknownColor
    : SPEAKER_COLORS[Math.abs(id % SPEAKER_COLORS.length)]
}
