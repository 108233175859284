import {debounce} from 'lodash-es'
import {useRef, useEffect, useState, useMemo} from 'react'

/**
 * Uses an IntersectionObserver to determine whether the element is visible
 */
export default function useInView<T extends HTMLElement>({
  initialInView = false,
  debounceDelay,
  intersectionOptions,
}: {
  initialInView?: boolean
  debounceDelay?: number
  intersectionOptions?: IntersectionObserverInit
}): {
  ref: React.RefCallback<T>
  inView: boolean
} {
  const observerRef = useRef<IntersectionObserver>()
  const [element, setElement] = useState<T>()
  const [inView, setInView] = useState(initialInView)

  // create IntersectionObserver
  useEffect(() => {
    const intersectionCallback = (entries: IntersectionObserverEntry[]): void => {
      setInView(entries.some((entry) => entry.isIntersecting))
    }

    observerRef.current = new IntersectionObserver(
      debounceDelay !== undefined
        ? debounce(intersectionCallback, debounceDelay)
        : intersectionCallback,
      intersectionOptions,
    )

    return () => {
      observerRef.current?.disconnect()
    }
  }, [intersectionOptions, debounceDelay])

  // add/remove ref element from IntersectionObserver
  useEffect(() => {
    if (element) observerRef.current?.observe(element)
    return () => {
      if (element) observerRef.current?.unobserve(element)
    }
  }, [element])

  const value = useMemo(() => ({ref: (r: T) => setElement(r), inView}), [inView])

  return value
}
