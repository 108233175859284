import {css} from '@emotion/react'

interface SkeletonProps {
  className?: string
  width?: number | string
  height?: number
  animate?: boolean
}

const skeletonCss = css`
  display: inline-block;
  background: rgba(167, 189, 194, 0.3);
  border-radius: 3px;
  height: 1em;
  margin: 5px 0;
`

const animateCss = css`
  @keyframes half-fade {
    0%,
    100% {
      opacity: 0.5;
    }

    50% {
      opacity: 1;
    }
  }

  animation: half-fade 1500ms linear 0ms infinite;
`

function getCssDimension(value: number | string = 'auto'): string {
  if (typeof value === 'number') return `${value}px`
  return value
}

export default function Skeleton(props: SkeletonProps): React.ReactNode {
  const {className, width = '100%', height, animate = true} = props

  return (
    <div
      className={className}
      css={[skeletonCss, animate && animateCss]}
      style={{
        width: getCssDimension(width),
        height: getCssDimension(height),
      }}
    >
      {/* blank character gives container height of inherited font-size */}
      &nbsp;
    </div>
  )
}
