export default function hexToRGBA(color: string, alpha?: number): string {
  let hex = color

  // expand shorthand (e.g. #fff -> #ffffff)
  if (hex.length === 4) {
    hex = `${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}`
  }

  const r = Number.parseInt(hex.slice(1, 3), 16)
  const g = Number.parseInt(hex.slice(3, 5), 16)
  const b = Number.parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha === undefined ? 255 : alpha})`
}
