import {useCallback} from 'react'

import useAPIFetch from './useAPIFetch'

export default function useDeleteTranscript(): (id: string) => Promise<Response> {
  const apiFetch = useAPIFetch()

  const deleteTranscript = useCallback(
    async (id: string) => apiFetch.execute(`/api/v2/transcription/${id}`, {method: 'DELETE'}),
    [apiFetch],
  )

  return deleteTranscript
}
