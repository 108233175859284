import {useCallback} from 'react'

import snakeCaseDeep from '../utils/snakeCaseDeep'
import {APITranscriptToken} from '../types/types'
import camelCaseDeep from '../utils/camelCaseDeep'

import useAPIFetch from './useAPIFetch'

export interface AlignTokensParams {
  startMs?: number
  startToken?: string // JSON pointer
  endMs?: number
  endToken?: string // JSON pointer
}

export default function useAlignTokens(): (
  transcriptId: string,
  options: AlignTokensParams,
) => Promise<APITranscriptToken[] | null> {
  const apiFetch = useAPIFetch(false)

  const alignTokens = useCallback(
    async (
      transcriptId: string,
      options: AlignTokensParams = {},
    ): Promise<APITranscriptToken[] | null> => {
      try {
        const response = await apiFetch.execute(
          `/api/v2/transcription/${transcriptId}/tokens?${new URLSearchParams(
            /* @ts-ignore-next-line ts(2345) */
            snakeCaseDeep(options),
          )}`,
          {method: 'GET'},
        )
        const json = await response.json()

        if (!response.ok) {
          throw new Error(`${json.status} ${json.msg_code} ${json.detail}`)
        }

        return camelCaseDeep(json) as APITranscriptToken[]
      } catch (error) {
        if (error instanceof Error) {
          if (error.name === 'AbortError') return null
          throw error
        }
      }
      return null
    },

    [apiFetch],
  )

  return alignTokens
}
