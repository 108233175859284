import clsx from 'clsx'
import {memo, useId} from 'react'
import {ScaleLinear} from 'd3-scale'

import {APITranscriptToken} from '../../../types/types'

import {MIN_TOKEN_DURATION_MS} from './constants'

const TOKEN_HEIGHT = 32

interface GraphTokenProps {
  token: APITranscriptToken
  /** Uses explicit startMs first, and falls back to token.startMs */
  startMs?: number
  /** Uses explicit durationMs first, and falls back to token.durationMs */
  durationMs?: number
  selected?: boolean
  xScale: ScaleLinear<number, number, number>
  onMouseDown?: (event: React.MouseEvent<SVGRectElement, MouseEvent>) => void
  onMouseUp?: (event: React.MouseEvent<SVGRectElement, MouseEvent>) => void
  onClick?: (event: React.MouseEvent<SVGRectElement, MouseEvent>) => void
}

function TimestampGraphToken(props: GraphTokenProps): React.ReactNode {
  const {
    selected,
    token,
    xScale,
    startMs: overrideStartMs,
    durationMs: overrideDurationMs,
    onMouseDown,
    onClick,
    onMouseUp,
  } = props
  const uid = useId()

  const startMs = overrideStartMs ?? token.startMs
  const durationMs = overrideDurationMs ?? token.durationMs
  const x = xScale(startMs)
  const width = Math.max(xScale(startMs + durationMs) - xScale(startMs), MIN_TOKEN_DURATION_MS)

  return (
    <g
      className={clsx('token', selected ? 'cursor-move' : 'cursor-pointer')}
      onMouseDown={onMouseDown}
      onClick={onClick}
      onMouseUp={onMouseUp}
    >
      <defs>
        <clipPath id={`token-container-${uid}`}>
          <rect x={x} y={0} width={width} height={TOKEN_HEIGHT} />
        </clipPath>
      </defs>
      <rect
        className={clsx(
          token.aligned !== false ? 'stroke-gray-300' : 'stroke-red-600',
          selected ? 'fill-blue-200' : 'fill-white',
        )}
        x={x}
        y={0}
        width={width}
        height={TOKEN_HEIGHT}
      />
      <text
        className={clsx('select-none', token.aligned !== false ? 'fill-black' : 'fill-red-600')}
        clipPath={`url(#token-container-${uid})`}
        x={x + 2}
        y={21}
        width={width}
        height={TOKEN_HEIGHT}
      >
        {token.transcript}
      </text>
      {/* resize handles */}
      <rect
        className="token-start cursor-ew-resize"
        x={x}
        y={0}
        width={2}
        height={TOKEN_HEIGHT}
        fill="transparent"
      />
      <rect
        className="token-end cursor-ew-resize"
        x={x + width - 2}
        y={0}
        width={2}
        height={TOKEN_HEIGHT}
        fill="transparent"
      />
    </g>
  )
}

export default memo(TimestampGraphToken)
