interface GenericErrorStateProps {
  title?: React.ReactNode
  description?: React.ReactNode
  action?: React.ReactNode
}

export default function GenericErrorState(props: GenericErrorStateProps): React.ReactNode {
  const {
    title = 'Something went wrong.',
    description,
    action = 'Please refresh the page to try again.',
  } = props

  return (
    <div className="flex h-2/3 flex-col items-center justify-center gap-2.5 p-2.5">
      {!!title && <p className="text-lg font-bold">{title}</p>}
      {!!description && <p>{description}</p>}
      {!!action && <p>{action}</p>}
    </div>
  )
}
