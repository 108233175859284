import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {isEqual} from 'lodash-es'

import {APITranscript, TranscriptSelection, TranscriptSelectionNode} from '../types/types'
import {getTranscriptSelection, toTranscriptPath} from '../utils/transcriptUtils'

function getEleFromTranscriptSelection(
  transcriptSelectionNode: TranscriptSelectionNode | null,
): Element | null {
  if (!transcriptSelectionNode) return null

  const {type, sliceIndex, tokenIndex} = transcriptSelectionNode
  let ele = document.querySelector(`[data-path="${toTranscriptPath(sliceIndex, tokenIndex)}"]`)

  // if selection is on a token-space the tokenIndex refers to the preceding token so we need to get the next sibling
  if (type === 'token-space') {
    ele = ele?.nextSibling as Element
  }

  return ele
}

export function setWindowSelection(
  transcriptSelection: TranscriptSelection | null,
  collapse: boolean,
): void {
  if (!transcriptSelection) return
  const range = document.createRange()
  const startEle = getEleFromTranscriptSelection(transcriptSelection.start)
  const endEle = getEleFromTranscriptSelection(transcriptSelection.end)
  if (startEle) {
    range.setStart(startEle.childNodes[0], transcriptSelection.start?.textOffset || 0)
  }
  if (endEle) {
    range.setEnd(endEle.childNodes[0], transcriptSelection.end?.textOffset || 0)
  }
  if (collapse) {
    range.collapse(true)
  }
  window.getSelection()?.removeAllRanges()
  window.getSelection()?.addRange(range)
}

/**
 * Because we're using contentEditable, we need to manually update the selection when the transcript
 * changes. So that the cursor/selection doesn't jump around as React recreates elements
 */
export type UpdateTranscriptSelectionType = (
  nextTranscriptSelection: TranscriptSelection | null,
  nextCollapse?: boolean,
  force?: boolean,
) => void

export default function useTranscriptSelection(
  transcript?: APITranscript,
  syncWindowSelection = true,
): {
  transcriptSelection: TranscriptSelection | null
  updateTranscriptSelection: UpdateTranscriptSelectionType
} {
  const [transcriptSelection, setTranscriptSelection] = useState<TranscriptSelection | null>(null)
  const transcriptSelectionRef = useRef<TranscriptSelection | null>(null)
  // wrap setTranscriptSelection state with additional ref update to keep them in sync
  const setTranscriptSelectionState = useCallback(
    (nextTranscriptSelection: TranscriptSelection | null): void => {
      setTranscriptSelection(nextTranscriptSelection)
      transcriptSelectionRef.current = nextTranscriptSelection
    },
    [],
  )
  const collapse = useRef<boolean>(true)

  // sync window selection with transcript selection state when the transcript changes
  // because React might recreate elements that will lose the selection in the DOM
  // transcriptSelectionRef is used instead of directly depending on transcriptSelection state
  // in order to preserve the selection direction when the transcript has not changed
  // certain things depend on selection direction (e.g. shift + ←/→ modifying end)
  useEffect(() => {
    if (syncWindowSelection) setWindowSelection(transcriptSelectionRef.current, collapse.current)
  }, [syncWindowSelection, transcript])

  const updateTranscriptSelection = useCallback<UpdateTranscriptSelectionType>(
    /**
     * This function should always be used when modifying the transcript selection. If the browser API's are used directly
     * it will cause a desync between the window selection and the transcriptSelection state maintained here
     *
     * @param nextTranscriptSelection the next transcript selection state
     * @param nextCollapse true if range should be collapsed to caret
     * @param forceSync update the window selection state,
     * if performing a selection change without also modifying the transcript set to true otherwise
     * the React selection state will be updated but the window selection state will not
     */
    (
      nextTranscriptSelection: TranscriptSelection | null,
      nextCollapse = true,
      forceSync = false,
    ) => {
      const selection = nextTranscriptSelection || getTranscriptSelection()
      if (!forceSync && isEqual(selection, transcriptSelection)) {
        return
      }
      setTranscriptSelectionState(selection)

      collapse.current = nextCollapse
      if (forceSync) {
        setWindowSelection(selection, collapse.current)
      }
    },
    [setTranscriptSelectionState, transcriptSelection],
  )

  return useMemo(
    () => ({
      transcriptSelection,
      updateTranscriptSelection,
    }),
    [updateTranscriptSelection, transcriptSelection],
  )
}
