import {Switch} from '@kensho/neo'

import useFeatureFlags from '../../../hooks/useFeatureFlags'
import {RealtimeTranscriptionConfiguration, TranscriptionConfiguration} from '../../../types/types'

interface PartialTranscriptsProps {
  transcriptionConfiguration: RealtimeTranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
}

export default function PartialTranscripts(props: PartialTranscriptsProps): React.ReactNode {
  const {transcriptionConfiguration, setTranscriptionConfiguration} = props
  const flags = useFeatureFlags()

  return (
    <div>
      <Switch
        label="Enable rapid transcription (Beta)"
        checked={Boolean(transcriptionConfiguration.partialTranscripts)}
        disabled={!flags('pt')}
        onChange={(event) => {
          setTranscriptionConfiguration((prevTranscriptionConfiguration) => ({
            ...prevTranscriptionConfiguration,
            partialTranscripts: event.target.checked,
          }))
        }}
      />
    </div>
  )
}
