import {useCallback, useContext, useEffect, useMemo, useState} from 'react'

import UserContext from '../providers/UserContext'
import {
  APIErrorResponse,
  AsyncStatus,
  TranscriptMetadata,
  TranscriptMetadataParams,
} from '../types/types'
import camelCaseDeep from '../utils/camelCaseDeep'

import useAPIFetch from './useAPIFetch'

/**
 * Parses contentRange header into a ui friendly format
 */
function parseContentRange(
  contentRange: string,
): GetBatchTranscriptMetadataResponse['contentRange'] {
  const data = contentRange.split(' ')[1]
  if (data.startsWith('*/')) {
    return {
      startIndex: 0,
      endIndex: 0,
      totalTranscripts: 0,
      hasNextPage: false,
    }
  }
  const [currentRange, total] = data.split('/')
  const [startIndex, endIndex] = currentRange.split('-').map((i) => parseInt(i, 10))
  const max = parseInt(total, 10)
  return {
    startIndex,
    endIndex,
    totalTranscripts: max,
    hasNextPage: endIndex < max - 1,
  }
}

export type Data = TranscriptMetadata[] | APIErrorResponse | null

export interface TranscriptMetadataContentRange {
  startIndex: number
  endIndex: number
  totalTranscripts: number
  hasNextPage: boolean
}

export interface GetBatchTranscriptMetadataResponse {
  status: AsyncStatus
  data: Data
  contentRange: TranscriptMetadataContentRange | null
  refreshData: () => void
  fetchData: () => Promise<void>
}
export default function useGetBatchTranscriptMetadata(
  options?: TranscriptMetadataParams,
): GetBatchTranscriptMetadataResponse {
  const {user} = useContext(UserContext)
  const apiFetch = useAPIFetch()
  const [status, setStatus] = useState<AsyncStatus>('idle')
  const [data, setData] = useState<Data>(null)
  const [refresh, setRefresh] = useState(0)
  const [contentRange, setContentRange] = useState<TranscriptMetadataContentRange | null>(null)

  const params = new URLSearchParams(
    Object.entries(options || {}).filter(([, value]) => value !== undefined),
  ).toString()

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      const response = await apiFetch.execute(`/api/v2/transcriptions?${params}`)
      const json = await response.json()
      if (!response.ok) {
        setData(json)
        setStatus('error')
      }
      const rawContentRange = response.headers.get('content-range')
      if (!rawContentRange) throw new Error('No content range header found')
      setContentRange(parseContentRange(rawContentRange))
      setData(camelCaseDeep(json) as TranscriptMetadata[])
      setStatus('success')
    } catch (error) {
      if (error instanceof Error) {
        if (error.name === 'AbortError') return
      }
      setStatus('error')
    }
  }, [apiFetch, params])

  useEffect(() => {
    if (!user?.token) return
    setStatus('pending')
    fetchData()
  }, [user?.token, refresh, fetchData])

  return useMemo<GetBatchTranscriptMetadataResponse>(() => {
    function refreshData(): void {
      setRefresh((prev) => prev + 1)
    }
    return {status, data, contentRange, refreshData, fetchData}
  }, [status, data, contentRange, fetchData])
}
