import {useState, useEffect} from 'react'

/**
 * Creates an ObjectURL from the source object and manages revocation lifecyle
 */
export default function useObjectURL(object?: File | Blob | MediaSource): string | undefined {
  const [objectURL, setObjectURL] = useState<string>()

  useEffect(() => {
    const url = object ? URL.createObjectURL(object) : undefined
    setObjectURL(url)

    return () => {
      if (url) URL.revokeObjectURL(url)
    }
  }, [object])

  return objectURL
}
