import {useContext, useState} from 'react'
import {Button, DropdownMenu} from '@kensho/neo'
import {useNavigate} from 'react-router-dom'

import SiteAnalyticsContext from '../providers/SiteAnalyticsContext'
import UserContext from '../providers/UserContext'

import SignInDialog from './SignInDialog'

export default function UserInfo(): React.ReactNode {
  const {user, logout} = useContext(UserContext)

  const [signInOpen, setSignInOpen] = useState(false)

  const analytics = useContext(SiteAnalyticsContext)

  const navigate = useNavigate()

  return (
    <>
      {user?.email ? (
        <DropdownMenu
          items={[
            {
              id: 'account',
              label: 'Account',
              onAction() {
                navigate(`/account`)
                analytics.sendEvent('view_account_details')
              },
            },
            {
              id: 'logout',
              label: 'Log out',
              onAction() {
                logout()
                analytics.sendEvent('logout')
              },
            },
          ]}
          position="bottom"
        >
          <Button
            minimal
            icon="UserCircleIcon"
            rightIcon="ChevronDownIcon"
            data-clarity-mask="True"
          >
            Hi {user?.email}
          </Button>
        </DropdownMenu>
      ) : (
        <Button minimal onClick={() => setSignInOpen(true)}>
          Sign in
        </Button>
      )}
      <SignInDialog isOpen={signInOpen} onClose={() => setSignInOpen(false)} />
    </>
  )
}
