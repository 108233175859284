import {HeadlessButton, Popover} from '@kensho/neo'
import {useState} from 'react'

import UserPresence from '../../../components/UserPresence'
import {ConnectedUser} from '../../../providers/MultiplayerProvider'

interface UserPresenceGutterProps {
  users: ConnectedUser[]
}
export default function UserPresenceGutter(props: UserPresenceGutterProps): React.ReactNode {
  const {users} = props
  const [showAllUsers, setShowAllUsers] = useState(false)

  return (
    <div className="mt-9 flex w-16 flex-none cursor-default select-none justify-center -space-x-2 bg-white">
      {users.slice(0, 2).map((user) => (
        <UserPresence user={user} isFollowing={false} size="small" />
      ))}
      {users.length > 2 && (
        <Popover
          content={
            <div className="flex flex-col gap-2 p-4">
              {users.map((user: ConnectedUser) => (
                <UserPresence user={user} isDetailed enablePopover={false} size="small" />
              ))}
            </div>
          }
          isOpen={showAllUsers}
          onOpenChange={setShowAllUsers}
        >
          <HeadlessButton>
            <div className="flex h-6 w-6 items-center justify-center rounded-full border bg-white text-xs">
              {users.length - 2}
            </div>
          </HeadlessButton>
        </Popover>
      )}
    </div>
  )
}
