import {createContext} from 'react'

import {
  APITranscript,
  APITranscriptSlice,
  EditorOperation,
  Mode,
  Stage,
  TranscriptMetadata,
} from '../types/types'

export type TranscriptAction =
  | {type: 'reset'; stage?: Stage}
  | {type: 'setMetadata'; metadata: TranscriptMetadata}
  | {type: 'setTranscript'; transcript: APITranscript}
  | {type: 'appendSlice'; slice: APITranscriptSlice}
  | {type: 'patch'; operation: EditorOperation; undoable?: boolean}
  | {type: 'undo'; operation: EditorOperation}
  | {type: 'redo'; operation: EditorOperation}
  | {type: 'saved'; date: Date}
  | {type: 'setStage'; stage: Stage}
  | {type: 'setMode'; mode?: Mode}

export interface TranscriptState {
  metadata?: TranscriptMetadata
  transcript?: APITranscript
  hasNoSpeech?: boolean
  undoOperations: EditorOperation[]
  redoOperations: EditorOperation[]
  stage: Stage
  mode?: Mode
  lastSaved?: Date
}

export type TranscriptContextValue = TranscriptState & {dispatch: React.Dispatch<TranscriptAction>}

export default createContext<TranscriptContextValue>({
  dispatch: () =>
    /* eslint-disable-next-line no-console */
    console.warn('You must wrap this component in TranscriptProvider before using this method.'),
  undoOperations: [],
  redoOperations: [],
  stage: 'CHOOSE_SOURCE',
})
