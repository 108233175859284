import {Button, Icon, Tooltip, useToaster} from '@kensho/neo'
import React, {useCallback, useContext, useEffect, useState} from 'react'

import MicrophoneAudioVisualization from '../../../components/MicrophoneAudioVisualization'
import useWebsocket, {ReadyState} from '../../../hooks/useWebsocket'
import TranscriptContext from '../../../providers/TranscriptContext'
import UserContext from '../../../providers/UserContext'
import {
  APITranscriptSlice,
  ClientWebsocketMessage,
  RealtimeStatus,
  RealtimeTranscriptionConfiguration,
  ScribeError,
  ServerErrorMessage,
  ServerWebsocketMessage,
  Stage,
} from '../../../types/types'
import camelCaseDeep from '../../../utils/camelCaseDeep'
import parseError from '../../../utils/parseError'
import {
  REALTIME_NUM_CHANNELS,
  REALTIME_SRC_MIME_TYPE,
  REALTIME_TRANSCRIPTION_SAMPLE_RATE,
} from '../constants'

import RecordingManager, {RecordingManagerState} from './RecordingManager'
import useRealtimeBuffer from './utils/useRealtimeBuffer'

interface RealtimeTranscriberProps {
  stage: Stage
  audioInputDeviceId?: string
  transcriptionConfiguration: RealtimeTranscriptionConfiguration
  resetRealtimeData: () => void
  onTranscribingStart: (transcriptId: string) => void
  onTranscribingComplete: () => void
  onAudioFileAvailable: (file: File) => void
  onAudioUnsupported: () => void
  onProcessAudio: (audioData: number[], totalDuration: number) => void
  onError: (error?: ScribeError) => void
  realtimeStatus: RealtimeStatus
  setRealtimeStatus: React.Dispatch<React.SetStateAction<RealtimeStatus>>
}

function sendMessage(message: ClientWebsocketMessage, websocket: WebSocket): void {
  websocket.send(JSON.stringify(message))
}

export default function RealtimeTranscriber(props: RealtimeTranscriberProps): React.ReactNode {
  const {
    stage,
    audioInputDeviceId,
    transcriptionConfiguration,
    resetRealtimeData,
    onTranscribingStart,
    onTranscribingComplete,
    onAudioFileAvailable,
    onAudioUnsupported,
    onProcessAudio,
    onError,
    realtimeStatus,
    setRealtimeStatus,
  } = props
  const {user} = useContext(UserContext)
  const {dispatch: transcriptContextDispatch} = useContext(TranscriptContext)
  const toaster = useToaster()

  const [recordingManagerState, setRecordingManagerState] =
    useState<RecordingManagerState>('uninitialized')
  const [recordingManager, setRecordingManager] = useState<RecordingManager>()
  const [pendingAddDataIds, setPendingAddDataIds] = useState<number[]>([])

  const serverNotResponding = pendingAddDataIds.length >= 3

  const onAddTranscript = useCallback(
    (slice: APITranscriptSlice) => transcriptContextDispatch({type: 'appendSlice', slice}),
    [transcriptContextDispatch],
  )

  // called after server broadcasts transcription ended or client forcefully ends
  const endTranscription = useCallback(() => {
    setRealtimeStatus('ended')
    onTranscribingComplete()
    // create audio file
    if (recordingManager)
      onAudioFileAvailable(
        new File([recordingManager.getRecording()], 'recording.wav', {
          type: REALTIME_SRC_MIME_TYPE,
        }),
      )
  }, [setRealtimeStatus, onTranscribingComplete, recordingManager, onAudioFileAvailable])

  const onTranscriptionError = useCallback(
    (error?: ServerErrorMessage) => {
      recordingManager?.close()
      // end transcription so user can download/edit current progress
      endTranscription()
      onError(error ? parseError(error) : {type: 'realtimeError'})
    },
    [onError, endTranscription, recordingManager],
  )

  const onWebsocketOpen = useCallback(
    (event: WebSocketEventMap['open']) => {
      const ws = event.target as WebSocket

      if (!user?.token) {
        onError({type: 'unauthenticated'})
        return
      }

      sendMessage(
        {
          message: 'Authenticate',
          token: user.token,
        },
        ws,
      )
    },
    [user, onError],
  )
  const onWebsocketError = useCallback(
    (event: WebSocketEventMap['error']) => {
      const ws = event.target as WebSocket
      recordingManager?.close()
      ws.close()
      if (realtimeStatus !== 'unstarted' && realtimeStatus !== 'ended') onTranscriptionError()
    },
    [recordingManager, onTranscriptionError, realtimeStatus],
  )
  const onWebsocketMessage = useCallback(
    (event: WebSocketEventMap['message']) => {
      const data = camelCaseDeep(JSON.parse(event.data)) as ServerWebsocketMessage
      switch (data.message) {
        case 'TranscriptionStarted':
          recordingManager?.resume()
          resetRealtimeData()
          setRealtimeStatus('transcribing')
          onTranscribingStart(data.requestId)
          break
        case 'AddTranscript':
          onAddTranscript(data.transcript)
          break
        case 'EndOfTranscript':
          endTranscription()
          break
        case 'DataAdded': {
          const {sequenceNumber} = data
          setPendingAddDataIds((ids) => ids.filter((id) => id !== sequenceNumber))
          if (realtimeStatus === 'offline' && recordingManagerState === 'paused') {
            recordingManager?.resume()
            setRealtimeStatus('transcribing')
            toaster.show({label: 'You are back online. Recording resumed', intent: 'primary'})
          }
          break
        }
        case 'Error':
          ;(event.target as WebSocket).close()
          onTranscriptionError(data)
          break
        default:
          break
      }
    },
    [
      recordingManager,
      resetRealtimeData,
      setRealtimeStatus,
      onTranscribingStart,
      onAddTranscript,
      endTranscription,
      onTranscriptionError,
      realtimeStatus,
      recordingManagerState,
      toaster,
    ],
  )

  const {readyState: websocketState, websocket} = useWebsocket({
    url: `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/ws`,
    onOpen: onWebsocketOpen,
    onMessage: onWebsocketMessage,
    onError: onWebsocketError,
  })

  const {pushRealtimeBuffer, flushRealtimeBuffer} = useRealtimeBuffer(
    websocket,
    (sequenceValue) => {
      setPendingAddDataIds((ids) => [...ids, sequenceValue])
    },
  )

  const internalOnProcessAudio = useCallback(
    (audioData: number[]) => {
      if (realtimeStatus === 'transcribing') pushRealtimeBuffer(audioData)
      onProcessAudio(audioData, recordingManager?.duration || 0)
    },
    [realtimeStatus, pushRealtimeBuffer, onProcessAudio, recordingManager],
  )

  // create recording manager
  useEffect(() => {
    let nextRecordingManager: RecordingManager | undefined
    let timeoutId: ReturnType<typeof setTimeout>
    if (audioInputDeviceId) {
      nextRecordingManager = new RecordingManager(audioInputDeviceId)
      nextRecordingManager.onStateChange = setRecordingManagerState
      nextRecordingManager.open()
      setRecordingManager(nextRecordingManager)
    }

    return () => {
      nextRecordingManager?.close()
      setRecordingManager(undefined)
      resetRealtimeData()
      clearTimeout(timeoutId)
    }
  }, [audioInputDeviceId, resetRealtimeData])

  // attach handlers to recording manager
  useEffect(() => {
    if (!recordingManager) return

    recordingManager.onError = onAudioUnsupported
    recordingManager.onProcessAudio = internalOnProcessAudio
  }, [recordingManager, onAudioUnsupported, internalOnProcessAudio])

  useEffect(() => {
    if (serverNotResponding) {
      recordingManager?.pause()
      setPendingAddDataIds([])
      setRealtimeStatus('offline')
      toaster.show({
        label: 'Network connection lost. Recording paused.',
        intent: 'danger',
      })
    }
  }, [recordingManager, serverNotResponding, onError, toaster, setRealtimeStatus])

  // client requests to start transcription
  useEffect(() => {
    if (realtimeStatus !== 'starting') return
    recordingManager?.reset()
    if (!websocket) {
      onError()
      return
    }

    sendMessage(
      {
        message: 'StartTranscription',
        audio_format: {
          type: 'RAW',
          encoding: 'pcm_s16le',
          sample_rate_hz: REALTIME_TRANSCRIPTION_SAMPLE_RATE,
          num_channels: REALTIME_NUM_CHANNELS,
        },
        features: {allow_partials: !!transcriptionConfiguration.partialTranscripts},
        hotwords: [...(transcriptionConfiguration.hotwords || [])],
      },
      websocket,
    )
  }, [websocket, transcriptionConfiguration, onError, recordingManager, realtimeStatus])

  // client requests to end transcription
  const finalizeTranscription = useCallback(() => {
    // Stop recording audio
    recordingManager?.close()
    setRealtimeStatus('finalizing')

    if (!websocket) {
      // end immediately
      endTranscription()
      return
    }

    // notify Scribe that the transcript has ended
    sendMessage(
      {
        message: 'EndOfStream',
        last_sequence_number: flushRealtimeBuffer(true),
      },
      websocket,
    )

    // TODO: put timeout so we can expose transcript if server doesn't respond with EndOfTranscript
    // in a reasonable amount of time
  }, [recordingManager, setRealtimeStatus, websocket, flushRealtimeBuffer, endTranscription])

  useEffect(() => {
    if (
      realtimeStatus === 'unready' &&
      websocketState === ReadyState.OPEN &&
      recordingManagerState === 'recording'
    ) {
      setRealtimeStatus('unstarted')
    }
  }, [websocketState, recordingManagerState, realtimeStatus, setRealtimeStatus])

  // hide but keep mounted for transcript data received from server after user finishes recording
  if (stage === 'POST_TRANSCRIPTION') return null
  return (
    <div className="flex items-center gap-10">
      {realtimeStatus === 'offline' && (
        <Tooltip content="You are offline. The current transcription has been paused and will resume when you reconnect.">
          <Icon icon="ExclamationCircleIcon" />
        </Tooltip>
      )}
      {realtimeStatus === 'transcribing' && (
        <>
          <Tooltip
            position="top"
            content={
              recordingManagerState === 'recording' ? 'Pause recording' : 'Continue recording'
            }
          >
            <Button
              icon={recordingManagerState === 'recording' ? 'PauseIcon' : 'PlayIcon'}
              intent="primary"
              rounded
              size="small"
              aria-label={
                recordingManagerState === 'recording' ? 'Pause recording' : 'Continue recording'
              }
              onClick={
                recordingManagerState === 'recording'
                  ? () => recordingManager?.pause()
                  : () => recordingManager?.resume()
              }
            />
          </Tooltip>
        </>
      )}
      {(realtimeStatus === 'transcribing' ||
        realtimeStatus === 'finalizing' ||
        realtimeStatus === 'offline') && (
        <Tooltip
          content={realtimeStatus === 'finalizing' ? 'Finalizing…' : 'Finalize recording'}
          position="top"
        >
          <Button
            size="small"
            rounded
            intent="danger"
            icon="StopIcon"
            disabled={realtimeStatus === 'finalizing'}
            aria-label="Finalize recording"
            onClick={finalizeTranscription}
          />
        </Tooltip>
      )}
      {(realtimeStatus === 'transcribing' || realtimeStatus === 'unstarted') && (
        <div className="ml-10">
          <MicrophoneAudioVisualization
            volumeLevel={
              recordingManagerState === 'recording' ? recordingManager?.volumeLevel || 0 : 0
            }
          />
        </div>
      )}
    </div>
  )
}
