import {useCallback, useContext} from 'react'

import UserContext from '../providers/UserContext'
import {FeatureFlag} from '../types/types'

/**
 * Provides a function that can be used to determine whether or not a certain feature is available
 *
 * @example
 * const flags = useFeatureFlags()
 *
 * flags('feature_a') && ...
 */
export default function useFeatureFlags(): (flag: FeatureFlag) => boolean {
  const {user} = useContext(UserContext)

  const hasFlag = useCallback((flag: FeatureFlag) => Boolean(user?.features?.[flag]), [user])

  return hasFlag
}
