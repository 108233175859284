import {useDebouncedValue} from '@kensho/tacklebox'
import {useEffect, useReducer} from 'react'
import {DropzoneOptions} from 'react-dropzone'
import {useToaster} from '@kensho/neo'

import useDeleteTranscript from '../../../api/useDeleteTranscript'
import useGetBatchTranscriptMetadata from '../../../api/useGetBatchTranscriptMetadata'
import useAsyncInterval from '../../../hooks/useAsyncInterval'
import {TranscriptMetadata} from '../../../types/types'
import assertNever from '../../../utils/assertNever'
import FileUpload from '../../transcription/FileUpload'

import ActionBar from './ActionBar'
import TranscriptMetadataTable from './TranscriptMetadataTable'
import PaginationBar from './PaginationBar'
import {TRANSCRIPTS_PER_PAGE} from './shared'
import {OptionsAction, OptionsState} from './types'

function optionsReducer(state: OptionsState, action: OptionsAction): OptionsState {
  switch (action.type) {
    case 'search':
      return {...state, search: action.payload, page: 0}
    case 'sort':
      return {...state, sort: action.payload.sort, direction: action.payload.direction, page: 0}
    case 'page':
      return {...state, page: action.payload}
    default:
      return assertNever(action, 'Unknown action type')
  }
}

interface HistoricalTranscriptsTableProps {
  handleFileDrop: DropzoneOptions['onDrop']
  onRecordButtonClick: () => void
  shouldPoll: boolean
}
export default function HistoricalTranscriptsTable(
  props: HistoricalTranscriptsTableProps,
): React.ReactNode {
  const {handleFileDrop, onRecordButtonClick, shouldPoll} = props
  const [{page, search, direction, sort}, optionsDispatch] = useReducer(optionsReducer, {
    search: '',
    sort: 'updated_on',
    direction: 'descending',
    page: 0,
  })

  const debouncedSearch = useDebouncedValue(search, 200)
  const {data, status, contentRange, refreshData, fetchData} = useGetBatchTranscriptMetadata({
    page: `${page}`,
    page_size: `${TRANSCRIPTS_PER_PAGE}`,
    name: debouncedSearch,
    sort,
    direction,
  })
  const deleteTranscriptMutation = useDeleteTranscript()
  const toaster = useToaster()

  useAsyncInterval(fetchData, status === 'success' && shouldPoll ? 5 * 1000 : null)

  useEffect(() => {
    if (status === 'error') {
      // We have requested an invalid page, so go back to the first page which is always safe
      if (data && 'msg_code' in data && data.msg_code === 'S2T-TR-0001') {
        optionsDispatch({type: 'page', payload: 0})
        return
      }
      throw new Error(`HistoricalTranscriptsTable, error was: ${JSON.stringify(data)}`)
    }
  }, [data, status])

  async function deleteTranscript(transcript: TranscriptMetadata): Promise<void> {
    try {
      const resp = await deleteTranscriptMutation(transcript.id)
      if (!resp.ok) throw new Error()
      if (contentRange) {
        const {startIndex, endIndex} = contentRange
        // if the current page is empty after deleting the transcript, go to the previous page if it exists
        if (startIndex === endIndex - 1 && page > 0) {
          optionsDispatch({type: 'page', payload: page - 1})
        } else {
          refreshData()
        }
      }
    } catch (err) {
      toaster.show({
        label: `Error deleting transcript ${transcript.name}`,
        intent: 'danger',
      })
    }
  }

  const transcripts = data && !('msg_code' in data) ? data : null

  const isPending = status === 'pending'
  return (
    <div className="mt-12">
      <FileUpload handleFileDrop={handleFileDrop}>
        {({open}) => (
          <div className="flex max-h-[calc(100vh-160px)] w-[768px] max-w-[100vw] flex-col items-center">
            <div className="mb-11 w-full">
              <ActionBar
                search={search}
                optionsDispatch={optionsDispatch}
                onRecordButtonClick={onRecordButtonClick}
                onUploadButtonClick={open}
              />
            </div>
            <TranscriptMetadataTable
              deleteTranscript={deleteTranscript}
              isPending={isPending}
              transcripts={transcripts}
              sort={sort}
              direction={direction}
              optionsDispatch={optionsDispatch}
              refreshData={refreshData}
            />
            {!isPending && transcripts && transcripts.length === 0 && (
              <div className="p-4 text-center">
                {search ? (
                  <>No results matched your search</>
                ) : (
                  <>{`You don't have transcripts at the moment.`}</>
                )}
              </div>
            )}

            <PaginationBar
              page={page}
              optionsDispatch={optionsDispatch}
              isPending={isPending}
              contentRange={contentRange}
            />
          </div>
        )}
      </FileUpload>
    </div>
  )
}
