import {useCallback, useRef, useState} from 'react'

export default function useFocusWithin(): [boolean, (node: HTMLElement | null) => void] {
  const [isFocused, setIsFocused] = useState(false)
  const elementRef = useRef<HTMLElement | null>(null)

  const onFocusIn = useCallback(() => {
    setIsFocused(true)
  }, [])
  const onFocusOut = useCallback(() => {
    setIsFocused(false)
  }, [])

  const focusCallback = useCallback(
    (node: HTMLElement | null) => {
      if (elementRef.current) {
        elementRef.current.removeEventListener('focusin', onFocusIn)
        elementRef.current.removeEventListener('focusout', onFocusOut)
      }

      elementRef.current = node
      if (node) {
        node.addEventListener('focusin', onFocusIn)
        node.addEventListener('focusout', onFocusOut)
      }
    },
    [onFocusIn, onFocusOut],
  )

  return [isFocused, focusCallback]
}
