import {css} from '@emotion/react'

interface SectionProps {
  children: React.ReactNode
  backgroundColor?: string
  title?: React.ReactNode
  description?: string
}

const sectionCss = css`
  overflow: hidden;
  width: 100%;
  padding: 50px 0;

  @media screen and (max-width: 1024px) {
    padding: 30px 0;
  }
`

const headerCss = css`
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    margin: 0 12px 20px;
  }
`

const headingCss = css`
  margin: 0 0 6px 0;
`

const descriptionCss = css`
  margin-bottom: 30px;
`

const innerCss = css`
  margin: 10px auto 0;
  max-width: 1000px;
`

const contentCss = css`
  display: flex;

  @media screen and (max-width: 1024px) {
    margin: 0 12px 0;
  }

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

export default function Section(props: SectionProps): React.ReactNode {
  const {children, backgroundColor = 'transparent', title, description} = props

  return (
    <section
      css={[
        sectionCss,
        css`
          background-color: ${backgroundColor};
        `,
      ]}
    >
      <div css={innerCss}>
        {title && (
          <header css={headerCss}>
            <h2 css={headingCss} className="text-xl font-bold">
              {title}
            </h2>
            {description && <p css={descriptionCss}>{description}</p>}
          </header>
        )}
        <div css={contentCss}>{children}</div>
      </div>
    </section>
  )
}
