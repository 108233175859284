import clsx from 'clsx'
import {debounce} from 'lodash-es'
import {memo, useCallback, useContext, useEffect, useMemo, useState} from 'react'

import SpellCheckContext from '../../../providers/SpellCheckContext'
import {APITranscriptToken, Stage} from '../../../types/types'
import getSpeakerColor from '../../../utils/getSpeakerColor'
import hexToRGBA from '../../../utils/hexToRGBA'
import {toTranscriptPath} from '../../../utils/transcriptUtils'

import TokenPopover from './TokenPopover'

interface TranscriptTokenProps {
  stage: Stage
  token: APITranscriptToken
  sliceIndex: number
  tokenIndex: number
  current?: boolean
  color?: string
  onClick?: (token: APITranscriptToken) => void
}

function TranscriptToken(props: TranscriptTokenProps): React.ReactNode {
  const {token, sliceIndex, tokenIndex, color = getSpeakerColor(0), current, onClick, stage} = props
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isSpellingError, setIsSpellingError] = useState(false)
  const {check} = useContext(SpellCheckContext)

  const checkSpelling = useCallback(
    (word = '') => {
      setIsSpellingError(!check(word))
    },
    [check],
  )

  const checkSpellingDebounced = useMemo(() => debounce(checkSpelling, 2000), [checkSpelling])

  useEffect(() => {
    checkSpellingDebounced(token.transcript)
  }, [checkSpellingDebounced, token.transcript])

  return (
    <span
      data-type="token"
      data-start-ms={token.startMs}
      data-path={toTranscriptPath(sliceIndex, tokenIndex)}
      className={clsx(
        /* Without this position rule, rendering large transcripts becomes
         * unacceptably slow on mouseover due to the browser's collision-detection */
        'relative',
        'border-b-solid border-b-2 border-b-transparent',
        token.isPartial && stage === 'TRANSCRIPTION' && 'text-brand-800',
        token.isRecent && stage === 'TRANSCRIPTION' && 'font-bold',
        isSpellingError &&
          stage === 'POST_TRANSCRIPTION' &&
          'underline decoration-red-600 decoration-wavy',
      )}
      style={{
        ...(current && {background: hexToRGBA(color, 0.3), borderBottomColor: hexToRGBA(color)}),
      }}
      onClick={() => {
        onClick?.(token)
      }}
      onContextMenu={(e) => {
        if (isSpellingError) {
          e.preventDefault()
          setPopoverOpen((prev) => !prev)
        }
      }}
      onKeyPress={(e) => e.key === 'Enter' && onClick?.(token)}
    >
      {token.transcript}
      {popoverOpen && isSpellingError && (
        <TokenPopover
          sliceIndex={sliceIndex}
          tokenIndex={tokenIndex}
          token={token}
          onClose={() => {
            setPopoverOpen(false)
            checkSpelling(token.transcript)
          }}
        />
      )}
    </span>
  )
}

export default memo(TranscriptToken)
