import {ClassNames} from '@emotion/react'
import {CSSTransition} from 'react-transition-group'

interface CrossFadeProps {
  children: React.ReactNode
  in?: boolean
  enterDuration?: number
  exitDuration?: number
}

export default function CrossFade({
  children,
  in: inProp,
  enterDuration = 750,
  exitDuration = 500,
}: CrossFadeProps): React.ReactNode {
  return (
    <ClassNames>
      {({css}) => (
        <CSSTransition
          in={inProp === undefined ? true : inProp}
          mountOnEnter
          unmountOnExit
          classNames={{
            enter: css`
              opacity: 0;
            `,
            enterActive: css`
              opacity: 1;
              transition: opacity ease ${enterDuration}ms;
            `,
            exit: css`
              opacity: 1;
              position: absolute;
            `,
            exitActive: css`
              opacity: 0;
              position: absolute;
              transition: opacity ease ${exitDuration}ms;
            `,
          }}
          timeout={{
            enter: enterDuration,
            exit: exitDuration,
          }}
        >
          {children}
        </CSSTransition>
      )}
    </ClassNames>
  )
}
