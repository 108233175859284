export interface SmoothScrollElementOptions {
  top: number
  duration?: number
}

/**
 * Wraps the native Element.scroll() with behavior: 'smooth' in a promise
 * and periodically checks if the target scroll position has been reached with requestAnimationFrame
 * to resolve once the scroll has finished
 */
export default function smoothScrollElement<T extends HTMLElement>(
  element: T,
  options: SmoothScrollElementOptions,
): Promise<T> {
  const {top: rawTop, duration = 2000} = options
  const top = Math.max(0, Math.floor(rawTop))
  const difference = Math.abs(top - element.scrollTop)
  if (difference === 0) return Promise.resolve(element)

  let startTime: number
  return new Promise((resolve) => {
    element.scroll({top, behavior: 'smooth'})

    const step = (timestamp: number): void => {
      if (startTime === undefined) startTime = timestamp
      // jump to target if we haven't reached it before max scroll duration
      // this could happen if the scrollHeight of the container changes during scroll
      if (startTime !== undefined && timestamp - startTime >= duration) {
        /* eslint-disable no-param-reassign */
        element.scrollTop = top
        resolve(element)
        return
      }

      if (element.scrollTop !== top) {
        window.requestAnimationFrame(step)
        return
      }

      resolve(element)
    }

    window.requestAnimationFrame(step)
  })
}
