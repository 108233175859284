import Transcription from '../core/transcription/Transcription'

import BasePage from './BasePage'

export default function TranscriptionPage(): React.ReactNode {
  return (
    <BasePage footer={false}>
      <Transcription />
    </BasePage>
  )
}
