import {
  ChangeEvent,
  KeyboardEventHandler,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useToaster} from '@kensho/neo'

import useUpdateTranscriptMetadata from '../api/useUpdateTranscriptMetadata'
import {AsyncStatus} from '../types/types'
import {useTranscriptNameContext} from '../core/historicalTranscripts/HistoricalTranscriptsTable/TranscriptName/TranscriptNameContext'
import validateTranscriptName from '../utils/validateTranscriptName'
import SiteAnalyticsContext from '../providers/SiteAnalyticsContext'

function getCaret(el: HTMLDivElement | HTMLInputElement | null): number {
  let caretAt = 0
  const windowSelection = window.getSelection()

  if (!windowSelection || windowSelection.rangeCount === 0 || !el) return caretAt

  const range = windowSelection.getRangeAt(0)

  const preRange = range.cloneRange()
  preRange.selectNodeContents(el)

  preRange.setEnd(range.endContainer, range.endOffset)

  caretAt = preRange.toString().length

  return caretAt
}

function setCaret(el: HTMLDivElement | HTMLInputElement, offset: number): void {
  const range = document.createRange()

  const start = el.childNodes.length ? el.childNodes[0] : null

  if (start) {
    range.setStart(start, offset)
  } else {
    range.setStart(el, offset)
  }

  range.collapse(true)

  window.getSelection()?.removeAllRanges()
  window.getSelection()?.addRange(range)
}

export default function useEditableTranscriptName({
  name,
  id,
  onSuccess,
  onUpdateName,
}: {
  name: string
  id: string
  onSuccess?: () => void
  onUpdateName?: () => void
}): {
  isEditing: boolean
  value: string
  contentRef: RefObject<HTMLInputElement | HTMLDivElement>
  status: AsyncStatus
  getTitleProps: () => {
    value: string
    onInput: (event: ChangeEvent<HTMLInputElement | HTMLDivElement>) => void
    onBlur: () => Promise<void>
    onKeyDown: KeyboardEventHandler<HTMLInputElement | HTMLDivElement>
  }
  getButtonProps: () => {
    onClick: () => void
  }
} {
  const {isEditing, setIsEditing} = useTranscriptNameContext(id)
  const [value, setValue] = useState(name)
  const [pristineValue, setPristineValue] = useState(name)
  const toaster = useToaster()
  const [status, setStatus] = useState<AsyncStatus>('idle')
  const updateTranscriptMetadata = useUpdateTranscriptMetadata()

  const analytics = useContext(SiteAnalyticsContext)

  const contentRef = useRef<HTMLDivElement | HTMLInputElement>(null)
  const caretPos = useRef(0)

  useEffect(() => {
    if (isEditing && contentRef.current != null) {
      if (contentRef.current instanceof HTMLDivElement) {
        setCaret(contentRef.current, caretPos.current)
      }
      contentRef.current.focus()
    }
  }, [isEditing, value])

  const getTitleProps = useCallback(() => {
    async function handleEditSubmit(): Promise<void> {
      setIsEditing(false)
      if (value === pristineValue) return
      const error = validateTranscriptName(value)
      if (error) {
        toaster.show({label: error, intent: 'danger'})
        caretPos.current = pristineValue.length
        setValue(pristineValue)
        return
      }
      setStatus('pending')
      try {
        if (onUpdateName) {
          onUpdateName()
        } else {
          const resp = await updateTranscriptMetadata(id, value)
          if (!resp.ok) {
            throw new Error()
          }
        }

        setPristineValue(value)
        setStatus('success')
        analytics.sendEvent('edit_title')

        if (onSuccess) {
          onSuccess()
        }
      } catch (e) {
        toaster.show({
          intent: 'danger',
          label: `Failed to change transcript name to "${value}", reverting to "${pristineValue}"`,
        })
        setStatus('error')
        setValue(pristineValue)
        caretPos.current = pristineValue.length
      }
    }

    return {
      value,
      onInput: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLDivElement>) => {
        if ('value' in e.target) {
          setValue(e.target.value)
        } else {
          caretPos.current = getCaret(contentRef.current)
          setValue(e.target.innerText)
        }
      },
      onFocus: (e: React.FocusEvent<HTMLInputElement | HTMLDivElement>) => {
        if (e.target instanceof HTMLInputElement) {
          e.target.select()
        } else {
          window.getSelection()?.selectAllChildren(e.currentTarget)
        }
      },
      onBlur: () => handleEditSubmit(),
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>) => {
        if (e.key === 'Enter') {
          handleEditSubmit()
        }
        if (e.key === 'Escape') {
          setValue(pristineValue)
          caretPos.current = pristineValue.length
        }
      },
    }
  }, [
    value,
    setIsEditing,
    pristineValue,
    toaster,
    onUpdateName,
    onSuccess,
    updateTranscriptMetadata,
    id,
    analytics,
  ])

  const getButtonProps = useCallback(
    () => ({
      onClick: () => {
        setIsEditing(true)
      },
    }),
    [setIsEditing],
  )
  return {
    isEditing,
    value,
    contentRef,
    status,
    getTitleProps,
    getButtonProps,
  }
}
