import {mapKeys, camelCase, mapValues, isArray, isPlainObject} from 'lodash-es'

/**
 * Type-safe wrapper for isPlainObject.
 */
function isObject(item: unknown): item is Record<string, unknown> {
  return isPlainObject(item)
}

export default function camelCaseDeep(item: unknown): unknown {
  if (isObject(item)) {
    const camelCased = mapKeys(item, (_, key: string) => {
      if (!Number(key)) {
        return camelCase(key)
      }
      return key
    })
    return mapValues(camelCased, camelCaseDeep)
  }
  if (isArray(item)) {
    return item.map(camelCaseDeep)
  }

  return item
}
