import {BrushSelection, brushX} from 'd3-brush'
import {ScaleLinear} from 'd3-scale'
import {select} from 'd3-selection'
import {useEffect, useRef, useState} from 'react'

import {AXIS_HEIGHT} from './constants'

// default bounds for the brush as a percentage of the width
const DEFAULT_LEFT_BOUND = 0.25
const DEFAULT_RIGHT_BOUND = 0.75

interface Props {
  currentTimeMs: number
  paused: boolean
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  xScale: ScaleLinear<number, number, number>
  width: number
  height: number
}

export default function PlaybackBrush(props: Props): React.ReactNode {
  const {currentTimeMs, paused, seekMedia, xScale, width, height} = props
  const brushRef = useRef<SVGGElement | null>(null)
  const [bounds, setBounds] = useState<{min: number; max: number}>({
    min: width * DEFAULT_LEFT_BOUND,
    max: width * DEFAULT_RIGHT_BOUND,
  })
  const boundsRef = useRef(bounds)
  useEffect(() => {
    boundsRef.current = bounds
  }, [bounds])

  useEffect(() => {
    if (!brushRef.current) return

    const brush = brushX<number>()
      .extent([
        [0, 0],
        [width, AXIS_HEIGHT + 4],
      ])
      .on('end', ({selection, sourceEvent}) => {
        // ignore programmatic brush moves
        if (!sourceEvent) return
        // ignore brush moves that don't change bounds
        if (
          selection &&
          selection[0] === boundsRef.current.min &&
          selection[1] === boundsRef.current.max
        )
          return

        const min = Math.max(
          selection ? selection[0] : (boundsRef.current.min ?? width * DEFAULT_LEFT_BOUND),
          20,
        )
        const max = Math.min(
          width - 20,
          Math.max(
            min + 200,
            selection ? selection[1] : (boundsRef.current.max ?? width * DEFAULT_RIGHT_BOUND),
          ),
        )
        setBounds({min, max})

        // force brush reposition in case the snapping to min/max resulted in different numbers
        // for example: a click without dragging should reset to previous bounds
        if (brushRef.current)
          select<SVGGElement, number>(brushRef.current).call(brush).call(brush.move, [min, max])
      })

    const initialSelection: BrushSelection = [
      width * DEFAULT_LEFT_BOUND,
      width * DEFAULT_RIGHT_BOUND,
    ]
    select<SVGGElement, number>(brushRef.current).call(brush).call(brush.move, initialSelection)
    setBounds({min: initialSelection[0], max: initialSelection[1]})
  }, [height, width])

  // loop media when playback reaches the end
  useEffect(() => {
    if (paused) return

    const currentPosition = xScale(currentTimeMs)
    if (currentPosition < bounds.min - 100 || currentPosition > bounds.max) {
      seekMedia({timeSeconds: xScale.invert(bounds.min) / 1000, play: true})
    }
  }, [bounds, paused, currentTimeMs, seekMedia, xScale])

  return <g className="playback-brush" ref={brushRef} />
}
