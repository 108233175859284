import {useInterval} from '@kensho/tacklebox'
import {useState} from 'react'

interface AudioIndicatorProps {
  volumeLevel: number
}

const SILENT_THRESHOLD = 0.15
const LOW_THRESHOLD = 0.3
const HIGH_THRESHOLD = 0.8
type AudioLevelBucket = 'silent' | 'low' | 'medium' | 'high'

function getAudioLevelBucket(volumeLevel: number): AudioLevelBucket {
  if (volumeLevel < SILENT_THRESHOLD) return 'silent'
  if (volumeLevel < LOW_THRESHOLD) return 'low'
  if (volumeLevel < HIGH_THRESHOLD) return 'medium'
  return 'high'
}

export default function MicrophoneAudioVisualization({
  volumeLevel,
}: AudioIndicatorProps): React.ReactNode {
  const [audioLevel, setAudioLevel] = useState(10)

  const audioLevelBucket = getAudioLevelBucket(volumeLevel)

  // if audioLevelBucket stays the same, change the audioLevel to make the bars jiggle
  useInterval(() => {
    if (audioLevelBucket === 'silent') {
      setAudioLevel(10)
    } else if (audioLevelBucket === 'low') {
      setAudioLevel(audioLevel === 40 ? 20 : 40)
    } else if (audioLevelBucket === 'medium') {
      setAudioLevel(audioLevel === 41 ? 60 : 41)
    } else {
      setAudioLevel(audioLevel === 61 ? 80 : 61)
    }
  }, 500)

  const sideBarStyle = {height: `${audioLevel * (volumeLevel < SILENT_THRESHOLD ? 1 : 0.8)}%`}

  return (
    <div className="flex size-8 items-center justify-center gap-0.5 rounded-full bg-brand-700">
      <div className="w-1 rounded bg-white transition-all duration-500" style={sideBarStyle} />
      <div
        className="w-1 rounded bg-white transition-all delay-[50ms] duration-500"
        style={{height: `${audioLevel}%`}}
      />
      <div
        className="w-1 rounded bg-white transition-all delay-100 duration-500"
        style={sideBarStyle}
      />
    </div>
  )
}
