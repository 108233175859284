import {forwardRef} from 'react'
import {Link as RouterLink, LinkProps as RouterLinkProps} from 'react-router-dom'

import {ENDPOINTS} from '../constants'

export interface LinkProps extends RouterLinkProps {
  // override automatic detection of external vs internal links
  routing?: 'client' | 'server'
  to: string
}

/**
 * converts a glob string into a regex and tests the input
 * query params and hash fragments will be ignored
 */
function globMatch(globString: string, urlPath: string): boolean {
  const regexp = new RegExp(
    `^${globString
      .replace(/([.?+^$[\]\\(){}|/-])/g, '\\$1')
      .replace(/\*/g, '.*')}\\/?(\\?.*)?(#.*)?$`,
  )
  return regexp.test(urlPath)
}

// these paths cannot be handled with client-side routing
const PROXIED_PATHS = ['/api*', ...Object.values(ENDPOINTS)]
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const BLOB_REGEX = /^blob:/
const EXTERNAL_LINK_REGEX = /^https?:\/\//

function Link(props: LinkProps, ref: React.Ref<HTMLAnchorElement>): React.ReactNode {
  const {children, to, routing, ...rest} = props

  // email
  if (to.startsWith('mailto:')) {
    return (
      <a ref={ref} href={to} {...rest}>
        {children || to}
      </a>
    )
  }
  // email
  if (EMAIL_REGEX.test(to)) {
    return (
      <a ref={ref} href={`mailTo:${to}`} {...rest}>
        {children || to}
      </a>
    )
  }

  // blob
  if (BLOB_REGEX.test(to)) {
    return (
      <a ref={ref} href={to} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    )
  }

  const isAbsoluteUrl = EXTERNAL_LINK_REGEX.test(to)
  const isProxiedPath = PROXIED_PATHS.some((pathPrefix) => globMatch(pathPrefix, to))

  // server-side or external
  if (isAbsoluteUrl || isProxiedPath || routing === 'server') {
    return (
      <a
        ref={ref}
        href={to}
        target={isAbsoluteUrl ? '_blank' : '_self'}
        rel={isAbsoluteUrl ? 'noopener noreferrer' : ''}
        {...rest}
      >
        {children}
      </a>
    )
  }

  // includes hash so use regular anchor for native browser scrollTo behavior
  if (routing !== 'client' && to.includes('#')) {
    return (
      <a ref={ref} href={to} {...rest}>
        {children}
      </a>
    )
  }

  // purely client-side route
  return (
    <RouterLink ref={ref} to={to} {...rest}>
      {children}
    </RouterLink>
  )
}

export default forwardRef(Link)
