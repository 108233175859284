import {useState, ReactNode, useContext} from 'react'

import {
  BatchMachineTranscriptionConfiguration,
  RealtimeStatus,
  TranscriptionConfiguration,
} from '../../../types/types'
import TranscriptContext from '../../../providers/TranscriptContext'

import {FormState} from './FormState'
import BatchConfigForm from './BatchConfigForm'
import RealtimeConfigForm from './RealtimeConfigForm'

interface TranscriptionConfigProps {
  mediaFile?: File
  transcriptionConfiguration: TranscriptionConfiguration
  setTranscriptionConfiguration: React.Dispatch<React.SetStateAction<TranscriptionConfiguration>>
  audioInputDeviceId?: string
  onAudioInputDeviceChange: (nextAudioDeviceInfoId?: string) => void
  setTranscriptId: (nextTranscriptId?: string) => void
  resetAudioAndTranscript: () => void
  setRealtimeStatus: React.Dispatch<React.SetStateAction<RealtimeStatus>>
  realtimeStatus: RealtimeStatus
}
export default function TranscriptionConfig(props: TranscriptionConfigProps): ReactNode {
  const {
    mediaFile,
    transcriptionConfiguration,
    setTranscriptionConfiguration,
    setTranscriptId,
    resetAudioAndTranscript,
    onAudioInputDeviceChange,
    audioInputDeviceId,
    setRealtimeStatus,
    realtimeStatus,
  } = props

  const {mode} = useContext(TranscriptContext)

  const defaultHotwords =
    'hotwords' in transcriptionConfiguration ? transcriptionConfiguration.hotwords : [] || []
  const [formState, setFormState] = useState<FormState>({
    transcriptName: transcriptionConfiguration.name || '',
    hotwords: new Set<string>(defaultHotwords),
    errors: {},
  })

  return (
    <div className="mx-auto mt-10 h-full w-[550px] overflow-auto px-6 py-5">
      {mode === 'BATCH' && (
        <>
          <BatchConfigForm
            formState={formState}
            setFormState={setFormState}
            resetAudioAndTranscript={resetAudioAndTranscript}
            setTranscriptId={setTranscriptId}
            mediaFile={mediaFile}
            setTranscriptionConfiguration={setTranscriptionConfiguration}
            transcriptionConfiguration={
              transcriptionConfiguration as BatchMachineTranscriptionConfiguration
            }
          />
        </>
      )}
      {mode === 'REALTIME' && (
        <RealtimeConfigForm
          formState={formState}
          setFormState={setFormState}
          onAudioInputDeviceChange={onAudioInputDeviceChange}
          resetAudioAndTranscript={resetAudioAndTranscript}
          setRealtimeStatus={setRealtimeStatus}
          setTranscriptionConfiguration={setTranscriptionConfiguration}
          realtimeStatus={realtimeStatus}
          transcriptionConfiguration={transcriptionConfiguration}
          audioInputDeviceId={audioInputDeviceId}
        />
      )}
    </div>
  )
}
