import {useLocalStorage} from '@kensho/tacklebox'
import {useCallback, useMemo} from 'react'

/**
 * NOTE: that this must be a serializable value
 */
export default function useLocalStorageState<T>(
  key: string,
  defaultValue: T,
): [T, (nextValue: T) => void] {
  const [localStorageValue, setLocalStorageValue] = useLocalStorage(key)

  const value = useMemo<T>(() => {
    if (localStorageValue === null) return defaultValue
    return JSON.parse(localStorageValue) as T
  }, [defaultValue, localStorageValue])

  const setValue = useCallback(
    (nextValue: T): void => {
      setLocalStorageValue(JSON.stringify(nextValue))
    },
    [setLocalStorageValue],
  )

  return [value, setValue]
}
