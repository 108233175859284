export const PRODUCT = {
  ID: 'scribe',
  NAME: 'Scribe',
  LONG_NAME: 'Kensho Scribe',
  STRIPE_PRODUCT_ID: 'prod_J3mwlshIz6hU9S',
}

export const URLS = {
  HOME: 'https://kensho.com',
  CONTACT: 'https://kensho.com/contact',
  CONTACT_FORM: `https://kensho.com/contact?product=${PRODUCT.ID}`,
  FREE_TRIAL: `https://services.kensho.com/free-trial/${PRODUCT.ID}`,
  PRIVACY_POLICY: 'https://kensho.com/privacy',
  TERMS_OF_USE: 'https://kensho.com/terms-of-use',
  TERMS_OF_SERVICE: 'https://kensho.com/terms-of-service',
  USAGE_BILLING: 'https://services.kensho.com/account/services',
}

export const EMAILS = {
  SUPPORT: 'support@kensho.com',
  FEEDBACK: 'scribe@kensho.com',
}

export const HEADER_HEIGHT = 80
export const MARKETPLACE_IDP = '0oa233h7mov7U7gD14x7'

export const API_ENDPOINT = '/api/v2'

export const LOCAL_URL = 'scribe.local'
export const DEV_URL = 'scribe-dev.beta-p.kensho.com'
export const STAGE_URL = 'scribe-stage.beta-p.kensho.com'
export const PROD_URL = 'scribe.kensho.com'
export const ENDPOINTS = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  ME: '/me',
  ACCESS_TOKEN_REFRESH: '/oauth2/refresh',
}

export const CONSENT_COOKIE_NAME = 'consentCookie'
export const CONSENT_COOKIE_DEFAULTS = {
  functional: true,
  targeting: true,
  performance: true,
  isSet: false,
}
