import {APIErrorResponse, ScribeError, ScribeErrorType, ServerErrorMessage} from '../types/types'

/** map of message codes to error types (e.g. S2T-BA-0001 maps to 'invalidFile') */
const messageCodesToErrorType = {
  S2T: {
    BA: {
      '0001': 'invalidFile',
      '0002': 'invalidFile',
      '0003': 'usageLimit',
      '0004': 'rateLimit',
    },
  },
}

/**
 * A message code is defined on the BE for specific error lookups.
 * For example S2T-BA-0002 which corresponds to:
 * https://github.kensho.com/kensho/zentreefish/blob/master/projects/speech2text/services/scribe/scribe/routes/transcription_v2.py#L950
 */
function messageCodeToErrorType(messageCode?: string): ScribeErrorType {
  if (!messageCode) return 'unknown'
  const messageCodeMatch = messageCode.match(/(.+)-(.+)-(.+)/)
  if (!messageCodeMatch) return 'unknown'
  const [, product, module, code] = messageCodeMatch
  /* @ts-ignore-next-line */
  return messageCodesToErrorType[product]?.[module]?.[code] || 'unknown'
}

function statusCodeToErrorType(statusCode: number): ScribeErrorType {
  switch (statusCode) {
    case 400:
      return 'badRequest'
    case 402:
      return 'usageLimit'
    case 401:
    case 403:
      return 'unauthenticated'
    case 404:
      return 'transcriptNotFound'
    case 408:
      return 'transcriptRequestTimedOut'
    case 413:
      return 'fileTooLarge'
    case 415:
      return 'audioInputUnsupported'
    case 429:
      return 'rateLimit'
    default:
      return 'unknown'
  }
}

export type ErrorInput = string | number | Error | Response | APIErrorResponse | ServerErrorMessage

function isServerErrorMessage(errorInput: ErrorInput): errorInput is ServerErrorMessage {
  return (
    typeof errorInput === 'object' &&
    'reason' in errorInput &&
    'message' in errorInput &&
    errorInput.message === 'Error'
  )
}

/** Map/coerce various types of inputs into a uniform ScribeError */
export default function parseError(errorInput: ErrorInput): ScribeError {
  // assume we're receiving a ScribeErrorType string and return as-is
  if (typeof errorInput === 'string') return {type: errorInput as ScribeErrorType}

  if (errorInput instanceof Error)
    return {type: 'generic', detail: errorInput.message, title: errorInput.name}

  if (typeof errorInput === 'number')
    return {
      type: statusCodeToErrorType(errorInput),
      status: errorInput,
      detail: `Response status: ${errorInput}`,
    }

  // error from realtime websocket
  if (isServerErrorMessage(errorInput)) {
    // try to map realtime error type to prettier ScribeErrorType
    let realtimeErrorType: ScribeErrorType
    switch (errorInput.type) {
      case 'Authentication':
        realtimeErrorType = 'unauthenticated'
        break
      case 'OverLimits':
        realtimeErrorType = 'usageLimit'
        break
      case 'RateLimitExceeded':
        realtimeErrorType = 'rateLimit'
        break
      default:
        // otherwise just use the realtime error type as-is
        realtimeErrorType = 'realtimeError'
    }

    return {
      type: realtimeErrorType,
      detail: `${errorInput.type}: ${errorInput.reason}`,
    }
  }

  if (errorInput instanceof Response) {
    return {
      type: statusCodeToErrorType(errorInput.status),
      status: errorInput.status,
    }
  }

  let mostSpecificErrorType: ScribeErrorType = 'unknown'
  const statusCodeErrorType = statusCodeToErrorType((errorInput as APIErrorResponse).status)
  const msgCodeErrorType = messageCodeToErrorType((errorInput as APIErrorResponse).msg_code)
  if (msgCodeErrorType !== 'unknown') {
    mostSpecificErrorType = msgCodeErrorType
  } else if (statusCodeErrorType !== 'unknown') {
    mostSpecificErrorType = statusCodeErrorType
  }

  return {...errorInput, type: mostSpecificErrorType}
}
