import clsx from 'clsx'

import {TranscriptMetadata} from '../../../types/types'

type JobStatus = TranscriptMetadata['status']

type JobStatusWithoutSubmitted = Exclude<JobStatus, 'submitted'>

const JOB_STATUS_COLOR: Record<JobStatusWithoutSubmitted, string> = {
  failed: '#C61936',
  in_progress: '#007A99',
  complete: '#01822C',
}

const JOB_STATUS_MESSAGE: Record<JobStatusWithoutSubmitted, string> = {
  failed: 'Error',
  in_progress: 'Processing',
  complete: 'Complete',
}

interface JobStatusProps {
  status: JobStatus
}

export default function JobStatus({status}: JobStatusProps): React.ReactNode {
  const replaceSubmitted = status === 'submitted' ? 'in_progress' : status
  const color = JOB_STATUS_COLOR[replaceSubmitted]

  return (
    <div className="flex items-center" style={{color}}>
      <div
        className={clsx(
          'mr-1.5 size-2.5 flex-none rounded-full bg-current',
          replaceSubmitted === 'in_progress' && 'animate-pulse',
        )}
      />
      <span>{JOB_STATUS_MESSAGE[replaceSubmitted]}</span>
    </div>
  )
}
