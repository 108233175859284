import {useDependentReinitializingState_discouraged} from '@kensho/tacklebox'
import {useCallback, useMemo} from 'react'

import useAsyncInterval from '../hooks/useAsyncInterval'
import {TranscriptMetadata, ScribeError} from '../types/types'
import parseError from '../utils/parseError'

import useAPIFetch from './useAPIFetch'
import useGetTranscriptMetadata from './useGetTranscriptMetadata'

/**
 * Get the transcription metadata
 * If the transcription job is still 'in_progress' will poll until completion
 */
export default function usePollTranscriptMetadata({
  transcriptId,
  disabled,
  onSuccess,
  onError,
}: {
  transcriptId?: string
  disabled?: boolean
  onSuccess: (metadata: TranscriptMetadata) => void
  onError: (error: ScribeError) => void
}): [cancel: () => void] {
  const apiFetch = useAPIFetch()
  const getTranscriptMetadata = useGetTranscriptMetadata()
  const internalDisabled = disabled || !transcriptId
  const pollTranscriptMetadata = useCallback(
    () => getTranscriptMetadata(internalDisabled ? '' : transcriptId),
    [getTranscriptMetadata, transcriptId, internalDisabled],
  )

  // Reset polling state if switching transcriptIds
  const [isPolling, setIsPolling] = useDependentReinitializingState_discouraged(!!transcriptId, [
    transcriptId,
  ])

  const onResolve = useCallback(
    (metadata: TranscriptMetadata) => {
      // Stop polling if the job is no longer pending
      if (metadata.status === 'complete' || metadata.status === 'failed') setIsPolling(false)
      onSuccess(metadata)
    },
    [setIsPolling, onSuccess],
  )

  const onReject = useCallback(
    (error: Error | DOMException) => {
      if ((error as DOMException).name === 'AbortError') return // ignore aborted request
      setIsPolling(false)
      onError(parseError(error))
    },
    [setIsPolling, onError],
  )

  useAsyncInterval(
    pollTranscriptMetadata,
    !internalDisabled && isPolling ? 5 * 1000 : null,
    onResolve,
    onReject,
  )

  return useMemo(() => [apiFetch.cancel], [apiFetch])
}
