import {ReactNode} from 'react'
import {InputGroup} from '@kensho/neo'

import validateTranscriptName from '../../../utils/validateTranscriptName'

import {FormState} from './FormState'

interface TranscriptNameProps {
  transcriptName: string
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
  error?: string
}

export default function TranscriptName({
  transcriptName,
  setFormState,
  error,
}: TranscriptNameProps): ReactNode {
  return (
    <InputGroup
      data-clarity-mask="True"
      label="File name"
      isInvalid={!!error}
      errorText={error}
      value={transcriptName}
      onChange={(e) => {
        setFormState((prev) => ({
          ...prev,
          transcriptName: e.target.value,
          errors: {
            ...prev.errors,
            transcriptName: validateTranscriptName(e.target.value),
          },
        }))
      }}
    />
  )
}
