import {RectSegment, Rect, RectAnnotationStyle} from '../types'

import drawRect from './rect'

export default function drawSegmentInBlock(
  ctx: CanvasRenderingContext2D,
  segment: RectSegment,
  style: RectAnnotationStyle,
  block: Rect,
): void {
  const {x, y, width, height} = segment
  const segmentInBlock = {
    x: x - block.x,
    y: y - block.y,
    width,
    height,
  }
  drawRect(ctx, segmentInBlock, style)
}
