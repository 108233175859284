import {useReducer, useEffect, useMemo, useCallback, useContext} from 'react'
import {useLogger} from '@kensho/lumberjack'

import me from '../api/me'
import logout from '../api/logout'
import assertNever from '../utils/assertNever'
import refreshAccessToken from '../api/refreshAccessToken'
import {User} from '../types/types'

import UserContext, {UserContextInterface} from './UserContext'
import SiteAnalyticsContext from './SiteAnalyticsContext'

type UserAction = {type: 'logout'} | {type: 'updateUser'; user: User}

interface UserState {
  user?: User
  _state: UserContextInterface['_state']
}

function userReducer(_state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case 'updateUser':
      return {_state: 'known', user: action.user}
    case 'logout':
      return {_state: 'known'}
    default:
      return assertNever(action, 'Unknown action type')
  }
}

function getUser(dispatch: (action: UserAction) => void): void {
  me()
    .then((user) => {
      dispatch({type: 'updateUser', user})

      // refresh the access token immediately since user may be returning to the page with
      // session cookie and access token could be expired already
      return refreshAccessToken(user.refreshToken).then(({accessToken}) => {
        dispatch({type: 'updateUser', user: {...user, token: accessToken}})
      })
    })
    .catch(() => dispatch({type: 'logout'}))
}

export default function UserProvider(props: {children: React.ReactNode}): React.ReactNode {
  const {children} = props
  const [{user, _state}, dispatch] = useReducer(userReducer, {_state: 'unknown'})
  const log = useLogger()

  const analytics = useContext(SiteAnalyticsContext)

  const triggerUpstreamLogout = useCallback((): void => {
    logout().catch((error) => {
      log.error(error)
      // user should already be unset, but reset the state on an error just in case
      dispatch({type: 'logout'})
    })
  }, [log, dispatch])

  // attempt to retrieve the user on mount
  useEffect(() => {
    getUser(dispatch)
  }, [])

  useEffect(() => {
    if (user) {
      let userType = 'external'
      if (user.email.endsWith('spglobal.com')) userType = 'sp_global'
      if (user.email.endsWith('kensho.com')) userType = 'kensho'

      analytics.setSessionContext('user_properties', 'user_type', userType)
    }
  }, [analytics, user])

  // refresh access token every 15 minutes if user hasn't logged out to avoid access token expiry
  useEffect(() => {
    let isCurrent = true
    const timeoutId = window.setTimeout(
      () => {
        if (!user?.refreshToken) return
        refreshAccessToken(user.refreshToken).then(
          ({accessToken}) =>
            isCurrent && dispatch({type: 'updateUser', user: {...user, token: accessToken}}),
        )
      },
      15 * 60 * 1000, // 15 minutes
    )

    return () => {
      window.clearTimeout(timeoutId)
      isCurrent = false
    }
  }, [user])

  const userContextValue = useMemo(
    () => ({
      user,
      _state,
      refreshToken() {
        if (!user?.refreshToken) return
        refreshAccessToken(user.refreshToken).then(({accessToken}) =>
          dispatch({type: 'updateUser', user: {...user, token: accessToken}}),
        )
      },
      logout() {
        dispatch({type: 'logout'})
        triggerUpstreamLogout()
      },
    }),
    [_state, user, triggerUpstreamLogout],
  )

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
}
