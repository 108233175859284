interface Props {
  children?: React.ReactNode
}

/**
 * Wraps children in a div that stops propagation of keydown, beforeInput, input, cut, and paste events.
 *
 * Intended to wrap children within the transcript contenteditable tree that
 * need to isolate their own input handlers from propagating upwards and affecting the transcript.
 */
export default function StopInputPropagation({children}: Props): React.ReactNode {
  return (
    <div
      onBeforeInput={(event) => event.stopPropagation()}
      onInput={(event) => event.stopPropagation()}
      onKeyDown={(event) => event.stopPropagation()}
      onCut={(event) => event.stopPropagation()}
      onPaste={(event) => event.stopPropagation()}
    >
      {children}
    </div>
  )
}
