import {createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef} from 'react'
import {useLogger} from '@kensho/lumberjack'
import {useParams} from 'react-router-dom'
import {produce} from 'immer'
import {ZodError} from 'zod'

import useTypedWebSocket from '../hooks/useTypedWebSocket'
import {
  MultiplayerServerMessage,
  MultiplayerClientMessage,
  MultiplayerClientMessageSchema,
  MultiplayerServerMessageSchema,
} from '../types/schemas'
import {TranscriptSelectionNode} from '../types/types'
import {TranscriptPermissionsContext} from '../core/transcription/TranscriptPermissionsProvider'

import UserContext from './UserContext'
import TranscriptContext from './TranscriptContext'

interface MultiplayerProviderProps {
  children: React.ReactNode
}

export interface ConnectedUser {
  clientId: string
  sessionId: string
  clientName: string
  cursorPosition?: CursorDetails
}

export interface CursorDetails {
  start: TranscriptSelectionNode | null
  end: TranscriptSelectionNode | null
}

interface ConnectionError {
  reason?: string
  code?: number
}

interface ServerError {
  type: 'error'
  payload: {
    code: string
    title: string
    detail: string
  }
}

interface MessageValidationError {
  type: 'message-validation-error'
  error: ZodError
}

type MultiplayerError = ServerError | MessageValidationError

export interface MultiplayerContextType {
  authenticated: boolean
  connectedUsers: ConnectedUser[]
  connectionError?: ConnectionError
  error?: MultiplayerError
  registerReceiveMessageCallback: (
    callback: (message: MultiplayerServerMessage) => void,
  ) => () => void
  sessionId: string | null
  sendMessage: (message: MultiplayerClientMessage) => void
}

export const MultiplayerContext = createContext<MultiplayerContextType | null>(null)

interface MultiplayerState {
  authenticated: boolean
  connectedUsers: ConnectedUser[]
  connectionError?: ConnectionError
  error?: MultiplayerError
  sessionId: string | null
}

type MultiplayerAction =
  | {
      type: 'userAuthenticated'
      authenticated: boolean
      sessionId: string
    }
  | {type: 'userConnected'; user: ConnectedUser}
  | {type: 'userDisconnected'; sessionId: string}
  | {type: 'addReceiveMessageCallback'; callback: (message: MultiplayerServerMessage) => void}
  | {type: 'removeReceiveMessageCallback'; callback: (message: MultiplayerServerMessage) => void}
  | {type: 'connectionError'; connectionError?: ConnectionError}
  | {type: 'cursorUpdate'; pos: CursorDetails; sessionId: string}
  | {type: 'error'; error?: MultiplayerError}
  | {type: 'reset'}

function reducer(state: MultiplayerState, action: MultiplayerAction): MultiplayerState {
  switch (action.type) {
    case 'userAuthenticated':
      return {
        ...state,
        authenticated: action.authenticated,
        sessionId: action.sessionId,
      }
    case 'userConnected':
      return {...state, connectedUsers: [...state.connectedUsers, action.user]}
    case 'userDisconnected':
      return {
        ...state,
        connectedUsers: state.connectedUsers.filter(
          (prevUser) => prevUser.sessionId !== action.sessionId,
        ),
      }
    case 'cursorUpdate': {
      /* eslint-disable no-param-reassign */
      return produce(state, (draftState) => {
        const index = draftState.connectedUsers.findIndex(
          (user) => user.sessionId === action.sessionId,
        )
        if (index !== -1) draftState.connectedUsers[index].cursorPosition = action.pos
      })
      /* eslint-enable no-param-reassign */
    }
    case 'connectionError':
      return {
        authenticated: false,
        connectedUsers: [],
        connectionError: action.connectionError,
        sessionId: null,
      }
    case 'error':
      return {...state, error: action.error}
    case 'reset':
      return {
        authenticated: false,
        connectedUsers: [],
        sessionId: null,
      }
    default:
      return state
  }
}

export function MultiplayerProvider({children}: MultiplayerProviderProps): React.ReactNode {
  const log = useLogger()
  const {user} = useContext(UserContext)
  const {stage, mode, metadata} = useContext(TranscriptContext)
  const {setTranscriptPermissions} = useContext(TranscriptPermissionsContext)
  const {transcriptId} = useParams()
  const receiveMessageCallbacksRef = useRef<((message: MultiplayerServerMessage) => void)[]>([])

  const [state, dispatch] = useReducer(reducer, {
    authenticated: false,
    connectedUsers: [],
    sessionId: null,
  })

  const shouldConnect =
    stage === 'POST_TRANSCRIPTION' &&
    mode !== 'REALTIME' &&
    !!transcriptId &&
    metadata?.status === 'complete'

  const handleValidationError = useCallback(
    (error: ZodError, message: unknown, isClientMessage: boolean): void => {
      dispatch({
        type: 'error',
        error: {
          type: 'message-validation-error',
          error,
        },
      })
      log.error(error, {message: `${JSON.stringify(message)}`, isClientMessage})
    },
    [log],
  )

  const handleOpen = useCallback(
    (sendMessage: (message: MultiplayerClientMessage) => void): void => {
      // send authentication message
      if (!user || !user.token) return
      sendMessage({type: 'authenticate', payload: {token: user.token}})
      dispatch({type: 'connectionError'})
    },
    [user],
  )

  // server closes connection
  const handleClose = useCallback(
    (e: CloseEvent): void => {
      // code 1000 is a clean connection close, all else are connection errors
      if (e.code !== 1000) {
        dispatch({type: 'connectionError', connectionError: {reason: e.reason, code: e.code}})
        log.error('Connection error', {code: e.code, reason: e.reason})
      }
    },
    [log],
  )

  const handleTopLevelMessage = useCallback(
    (message: MultiplayerServerMessage): void => {
      switch (message.type) {
        case 'authenticated':
          dispatch({
            type: 'userAuthenticated',
            authenticated: true,
            sessionId: message.payload.sessionId,
          })

          break
        case 'presence-add':
          dispatch({type: 'userConnected', user: message.payload})
          break
        case 'presence-remove':
          dispatch({type: 'userDisconnected', sessionId: message.payload.sessionId})
          break
        case 'reconnect':
          // initiate reconnect behavior
          break
        case 'cursor-update':
          dispatch({
            type: 'cursorUpdate',
            pos: {start: message.payload.start, end: message.payload.end},
            sessionId: message.payload.sessionId,
          })
          break
        case 'error':
          dispatch({type: 'error', error: message})
          log.error(`Error`, {message: message.payload.toString()})
          break
        default:
        // noop
      }
    },
    [log],
  )

  const receiveMessage = (message: MultiplayerServerMessage): void => {
    handleTopLevelMessage(message)
    receiveMessageCallbacksRef.current.forEach((callback) => callback(message))
  }

  const registerReceiveMessageCallback = useCallback(
    (callback: (message: MultiplayerServerMessage) => void): (() => void) => {
      receiveMessageCallbacksRef.current.push(callback)
      return () => {
        receiveMessageCallbacksRef.current = receiveMessageCallbacksRef.current.filter(
          (cb) => cb !== callback,
        )
      }
    },
    [],
  )

  const {sendMessage} = useTypedWebSocket({
    url: `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${window.location.host}/app/editor/v1/${transcriptId}`,
    clientMessageSchema: MultiplayerClientMessageSchema,
    serverMessageSchema: MultiplayerServerMessageSchema,
    onValidationError: handleValidationError,
    onMessage: receiveMessage,
    onClose: handleClose,
    onOpen: handleOpen,
    shouldConnect,
  })

  useEffect(() => {
    if (!shouldConnect) {
      dispatch({type: 'reset'})
    }
  }, [shouldConnect])

  // switch to read-only mode if hitting an error
  useEffect(() => {
    if (state.error || state.connectionError) setTranscriptPermissions({edit: false})
  }, [state.error, state.connectionError, setTranscriptPermissions])

  const value = useMemo<MultiplayerContextType>(
    () => ({
      ...state,
      sendMessage,
      registerReceiveMessageCallback,
    }),
    [state, sendMessage, registerReceiveMessageCallback],
  )

  return <MultiplayerContext.Provider value={value}>{children}</MultiplayerContext.Provider>
}
