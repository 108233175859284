import isMac from '../utils/isMac'

export type Shortcut = {
  /** The key(s) used to trigger the shortcut. Values should be a valid KeyboardEvent.code */
  keys: string[]
  /**
   * If you want to display different key(s) than what is actually used
   * '/' can be included to add a divider between keys
   */
  keysLabel?: string[]
  /** The description of the shortcut */
  description: string
  /** Additional information about the shortcut */
  info?: string
  /** Whether or not to display this shortcut in the shortcut panel, default = true */
  displayInShortcutPanel?: boolean
}

const SHORTCUTS = {
  Audio: {
    skipBackwards: {
      keys: ['Alt', 'KeyJ'],
      description: 'Skip backwards 3 seconds',
    },
    playPause: {
      keys: ['Alt', 'KeyK'],
      description: 'Play/Pause',
    },
    skipForwards: {
      keys: ['Alt', 'KeyL'],
      description: 'Skip forwards 3 seconds',
    },
    decreaseSpeed: {
      keys: ['Alt', 'Minus'],
      description: 'Decrease playback speed',
    },
    increaseSpeed: {
      keys: ['Alt', 'Equal'],
      description: 'Increase playback speed',
    },
  },
  Cursor: {
    moveCursorWord: {
      keys: isMac() ? ['Alt', 'ArrowRight'] : ['Ctrl', 'ArrowRight'],
      keysLabel: isMac()
        ? ['Alt', 'ArrowLeft', '/', 'ArrowRight']
        : ['Ctrl', 'ArrowLeft', '/', 'ArrowRight'],
      description: 'Move cursor by word',
    },
    highlightWord: {
      keys: isMac() ? ['Shift', 'Alt', 'ArrowRight'] : ['Shift', 'Ctrl', 'ArrowRight'],
      keysLabel: isMac()
        ? ['Shift', 'Alt', 'ArrowLeft', '/', 'ArrowRight']
        : ['Shift', 'Ctrl', 'ArrowLeft', '/', 'ArrowRight'],
      description: 'Highlight word',
    },
  },
  Edit: {
    copy: {
      keys: ['Mod', 'KeyC'],
      description: 'Copy',
    },
    cut: {
      keys: ['Mod', 'KeyX'],
      description: 'Cut',
    },
    paste: {
      keys: ['Mod', 'KeyV'],
      description: 'Paste',
    },
    selectAll: {
      keys: ['Mod', 'KeyA'],
      description: 'Select all',
      info: 'Select all transcript text. Timestamp and speaker names are not included.',
    },
    undo: {
      keys: ['Mod', 'KeyZ'],
      description: 'Undo',
    },
    redo: {
      keys: ['Mod', 'KeyY'],
      description: 'Redo',
    },
    toggleCapitalization: {
      keys: ['Mod', 'KeyU'],
      description: 'Capitalize/uncap initial',
    },
    search: {
      keys: ['Mod', 'KeyF'],
      description: 'Search',
    },
  },
  Timeline: {
    jumpToCurrentTime: {
      keys: ['KeyK'],
      description: 'Jump to current time',
    },
    jumpToUnalignedToken: {
      keys: ['KeyN'],
      description: 'Jump to next unaligned token',
    },
    moveToken: {
      keys: ['Alt', 'ArrowLeft', 'ArrowRight'],
      keysLabel: isMac()
        ? ['Alt', 'ArrowLeft', '/', 'ArrowRight']
        : ['Ctrl', 'ArrowLeft', '/', 'ArrowRight'],
      description: 'Move token selection left / right',
    },
    moveTokenSelectionLeft: {
      keys: isMac() ? ['Alt', 'ArrowLeft'] : ['Ctrl', 'ArrowLeft'],
      description: 'Move token selection left',
      displayInShortcutPanel: false,
    },
    moveTokenSelectionRight: {
      keys: isMac() ? ['Alt', 'ArrowRight'] : ['Ctrl', 'ArrowRight'],
      description: 'Move token selection right',
      displayInShortcutPanel: false,
    },
    nudgeToken: {
      keys: ['ArrowLeft', 'ArrowRight'],
      keysLabel: ['ArrowLeft', '/', 'ArrowRight'],
      description: 'Nudge token left / right',
    },
    nudgeLeft: {
      keys: ['ArrowLeft'],
      description: 'Nudge token left',
      displayInShortcutPanel: false,
    },
    nudgeRight: {
      keys: ['ArrowRight'],
      description: 'Nudge token right',
      displayInShortcutPanel: false,
    },
    bigNudgeToken: {
      keys: ['Shift', 'ArrowLeft', 'ArrowRight'],
      keysLabel: ['Shift', 'ArrowLeft', '/', 'ArrowRight'],
      description: 'Big nudge token left / right',
    },
    bigNudgeLeft: {
      keys: ['Shift', 'ArrowLeft'],
      description: 'Big nudge token left',
      displayInShortcutPanel: false,
    },
    bigNudgeRight: {
      keys: ['Shift', 'ArrowRight'],
      description: 'Big nudge token right',
      displayInShortcutPanel: false,
    },
    modifyStartTime: {
      keys: ['BracketLeft', 'BracketRight'],
      keysLabel: ['BracketLeft', '/', 'BracketRight'],
      description: 'Increase / decrease token start time',
    },
    increaseStartTime: {
      keys: ['BracketRight'],
      description: 'Increase token start time',
      displayInShortcutPanel: false,
    },
    decreaseStartTime: {
      keys: ['BracketLeft'],
      description: 'Decrease token start time',
      displayInShortcutPanel: false,
    },
    modifyDuration: {
      keys: ['Alt', 'BracketLeft', 'BracketRight'],
      keysLabel: ['Alt', 'BracketLeft', '/', 'BracketRight'],
      description: 'Increase / decrease token duration',
    },
    increaseDuration: {
      keys: ['Alt', 'BracketRight'],
      description: 'Increase token duration',
      displayInShortcutPanel: false,
    },
    decreaseDuration: {
      keys: ['Alt', 'BracketLeft'],
      description: 'Decrease token duration',
      displayInShortcutPanel: false,
    },
    dragSetTime: {
      keys: ['Alt', 'Left-click'],
      description: 'Hold and drag to set token time',
    },
    multiSelect: {
      keys: ['Mod', 'Left-click'],
      description: 'Hold and click to multi-select',
    },
    rangeSelect: {
      keys: ['Shift', 'Left-click'],
      description: 'Hold and click to range-select',
    },
    togglePlaybackLoop: {
      keys: ['KeyL'],
      description: 'Toggle playback loop',
    },
    toggleSyncToTime: {
      keys: ['KeyS'],
      description: 'Toggle current time sync',
    },
  },
  View: {
    toggleShortcutDrawer: {
      keys: ['Slash'],
      keysLabel: ['?'],
      description: 'Show/hide shortcut panel',
    },
    toggleTimeline: {
      keys: ['KeyT'],
      description: 'Show/hide timeline panel',
    },
  },
}

// type check to guarantee SHORTCUTS conforms to the type without breaking
// autocomplete by casting specific keys to generic strings
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const check: Record<string, Record<string, Shortcut>> = SHORTCUTS

export default SHORTCUTS
