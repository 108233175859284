import {Button, DropdownMenu} from '@kensho/neo'
import {Dispatch, SetStateAction, useContext, useState} from 'react'
import {FileRejection} from 'react-dropzone'

import SiteAnalyticsContext from '../../providers/SiteAnalyticsContext'
import {Stage} from '../../types/types'

import FileUpload from './FileUpload'
import UnloadWarningDialog from './UnloadWarningDialog'

const REALTIME_TRANSCRIPTION_URL = '/transcription?mode=realtime'

interface NewTranscriptMenuProps {
  handleFileDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void
  resetAudioAndTranscript: () => void
  showUnsavedTranscriptWarning: boolean
  showUploadInProgressWarning: () => void
  setTranscriptDownloaded: Dispatch<SetStateAction<boolean>>
  stage: Stage
}

export default function NewTranscriptMenu({
  handleFileDrop,
  showUnsavedTranscriptWarning,
  showUploadInProgressWarning,
  setTranscriptDownloaded,
  stage,
}: NewTranscriptMenuProps): React.ReactNode {
  const [dialogType, setDialogType] = useState<'upload' | 'record'>()
  const [isOpen, setIsOpen] = useState(false)

  const analytics = useContext(SiteAnalyticsContext)

  return (
    <FileUpload disabled={stage === 'START_TRANSCRIPTION'} handleFileDrop={handleFileDrop}>
      {({open}) => (
        <>
          <UnloadWarningDialog
            isOpen={isOpen}
            content={
              <div className="flex flex-col gap-4">
                <p>Are you sure you want to leave this page and discard this transcript?</p>
                <p>
                  Scribe does not save real time transcriptions, so be sure to download your
                  transcript text before closing your session, or it will be lost permanently.
                </p>
              </div>
            }
            title="Leave page and discard this transcript?"
            primaryButtonProps={{
              intent: 'danger',
              children: 'Leave & discard transcript',
              onClick: () => {
                if (dialogType === 'upload') {
                  open()
                } else if (dialogType === 'record') {
                  window.location.href = REALTIME_TRANSCRIPTION_URL
                }
                setIsOpen(false)
              },
            }}
            secondaryButtonProps={{
              children: 'Cancel & stay on page',
              onClick: () => setIsOpen(false),
            }}
            onClose={() => setIsOpen(false)}
          />

          <div className="flex w-full flex-col">
            <DropdownMenu
              items={[
                {
                  id: 'batch',
                  label: 'Upload',
                  onAction() {
                    if (stage === 'START_TRANSCRIPTION') {
                      showUploadInProgressWarning()
                    } else if (showUnsavedTranscriptWarning) {
                      setDialogType('upload')
                      setIsOpen(true)
                      setTranscriptDownloaded(true)
                    } else {
                      analytics.sendEvent('upload_batch_file')
                      open()
                    }
                  },
                },
                {
                  id: 'realtime',
                  label: 'Record',
                  onAction() {
                    if (stage === 'START_TRANSCRIPTION') {
                      showUploadInProgressWarning()
                    } else if (showUnsavedTranscriptWarning) {
                      setDialogType('record')
                      setIsOpen(true)
                      setTranscriptDownloaded(true)
                    } else {
                      analytics.sendEvent('record_realtime')
                      window.location.href = REALTIME_TRANSCRIPTION_URL
                    }
                  },
                },
              ]}
            >
              <Button icon="PlusCircleIcon" rightIcon="ChevronDownIcon">
                Create new
              </Button>
            </DropdownMenu>
          </div>
        </>
      )}
    </FileUpload>
  )
}
