import {clamp} from 'lodash-es'
import {useCallback, useRef, useState} from 'react'

import {REALTIME_TRANSCRIPTION_SAMPLE_RATE} from '../../constants'

import float32ToInt16 from './float32ToInt16'
import littleEndianInt16 from './littleEndianInt16'
import useSequence from './useSequence'

const MIN_CHUNK_SIZE = REALTIME_TRANSCRIPTION_SAMPLE_RATE * 1 // seconds of audio data

/** Accumulate audio data until we have a MIN_REALTIME_CHUNK_DURATION second(s) chunk to send for transcription */
export default function useRealtimeBuffer(
  websocket?: WebSocket,
  onFlush?: (sequenceValue: number) => void,
): {
  pushRealtimeBuffer: (audioData: number[]) => void
  flushRealtimeBuffer: (all?: boolean) => number
  resetRealtimeBuffer: () => void
} {
  const [lastSequenceValue, setLastSequenceValue] = useState(0)
  const [nextSequenceValue, resetSequence] = useSequence()
  const rawBufferRef = useRef<number[]>([])

  const flushRealtimeBuffer = useCallback(
    (forceFlush = false) => {
      if (!(websocket && MIN_CHUNK_SIZE)) return lastSequenceValue
      // skip if there isn't enough data yet
      // forceFlush is used when the transcription is being ended, since scriptProcessingNode
      // works in binary buffer sizes it may leave a remainder compared to MIN_CHUNK_SIZE
      if (rawBufferRef.current.length < MIN_CHUNK_SIZE && !forceFlush) return lastSequenceValue
      // transform into the format Scribe accepts
      if (websocket?.readyState !== WebSocket.OPEN)
        throw new Error('Websocket closed before transcription finished')

      const audioData = rawBufferRef.current.splice(0)

      const outputData: string = window.btoa(
        audioData.reduce((acc, d) => {
          /* eslint-disable-next-line no-param-reassign */
          acc += littleEndianInt16(float32ToInt16(clamp(d, -1, 1)))
          return acc
        }, ''),
      )
      const sequenceValue = nextSequenceValue()
      websocket.send(
        JSON.stringify({
          message: 'AddData',
          audio: outputData,
          sequence_number: sequenceValue,
        }),
      )
      setLastSequenceValue(sequenceValue)
      if (onFlush) onFlush(sequenceValue)
      return sequenceValue
    },
    [websocket, nextSequenceValue, lastSequenceValue, onFlush],
  )

  const pushRealtimeBuffer = useCallback(
    (audioData: number[]): void => {
      rawBufferRef.current.push(...audioData)
      flushRealtimeBuffer()
    },
    [flushRealtimeBuffer],
  )

  const resetRealtimeBuffer = useCallback(() => {
    resetSequence()
    setLastSequenceValue(0)
    rawBufferRef.current = []
  }, [resetSequence])

  return {pushRealtimeBuffer, flushRealtimeBuffer, resetRealtimeBuffer}
}
