import {Button, HeadlessButton, Tooltip} from '@kensho/neo'
import clsx from 'clsx'
import {throttle} from 'lodash-es'
import React, {useCallback, useContext, useMemo, useState} from 'react'

import IconSkipAhead from '../../assets/skipahead.svg'
import IconSkipBack from '../../assets/skipback.svg'
import TranscriptContext, {TranscriptAction} from '../../providers/TranscriptContext'
import {
  APITranscript,
  RealtimeStatus,
  RealtimeTranscriptionConfiguration,
  ScribeError,
  TranscriptionConfiguration,
} from '../../types/types'
import getDefaultTranscript from '../../utils/getDefaultTranscript'

import MediaContainer from './MediaContainer'
import Timeline from './Timeline'
import DownloadMediaFileButton from './actions/DownloadMediaFileButton'
import PlaybackRateButton from './actions/PlaybackRateButton'
import VolumeButton from './actions/VolumeButton'
import RealtimeTranscriber from './realtime/RealtimeTranscriber'

interface ActionBarProps {
  transcript?: APITranscript
  mediaFile?: File
  mediaPlayable: boolean
  currentTime: number
  seekMedia: (options: {timeSeconds: number; play?: boolean; scroll?: boolean}) => void
  setPaused: React.Dispatch<React.SetStateAction<boolean>>
  paused: boolean
  duration?: number
  setDuration: React.Dispatch<React.SetStateAction<number | undefined>>
  playbackRate: number
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>
  setCurrentTime: React.Dispatch<React.SetStateAction<number>>
  onMediaError: () => void
  setMediaEle: React.Dispatch<React.SetStateAction<HTMLMediaElement | null>>
  setMediaPlayable: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<React.SetStateAction<ScribeError | undefined>>
  setTranscriptId: (nextTranscriptId?: string) => void
  transcriptionConfiguration: TranscriptionConfiguration
  transcriptContextDispatch: React.Dispatch<TranscriptAction>
  audioInputDeviceId?: string
  setMediaFile: React.Dispatch<React.SetStateAction<File | undefined>>
  transcriptId?: string
  realtimeStatus: RealtimeStatus
  setRealtimeStatus: React.Dispatch<React.SetStateAction<RealtimeStatus>>
  openFileChooser: () => void
}

export default function ActionBar(props: ActionBarProps): React.ReactNode {
  const {
    transcript,
    mediaFile,
    mediaPlayable,
    currentTime,
    seekMedia,
    paused,
    setPaused,
    duration,
    playbackRate,
    setPlaybackRate,
    onMediaError,
    setCurrentTime,
    setDuration,
    setMediaEle,
    setError,
    setTranscriptId,
    transcriptionConfiguration,
    transcriptContextDispatch,
    audioInputDeviceId,
    setMediaFile,
    transcriptId,
    realtimeStatus,
    setRealtimeStatus,
    openFileChooser,
  } = props

  const [volume, setVolume] = useState<number>(0.5)
  const [savedVol, setSavedVol] = useState<number>(0.5)

  const {mode, dispatch, stage} = useContext(TranscriptContext)

  const onTimeUpdate = useMemo(
    () =>
      throttle(
        (event: React.SyntheticEvent<HTMLMediaElement, Event>) =>
          setCurrentTime((event.target as HTMLMediaElement).currentTime),
        100,
      ),
    [setCurrentTime],
  )

  const resetRealtimeAudio = useCallback(() => {
    setMediaFile(undefined)
  }, [setMediaFile])

  const onProcessAudio = useCallback(
    (_audioData: number[], totalDuration: number) => {
      setDuration(totalDuration)
      setCurrentTime(totalDuration)
    },
    [setCurrentTime, setDuration],
  )

  const onAudioUnsupported = useCallback(
    () => setError({type: 'audioInputUnsupported'}),
    [setError],
  )
  const onRealtimeError = useCallback(
    (e: ScribeError = {type: 'realtimeError'}) => setError(e),
    [setError],
  )

  return (
    <Tooltip
      content="The audio file expired. Click to re-upload."
      disabled={(!!mediaPlayable && !!mediaFile) || mode === 'REALTIME'}
    >
      <HeadlessButton
        onClick={!mediaPlayable || !mediaFile ? openFileChooser : undefined}
        type="button"
        aria-label="Upload audio file"
        className={clsx(
          'w-full',
          !mediaPlayable || !mediaFile ? 'bg-[#0000000A]' : 'hover:cursor-auto',
        )}
      >
        <div className="mx-auto flex h-20 max-w-[1440px] items-center justify-between gap-x-1">
          <div className="ml-10 flex items-center justify-between gap-x-1">
            {mediaFile && (
              <MediaContainer
                setMediaEle={setMediaEle}
                mediaPlayable={mediaPlayable}
                mediaFile={mediaFile}
                muted={volume === 0}
                paused={paused}
                setPaused={setPaused}
                playbackRate={playbackRate}
                onMediaError={onMediaError}
                onLoadedMetadata={(event) => {
                  const mediaEleDuration = event.currentTarget.duration
                  // wait for true duration to be reported before updating react state
                  if (
                    mediaEleDuration !== Infinity &&
                    mediaEleDuration !== Number.MAX_SAFE_INTEGER
                  ) {
                    setDuration(event.currentTarget.duration || 0)
                  }

                  // if we're in POST_TRANSCRIPTION noop, user switched the media
                  if (stage === 'POST_TRANSCRIPTION') return

                  if (mode === 'REALTIME') setCurrentTime(0)
                  if (mode === 'BATCH') dispatch({type: 'setStage', stage: 'PRE_TRANSCRIPTION'})
                }}
                onDurationChange={(event) => {
                  const mediaEleDuration = event.currentTarget.duration
                  // wait for true duration to be reported before updating react state
                  if (mediaEleDuration === Infinity || mediaEleDuration === Number.MAX_SAFE_INTEGER)
                    return
                  setDuration(mediaEleDuration)
                }}
                onPlay={(event) => {
                  if (!event.currentTarget.seeking) setPaused(false)
                }}
                onPause={(event) => {
                  if (!event.currentTarget.seeking) setPaused(true)
                }}
                onTimeUpdate={onTimeUpdate}
                volume={volume}
              />
            )}
            {(mode === 'BATCH' || ['POST_TRANSCRIPTION'].includes(stage)) && (
              <>
                <Button
                  disabled={!transcript || !mediaFile}
                  onClick={() => seekMedia({timeSeconds: currentTime - 3})}
                  minimal
                  intent="primary"
                >
                  <img
                    src={IconSkipBack}
                    alt="Skip Back 3 Seconds"
                    className={clsx(
                      'text-brand-700',
                      (!mediaPlayable || !mediaFile) && 'opacity-50',
                    )}
                  />
                </Button>

                <Button
                  disabled={!mediaPlayable || !mediaFile}
                  onClick={() => setPaused((prev) => !prev)}
                  icon={paused ? 'PlayIcon' : 'PauseIcon'}
                  intent="primary"
                  rounded
                  size="small"
                  aria-label={paused ? 'Play' : 'Pause'}
                />

                <Button
                  disabled={!transcript || !mediaFile}
                  onClick={() => seekMedia({timeSeconds: currentTime + 3})}
                  minimal
                  intent="primary"
                >
                  <img
                    src={IconSkipAhead}
                    alt="Skip Ahead 3 Seconds"
                    className={clsx(
                      'text-brand-700',
                      (!mediaPlayable || !mediaFile) && 'opacity-50',
                    )}
                  />
                </Button>
              </>
            )}
            {mode === 'REALTIME' && (
              <RealtimeTranscriber
                stage={stage}
                audioInputDeviceId={audioInputDeviceId}
                transcriptionConfiguration={
                  transcriptionConfiguration as RealtimeTranscriptionConfiguration
                }
                resetRealtimeData={resetRealtimeAudio}
                onTranscribingStart={(nextTranscriptId) => {
                  setTranscriptId(nextTranscriptId)
                  transcriptContextDispatch({
                    type: 'setTranscript',
                    transcript: getDefaultTranscript(),
                  })
                  dispatch({type: 'setStage', stage: 'TRANSCRIPTION'})
                }}
                onTranscribingComplete={() => {
                  dispatch({type: 'setStage', stage: 'POST_TRANSCRIPTION'})
                }}
                onAudioFileAvailable={(file) => setMediaFile(file)}
                onProcessAudio={onProcessAudio}
                onAudioUnsupported={onAudioUnsupported}
                onError={onRealtimeError}
                realtimeStatus={realtimeStatus}
                setRealtimeStatus={setRealtimeStatus}
              />
            )}
            {mode === 'REALTIME' && mediaFile && (
              <DownloadMediaFileButton mediaFile={mediaFile} name={transcriptId} />
            )}
          </div>
          <div className="grow">
            <Timeline
              hasMedia={Boolean(mediaFile)}
              mode={mode}
              stage={stage}
              transcript={transcript}
              duration={duration}
              currentTime={currentTime}
              onSeek={(time) => seekMedia({timeSeconds: time, play: false})}
            />
          </div>
          <div className="mr-10 flex items-center gap-2">
            <PlaybackRateButton
              disabled={!mediaPlayable || !mediaFile}
              playbackRate={playbackRate}
              onChange={(nextPlaybackRate) => setPlaybackRate(nextPlaybackRate)}
            />
            <VolumeButton
              disabled={!mediaPlayable || !mediaFile}
              volume={volume}
              onClick={() => {
                if (volume === 0) {
                  if (savedVol === 0) {
                    setVolume(0.5)
                  } else {
                    setVolume(savedVol)
                    setSavedVol(0)
                  }
                } else {
                  setSavedVol(volume)
                  setVolume(0)
                }
              }}
              onChange={(nextVolume) => {
                setVolume(nextVolume)
              }}
            />
          </div>
        </div>
      </HeadlessButton>
    </Tooltip>
  )
}
