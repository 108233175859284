/**
 * Convert a signed 16-bit number from big endian to little endian, and encodes it into a string.
 *
 * @returns String representing the raw bytes of the number in little-endian
 */
export default function littleEndianInt16(num: number): string {
  /* eslint-disable no-bitwise */
  const byte0 = (num & 0xff00) >> 8
  const byte1 = num & 0x00ff
  return String.fromCharCode(byte1) + String.fromCharCode(byte0)
  /* eslint-enable no-bitwise */
}
