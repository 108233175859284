/**
 * Download the given data as a file with the given filename.
 */
export default function downloadFile(filename: string, data: string, isPlainText = true): void {
  const element = document.createElement('a')

  if (filename.endsWith('.json')) {
    element.href = URL.createObjectURL(new Blob([data], {type: 'application/json'}))
  } else if (isPlainText) {
    element.href = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`
  } else {
    element.href = data
  }
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
