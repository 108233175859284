import {createContext, useMemo, useState} from 'react'

export interface TranscriptPermissions {
  edit: boolean
}

interface TranscriptPermissionsProviderProps {
  children: React.ReactNode
}

export const TranscriptPermissionsContext = createContext<{
  transcriptPermissions: TranscriptPermissions
  setTranscriptPermissions: React.Dispatch<React.SetStateAction<TranscriptPermissions>>
}>({transcriptPermissions: {edit: false}, setTranscriptPermissions: () => {}})

export function TranscriptPermissionsProvider({
  children,
}: TranscriptPermissionsProviderProps): React.ReactNode {
  const [transcriptPermissions, setTranscriptPermissions] = useState<TranscriptPermissions>({
    edit: true,
  })
  /* @ts-ignore-next-line for testing purposes */
  window.debug_setTranscriptPermissions = setTranscriptPermissions

  const value = useMemo(
    () => ({
      transcriptPermissions,
      setTranscriptPermissions,
    }),
    [transcriptPermissions, setTranscriptPermissions],
  )

  return (
    <TranscriptPermissionsContext.Provider value={value}>
      {children}
    </TranscriptPermissionsContext.Provider>
  )
}
