import {useContext, useState} from 'react'
import {Button, Dialog} from '@kensho/neo'

import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'
import {AsyncStatus, TranscriptMetadata} from '../../../types/types'

interface DeleteDialogProps {
  isOpen: boolean
  onClose: () => void
  transcript: TranscriptMetadata
  deleteTranscript: (transcript: TranscriptMetadata) => Promise<void>
}

export default function DeleteDialog(props: DeleteDialogProps): React.ReactNode {
  const {isOpen, onClose, transcript, deleteTranscript} = props
  const [status, setStatus] = useState<AsyncStatus>('idle')

  const analytics = useContext(SiteAnalyticsContext)

  return (
    <Dialog backdrop="light" isOpen={isOpen} onClose={onClose} title="Delete this transcript?">
      Would you like to delete your transcript named “{transcript.name}”?{' '}
      <strong>Once deleted, it cannot be recovered.</strong>
      <div className="mt-6 flex justify-end gap-4">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          intent="danger"
          disabled={status === 'pending'}
          onClick={async () => {
            setStatus('pending')
            await deleteTranscript(transcript)
            analytics.sendEvent('delete_transcript')
            onClose()
          }}
        >
          Delete transcript
        </Button>
      </div>
    </Dialog>
  )
}
