import {useResizeObserver} from '@kensho/tacklebox'

interface Size {
  height: number
  width: number
}

interface PopoverDynamicContentProps {
  onResize: (size: Size) => void
  children: React.ReactNode
}

export default function PopoverDynamicContent(props: PopoverDynamicContentProps): React.ReactNode {
  const {onResize, children} = props
  const ref = useResizeObserver(onResize)
  return <div ref={ref}>{children}</div>
}
