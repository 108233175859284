import {Button, DropdownMenu} from '@kensho/neo'

export const PLAYBACK_RATES = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

interface PlaybackRateButtonProps {
  disabled: boolean
  playbackRate: number
  onChange: (playbackrate: number) => void
}

function prettyPlaybackRate(playbackRate: number): string {
  return `${`${playbackRate}`.replace(/^0/, '')}x`
}

export default function PlaybackRateButton(props: PlaybackRateButtonProps): React.ReactNode {
  const {disabled, playbackRate, onChange} = props

  return (
    <DropdownMenu
      items={PLAYBACK_RATES.map((rate) => ({
        id: rate.toString(),
        label: rate,
        onAction: () => onChange(rate),
      }))}
      position="top"
    >
      <Button minimal disabled={disabled}>
        {prettyPlaybackRate(playbackRate)}
      </Button>
    </DropdownMenu>
  )
}
