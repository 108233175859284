import {useCallback} from 'react'

import {API_ENDPOINT} from '../constants'
import {TranscriptMetadata} from '../types/types'
import parseError from '../utils/parseError'

import useAPIFetch from './useAPIFetch'

export default function useGetTranscriptMetadata(
  abortDuplicateRequests?: boolean,
): (transcriptId: string) => Promise<TranscriptMetadata> {
  const apiFetch = useAPIFetch(abortDuplicateRequests)
  return useCallback(
    (transcriptId: string) =>
      apiFetch
        .execute(`${API_ENDPOINT}/transcription/${transcriptId}/metadata`, {
          method: 'GET',
        })
        .then((response): Promise<TranscriptMetadata> => {
          if (!response.ok) throw parseError(response.status)
          const metadata = response.json()
          return metadata as Promise<TranscriptMetadata>
        })
        .catch((error: Error | DOMException) => {
          if ((error as DOMException).name === 'AbortError') throw error

          const clientError = error instanceof DOMException ? {type: 'unknown'} : parseError(error)
          throw clientError
        }),
    [apiFetch],
  )
}
