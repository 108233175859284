import React, {useContext} from 'react'

import useMultiplayerContext from '../../hooks/useMultiplayerContext'
import TranscriptContext from '../../providers/TranscriptContext'
import prettyDateTime from '../../utils/prettyDateTime'

export default function LastSavedStatus(): React.ReactNode {
  const {dispatch: transcriptContextDispatch, lastSaved} = useContext(TranscriptContext)
  useMultiplayerContext((message) => {
    if (message.type === 'patch') {
      transcriptContextDispatch({type: 'saved', date: new Date()})
    }
  })
  if (!lastSaved) return null
  return (
    <span
      className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-green-800"
      title={lastSaved ? `Last saved at ${prettyDateTime(`${lastSaved}`)}` : ''}
    >
      Last saved at {prettyDateTime(`${lastSaved}`)}
    </span>
  )
}
