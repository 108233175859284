import {Dispatch, useContext, useMemo} from 'react'
import {debounce} from 'lodash-es'
import {Button, InputGroup} from '@kensho/neo'

import SiteAnalyticsContext from '../../../providers/SiteAnalyticsContext'

import {OptionsAction} from './types'

interface ActionBarProps {
  search: string
  optionsDispatch: Dispatch<OptionsAction>
  onRecordButtonClick: () => void
  onUploadButtonClick: () => void
}
export default function ActionBar(props: ActionBarProps): React.ReactNode {
  const {search, optionsDispatch, onRecordButtonClick, onUploadButtonClick} = props

  const analytics = useContext(SiteAnalyticsContext)

  const debouncedSendEvent = useMemo(
    () =>
      debounce(() => {
        analytics.sendEvent('search_transcripts')
      }, 3000),
    [analytics],
  )

  return (
    <div className="flex items-center justify-between">
      <div className="text-xl">My transcripts</div>
      <div className="flex gap-4">
        <div className="w-72">
          <InputGroup
            placeholder="Search transcript titles"
            size="medium"
            value={search}
            onChange={(e) => {
              optionsDispatch({type: 'search', payload: e.target.value})
              debouncedSendEvent()
            }}
            icon="MagnifyingGlassIcon"
          />
        </div>
        <Button
          icon="CloudArrowUpIcon"
          intent="primary"
          size="medium"
          onClick={() => {
            analytics.sendEvent('upload_batch_file')
            onUploadButtonClick()
          }}
        >
          Upload File
        </Button>
        <Button
          size="medium"
          icon="MicrophoneIcon"
          onClick={() => {
            analytics.sendEvent('record_realtime')
            onRecordButtonClick()
          }}
        >
          Record
        </Button>
      </div>
    </div>
  )
}
