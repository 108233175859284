import {Component} from 'react'
import {useLogger, Logger} from '@kensho/lumberjack'

import GenericErrorState from './GenericErrorState'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback?: React.ReactNode
  log: Logger
}

interface ErrorBoundaryState {
  error: Error | null
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {error}
  }

  state: ErrorBoundaryState = {
    error: null,
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    const {log} = this.props
    log.error(error, info)
  }

  public render(): React.ReactNode {
    const {children, fallback} = this.props
    const {error} = this.state

    if (error === null) return children
    if (fallback) return fallback

    return <GenericErrorState />
  }
}

export default function ErrorBoundaryWithLogger(
  props: Omit<ErrorBoundaryProps, 'log'>,
): React.ReactNode {
  const log = useLogger()

  return <ErrorBoundary {...props} log={log} />
}
