import {APITranscript} from '../types/types'

import {FULL_TOKEN_OFFSET} from './constants'
import {KHighlight} from './types'

export function getPersistedHighlights(transcript: APITranscript): KHighlight[] {
  if (!transcript?.annotations) return []
  const tokensByAnnotation = new Map<string, {sliceIndex: number; tokenIndex: number}[]>()

  transcript.sliceMeta.forEach((slice, sliceIndex) => {
    slice.tokenMeta.forEach((token, tokenIndex) => {
      token.annotations?.forEach((annotation) => {
        if (!tokensByAnnotation.has(annotation.id)) {
          tokensByAnnotation.set(annotation.id, [])
        }
        tokensByAnnotation.get(annotation.id)?.push({sliceIndex, tokenIndex})
      })
    })
  })

  // TODO: update color assignment for "needs review"
  const colors = [
    'rgba(0,0,255, 0.3)',
    'rgba(255,0,0, 0.3)',
    'rgba(0,255,0, 0.3)',
    'rgba(255,255,0, 0.3)',
    'rgba(0,255,255, 0.3)',
    'rgba(255,0,255, 0.3)',
    'rgba(128,128,128, 0.3)',
  ]

  const persistedHighlights: KHighlight[] = []
  Object.values(transcript.annotations).forEach((annotation, i) => {
    const tokens = tokensByAnnotation.get(annotation.id)
    if (tokens && tokens.length > 0) {
      const kh: KHighlight = {
        style: {backgroundColor: colors[i % colors.length]},
        ranges: [
          {
            start: {
              sliceIndex: tokens[0].sliceIndex,
              textOffset: 0,
              tokenIndex: tokens[0].tokenIndex,
              type: 'token',
            },
            end: {
              sliceIndex: tokens[tokens.length - 1].sliceIndex,
              tokenIndex: tokens[tokens.length - 1].tokenIndex,
              textOffset: FULL_TOKEN_OFFSET,
              type: 'token',
            },
            type: 'Range',
          },
        ],
      }
      persistedHighlights.push(kh)
    }
  })

  return persistedHighlights
}
