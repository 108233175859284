import {APITranscript} from '../types/types'

export default function getDefaultTranscript(): APITranscript {
  return {
    sliceMeta: [],
    transcript: '',
    speakers: {
      '-1': {name: 'Unknown Speaker'},
    },
    accuracy: 1,
    annotations: {},
  }
}
