/**
 * Checks if input (string) is a valid transcript name.
 *
 * @param [input] transcript name
 * @returns invalid name error message or undefined
 */
export default function validateTranscriptName(input: string): string | undefined {
  if (input === '') return 'Name cannot be empty'
  if (input.trim() === '') return 'Name cannot be only whitespace'
  if (input.length > 256) return 'Name cannot be longer than 256 characters'
  return undefined
}
