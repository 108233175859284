import {mapKeys, snakeCase, mapValues, isArray, isPlainObject} from 'lodash-es'

/**
 * Type-safe wrapper for isPlainObject.
 */
function isObject(item: unknown): item is Record<string, unknown> {
  return isPlainObject(item)
}

export default function snakeCaseDeep(item: unknown): unknown {
  if (isObject(item)) {
    const snakeCased = mapKeys(item, (_, key: string) => {
      if (!Number(key)) {
        return snakeCase(key)
      }
      return key
    })

    return mapValues(snakeCased, snakeCaseDeep)
  }
  if (isArray(item)) {
    return item.map(snakeCaseDeep)
  }
  return item
}
