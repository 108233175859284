import {css} from '@emotion/react'
import {TextLink} from '@kensho/neo'

import {URLS, PRODUCT} from '../constants'

const footerCss = css`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  max-width: 1000px;
  justify-content: space-between;
  height: 50px;
  margin: 10px auto;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: auto;
    margin: 10px 12px;
  }

  @media screen and (max-width: 600px) {
    display: block;
  }
`

const sectionCss = css`
  flex: 0 1 auto;
  @media screen and (max-width: 600px) {
    text-align: center;
  }
`

const subHeaderCss = css`
  margin-top: 0;
  margin-bottom: 0;
`

export default function Footer(): React.ReactNode {
  return (
    <footer css={footerCss}>
      <div css={sectionCss}>
        <p css={subHeaderCss}>
          Have questions?{' '}
          <TextLink href={URLS.CONTACT_FORM}>Contact the {PRODUCT.NAME} Team</TextLink>
        </p>
      </div>
      <div css={sectionCss}>
        <TextLink href={URLS.TERMS_OF_USE}>Web Terms</TextLink>
        {'  '}|{'  '}
        <TextLink href={URLS.TERMS_OF_SERVICE}>Service Terms</TextLink>
        {'  '}|{'  '}
        <TextLink href={URLS.PRIVACY_POLICY}>Privacy</TextLink>
      </div>
    </footer>
  )
}
