import React, {Dispatch, SetStateAction} from 'react'
import {FileRejection} from 'react-dropzone'

import {Mode, Stage, TranscriptionConfiguration} from '../../../../types/types'
import NewTranscriptMenu from '../../NewTranscriptMenu'
import DownloadTranscriptButton from '../../actions/DownloadTranscriptButton'

interface Props {
  mode?: Mode
  stage: Stage
  setTranscriptDownloaded: Dispatch<SetStateAction<boolean>>
  transcriptionConfiguration: TranscriptionConfiguration
  resetAudioAndTranscript: () => void
  handleFileDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void
  showUnsavedTranscriptWarning: boolean
  showUploadInProgressWarning: () => void
}

export default function TranscriptRightSidebar(props: Props): React.ReactNode {
  const {
    mode,
    stage,
    transcriptionConfiguration,
    setTranscriptDownloaded,
    resetAudioAndTranscript,
    handleFileDrop,
    showUnsavedTranscriptWarning,
    showUploadInProgressWarning,
  } = props

  return (
    <aside className="mr-2 mt-20 flex w-40 flex-col gap-4">
      {/* Download Transcript */}
      <DownloadTranscriptButton
        mode={mode}
        transcriptionConfiguration={transcriptionConfiguration}
        onDownload={() => setTranscriptDownloaded(true)}
      />

      {/* New Transcription */}
      <NewTranscriptMenu
        setTranscriptDownloaded={setTranscriptDownloaded}
        handleFileDrop={handleFileDrop}
        resetAudioAndTranscript={resetAudioAndTranscript}
        showUnsavedTranscriptWarning={showUnsavedTranscriptWarning}
        showUploadInProgressWarning={showUploadInProgressWarning}
        stage={stage}
      />
    </aside>
  )
}
