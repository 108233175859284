import {css, SerializedStyles} from '@emotion/react'
import {Placement, StrictModifiers} from '@popperjs/core'
import {forwardRef} from 'react'
import {createPortal} from 'react-dom'
import {Manager, Popper, Reference} from 'react-popper'

import usePortalContainer from '../hooks/usePortalContainer'

import PopoverDynamicContent from './PopoverDynamicContent'

interface PopoverProps {
  className?: string
  isOpen: boolean
  onClose?: () => void
  target: React.ReactNode
  children: React.ReactNode
  popperModifiers?: StrictModifiers[]
  popperPlacement?: Placement
  arrow?: boolean
  arrowColor?: string
}

const popoverSurfaceCss = css`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 5px;
  max-height: 100%;
  z-index: 600;
`

function getArrowCss(color = '#fff'): SerializedStyles {
  return css`
    position: absolute;
    height: 6px;
    width: 14px;

    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &[data-placement*='bottom'] {
      top: 0;
      left: 0;
      margin-top: -6px;
      width: 14px;
      height: 6px;
      &::before {
        border-width: 0 7px 6px 7px;
        border-color: transparent transparent ${color} transparent;
      }
    }

    &[data-placement*='top'] {
      bottom: 0;
      left: 0;
      margin-bottom: -6px;
      width: 14px;
      height: 7px;
      &::before {
        border-width: 6px 7px 0 7px;
        border-color: ${color} transparent transparent transparent;
      }
    }

    &[data-placement*='right'] {
      left: 0;
      margin-left: -6px;
      height: 14px;
      width: 7px;
      &::before {
        border-width: 7px 6px 7px 0;
        border-color: transparent ${color} transparent transparent;
      }
    }

    &[data-placement*='left'] {
      right: 0;
      margin-right: -6px;
      height: 14px;
      width: 7px;
      &::before {
        border-width: 7px 0 7px 6px;
        border-color: transparent transparent transparent ${color};
      }
    }
  `
}

function Popover(props: PopoverProps, forwardedRef?: React.Ref<HTMLDivElement>): React.ReactNode {
  const {
    className,
    isOpen,
    children,
    popperPlacement = 'bottom-end',
    popperModifiers = [],
    target,
    arrow = false,
    arrowColor,
  } = props
  const portalContainer = usePortalContainer(isOpen)

  const modifiers = [...popperModifiers]
  if (arrow)
    modifiers.push({
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    })

  return (
    <Manager>
      <Reference>{({ref}) => <div ref={ref}>{target}</div>}</Reference>
      {isOpen && portalContainer && (
        <Popper placement={popperPlacement} modifiers={modifiers}>
          {({ref, style, placement, update, arrowProps}) =>
            createPortal(
              <div
                className={className}
                css={popoverSurfaceCss}
                ref={(element) => {
                  ;[ref, forwardedRef].filter(Boolean).forEach((r) => {
                    if (typeof r === 'function') {
                      r(element)
                    } else if (r && typeof r === 'object') {
                      /* eslint-disable-next-line no-param-reassign */
                      ;(r as React.MutableRefObject<HTMLDivElement | null>).current = element
                    }
                  })
                }}
                data-placement={placement}
                style={style}
              >
                {arrow && (
                  <div
                    ref={arrowProps.ref}
                    style={arrowProps.style}
                    css={getArrowCss(arrowColor)}
                    data-placement={placement}
                  />
                )}
                <PopoverDynamicContent onResize={update}>{children}</PopoverDynamicContent>
              </div>,
              portalContainer,
            )
          }
        </Popper>
      )}
    </Manager>
  )
}

export default forwardRef(Popover)
