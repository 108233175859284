import {css} from '@emotion/react'

import Skeleton from '../../../components/Skeleton'
import {TranscriptMetadata} from '../../../types/types'

interface SkeletonTranscriptProps {
  metadata?: TranscriptMetadata
}

const sliceCss = css`
  margin: 6px 0;
`

const skeletonTranscriptCss = css`
  z-index: 1;
  width: 100%;
  max-width: 95vw;
`

const skeletonLineCss = css`
  border-radius: 16px;
  margin: 16px 0;
`

const line0Css = css`
  animation-duration: 1500ms;
  animation-delay: 0ms;
`
const line1Css = css`
  animation-duration: 800ms;
  animation-delay: 500ms;
`
const line2Css = css`
  animation-duration: 700ms;
  animation-delay: 700ms;
`
const line3Css = css`
  animation-duration: 900ms;
  animation-delay: 100ms;
`
const line4Css = css`
  animation-duration: 1400ms;
  animation-delay: 300ms;
`

const statusTextCss = css`
  position: absolute;
  margin: 25% auto;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  opacity: 0.54;
  z-index: 1;
`

export default function SkeletonTranscript(props: SkeletonTranscriptProps): React.ReactNode {
  const {metadata} = props

  return (
    <div css={skeletonTranscriptCss}>
      {metadata && (
        <div css={statusTextCss}>
          {metadata.status !== 'complete' ? 'Transcribing…' : 'Loading…'}
        </div>
      )}
      <div css={sliceCss}>
        <Skeleton css={[skeletonLineCss, line0Css]} width={75} height={8} />
        <Skeleton css={[skeletonLineCss, line1Css]} width="96%" height={8} />
        <Skeleton css={[skeletonLineCss, line2Css]} width="90%" height={8} />
        <Skeleton css={[skeletonLineCss, line3Css]} width="94%" height={8} />
        <Skeleton css={[skeletonLineCss, line4Css]} width="92%" height={8} />
      </div>
      <div css={sliceCss}>
        <Skeleton css={[skeletonLineCss, line0Css]} width={75} height={8} />
        <Skeleton css={[skeletonLineCss, line1Css]} width="96%" height={8} />
        <Skeleton css={[skeletonLineCss, line2Css]} width="90%" height={8} />
        <Skeleton css={[skeletonLineCss, line3Css]} width="94%" height={8} />
        <Skeleton css={[skeletonLineCss, line4Css]} width="92%" height={8} />
      </div>
    </div>
  )
}
