import {Button, InputGroup} from '@kensho/neo'
import clsx from 'clsx'

import copyText from '../utils/copyText'

interface CopyableValueProps {
  className?: string
  value: string
  label?: string
  description?: string
}

export default function CopyableValue(props: CopyableValueProps): React.ReactNode {
  const {className, value, label, description} = props

  return (
    <div className={clsx('flex items-center gap-3', className)}>
      <InputGroup value={value} label={label} description={description} readOnly />
      <Button aria-label="Copy to clipboard" icon="ClipboardIcon" onClick={() => copyText(value)} />
    </div>
  )
}
