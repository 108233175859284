import {ScaleLinear} from 'd3-scale'
import {useEffect, useState} from 'react'

import {formatTimestamp} from './timestampUtils'

export interface Props {
  height: number
  xScale: ScaleLinear<number, number, unknown>
}

export default function MouseHoverLine(props: Props): React.ReactNode {
  const {height, xScale} = props
  const [mousePosition, setMousePosition] = useState({x: 0, y: 0})

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent): void => {
      setMousePosition({x: event.clientX, y: event.clientY})
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  return (
    <g className="pointer-events-none select-none">
      <defs>
        <marker id="dot" viewBox="0 0 4 4" refX="2" refY="2">
          <circle className="fill-brand-700" cx="2" cy="2" r="2" />
        </marker>
      </defs>
      {xScale.invert(mousePosition.x) > 0 && (
        <>
          <rect x={mousePosition.x} y={15} width={70} height={18} className="fill-white" />
          <text className="text-md fill-brand-700 font-sans" x={mousePosition.x + 4} y={28}>
            {formatTimestamp(Math.floor(xScale.invert(mousePosition.x)), true)}
          </text>
        </>
      )}
      <line
        y1={0}
        y2={height}
        strokeWidth={1}
        className="stroke-brand-700"
        x1={mousePosition.x}
        x2={mousePosition.x}
        markerStart="url(#dot)"
      />
    </g>
  )
}
