import {useState} from 'react'
import {Overlay, Button, Tooltip} from '@kensho/neo'

import {UpdateTranscriptSelectionType} from '../../../hooks/useTranscriptSelection'
import Reviewer, {ReviewItem} from '../transcript/review/Reviewer'
import {OperationAction} from '../../../utils/transcriptPatchUtils'

interface Props {
  onEditOperationError: (error: Error, action: OperationAction) => void
  updateTranscriptSelection: UpdateTranscriptSelectionType
  scrollTranscriptToTime: (timeMs: number) => Promise<void>
}

export default function ReviewButton(props: Props): React.ReactNode {
  const {onEditOperationError, updateTranscriptSelection, scrollTranscriptToTime} = props
  const [isOpen, setIsOpen] = useState(false)

  const [items, setItems] = useState<ReviewItem[]>([])

  return (
    <>
      <Tooltip content="Review" position="bottom">
        <Button
          minimal
          icon="CheckIcon"
          intent="primary"
          onClick={() => {
            setIsOpen((prev) => !prev)
          }}
          aria-label="Review"
        />
      </Tooltip>
      <Overlay backdrop={null} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className="pointer-events-auto fixed right-2 top-24 h-[260px] w-[380px] overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-10">
          <Reviewer
            onEditOperationError={onEditOperationError}
            onClose={() => setIsOpen(false)}
            updateTranscriptSelection={updateTranscriptSelection}
            scrollTranscriptToTime={scrollTranscriptToTime}
            items={items}
            setItems={setItems}
          />
        </div>
      </Overlay>
    </>
  )
}
