import {Radio, RadioGroup} from '@kensho/neo'

import useLocalStorageState from '../../hooks/useLocalStorageState'

import {
  DEFAULT_DICTIONARY,
  DICTIONARIES,
  DICTIONARY_STORE_KEY,
  DictionaryCode,
} from './dictionaries'

export default function DictionaryPreference(): React.ReactNode {
  const [dictionaryCode, setDictionaryCode] = useLocalStorageState(
    DICTIONARY_STORE_KEY,
    DEFAULT_DICTIONARY,
  )

  return (
    <RadioGroup
      label="Dictionary"
      description="Default dictionary to use for spellcheck. A transcription's output locale will override this default."
      value={dictionaryCode || DICTIONARIES[0].langCode}
      onChange={(nextValue) => {
        setDictionaryCode(nextValue as DictionaryCode)
      }}
    >
      {DICTIONARIES.map((dictionary) => (
        <Radio key={dictionary.langCode} label={dictionary.label} value={dictionary.langCode} />
      ))}
    </RadioGroup>
  )
}
