import {ButtonLink} from '@kensho/neo'

import useObjectURL from '../../../hooks/useObjectURL'

export default function DownloadMediaFileButton({
  mediaFile,
  name = 'audio',
}: {
  mediaFile: File
  name?: string
}): React.ReactNode {
  const url = useObjectURL(mediaFile)
  if (!url) return null

  return (
    <ButtonLink
      href={url}
      download={name}
      aria-label="Download recording"
      icon="ArrowDownTrayIcon"
      minimal
    />
  )
}
