import * as React from 'react'
import {forwardRef, useEffect, useRef, useState} from 'react'

import useRefState from '../hooks/useRefState'
import {ScribeError} from '../types/types'

import Audio from './Audio'
import Video from './Video'

interface MediaProps extends React.HTMLAttributes<HTMLMediaElement> {
  controls?: boolean
  mediaSrc?: string
  playbackRate?: number
  volume?: number
  muted?: boolean
  paused?: boolean
  fullscreen?: boolean
  mediaIsVideo?: boolean
  hidden?: boolean
  showSubtitles?: boolean
  onMediaTypeChange: (mediaIsVideo: boolean) => void
  onMediaError: (error?: ScribeError) => void
  onFullscreenChange?: () => void
}

/**
 * Determines if the media src is a video
 * Creates a <video> element and waits for loadedmetadata event to check if videoHeight > 0
 * Otherwise we can assume it is audio
 */
function isVideo(mediaDataURL?: string): Promise<boolean> {
  if (!mediaDataURL) return Promise.resolve(false)

  const videoEle = document.createElement('video')

  return new Promise<boolean>((resolve, reject) => {
    const timeout = window.setTimeout(() => {
      reject(Error('Media type detection timeout exceeded'))
    }, 10 * 1000) // 10 seconds

    videoEle.addEventListener('loadedmetadata', () => {
      window.clearTimeout(timeout)
      resolve(videoEle.videoHeight > 0)
    })

    videoEle.src = mediaDataURL
  })
}

function Media(props: MediaProps, ref: React.Ref<HTMLMediaElement>): React.ReactNode {
  const {
    className,
    children,
    mediaSrc,
    onCanPlayThrough,
    mediaIsVideo,
    onMediaTypeChange,
    onMediaError,
    hidden,
    controls,
    onFullscreenChange,
    paused,
    volume,
    playbackRate,
    fullscreen,
    showSubtitles,
    ...rest
  } = props
  const [mediaEle, setMediaEle] = useState<HTMLMediaElement | null>(null)
  const refState = useRefState(ref, setMediaEle)

  // detect if this is an audio or video file
  useEffect(() => {
    if (!mediaSrc) return undefined

    let current = true
    isVideo(mediaSrc)
      .then((result) => {
        if (current) onMediaTypeChange(result)
      })
      .catch(() => {
        if (current) onMediaError({type: 'corruptedFile'})
      })

    return () => {
      current = false
    }
  }, [mediaSrc, onMediaError, onMediaTypeChange])

  // handle toggling of fullscreen display
  useEffect(() => {
    if (!(mediaEle && onFullscreenChange)) return undefined

    mediaEle.addEventListener('fullscreenchange', onFullscreenChange)
    return () => {
      mediaEle.removeEventListener('fullscreenChange', onFullscreenChange)
    }
  }, [onFullscreenChange, mediaEle])

  // keep media element state in sync with react component state

  useEffect(() => {
    if (fullscreen) mediaEle?.requestFullscreen()
  }, [fullscreen, mediaEle])

  const playPromiseRef = useRef<Promise<void>>()
  useEffect(() => {
    if (mediaEle && paused !== undefined && mediaEle.paused !== paused && !playPromiseRef.current) {
      if (paused) {
        mediaEle.pause()
      } else {
        playPromiseRef.current = mediaEle
          .play()
          .catch(() => {
            // ignore play errors which are caused by race conditions between play/pause
            // typically once the user triggers another play/pause, playback will be resolved
            // if the media is not going to play at all those errors would be caught earlier
            // in the lifecycle of this component
          })
          .finally(() => {
            playPromiseRef.current = undefined
          })
      }
    }
  }, [paused, mediaEle])

  useEffect(() => {
    if (mediaEle && volume !== undefined) mediaEle.volume = volume
  }, [volume, mediaEle])

  useEffect(() => {
    if (mediaEle && playbackRate !== undefined) mediaEle.playbackRate = playbackRate
  }, [playbackRate, mediaEle])

  return (
    <div className={className}>
      {mediaSrc && mediaIsVideo && (
        <Video
          mediaSrc={mediaSrc}
          ref={refState}
          showSubtitles={showSubtitles}
          {...rest}
          controls={fullscreen}
          onError={() => onMediaError()}
        >
          {children}
        </Video>
      )}
      {mediaSrc && !mediaIsVideo && (
        <Audio
          mediaSrc={mediaSrc}
          ref={refState}
          {...rest}
          controls={fullscreen}
          onError={() => onMediaError()}
        >
          {children}
        </Audio>
      )}
    </div>
  )
}

export default forwardRef(Media)
