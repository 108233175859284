/* eslint-disable no-underscore-dangle */
import closeMediaStream from '../../../utils/closeMediaStream'
import getAudioContext from '../../../utils/getAudioContext'
import {
  REALTIME_NUM_CHANNELS,
  REALTIME_RECORDING_MIME_TYPE,
  REALTIME_TRANSCRIPTION_SAMPLE_RATE,
} from '../constants'

import convertToWAV from './utils/convertToWAV'

export type RecordingManagerState =
  | 'uninitialized'
  | 'initializing'
  | 'recording'
  | 'paused'
  | 'closed'
  | 'error'

const resampleAudioWorkerServer = new Worker(
  new URL('../../../workers/resampleAudio.worker.js', import.meta.url),
)

/**
 * Manages the lifecycle and event callbacks for consuming the user's audio input
 *
 * Creates and cleans up the MediaStream, MediaRecorder, and AudioContext graph
 * Handles resampling the raw audio stream into the desired output stream(s)
 *
 * This class needs to be a singleton because the resampling workers are stateful
 */
export default class RecordingManager {
  state: RecordingManagerState = 'uninitialized'

  audioInputDeviceId: string

  recordedChunks: number[][] = []

  mediaStream?: MediaStream

  mediaRecorder?: MediaRecorder

  onStateChange?: (state: RecordingManagerState) => void

  onMediaRecorderStateChange?: (state: RecordingState) => void

  onError?: () => void

  onProcessAudio?: (audioData: number[]) => void

  volumes: number[] = []

  volumeRangeIntervalId: number | null = null

  constructor(audioInputDeviceId: string) {
    this.audioInputDeviceId = audioInputDeviceId
    resampleAudioWorkerServer.onmessage = this._onProcessAudio.bind(this)
  }

  setState(value: RecordingManagerState): void {
    this.state = value
    if (this.onStateChange) this.onStateChange(value)
  }

  _onMediaRecorderStateChange(): void {
    if (this.onMediaRecorderStateChange)
      this.onMediaRecorderStateChange(this.mediaRecorder?.state || 'inactive')
  }

  _onError(): void {
    this.close()
    this.setState('error')
    if (this.onError) this.onError()
  }

  _onProcessAudio({data}: MessageEvent<number[]>): void {
    if (this.onProcessAudio) this.onProcessAudio(data)
    this.recordedChunks.push(data)
  }

  open(): void {
    if (!navigator.mediaDevices) {
      this.mediaStream = undefined
      this._onError()
      return
    }

    if (this.state !== 'uninitialized') return
    this.setState('initializing')

    // initialize MediaStream
    navigator.mediaDevices
      .getUserMedia({audio: {deviceId: this.audioInputDeviceId}})
      .then(async (stream) => {
        // if the user has closed the recording manager before the stream has been initialized,
        // close the stream and return
        if (this.state === 'closed') {
          closeMediaStream(stream)
          return
        }

        this.mediaStream = stream

        // initialize MediaRecorder
        this.mediaRecorder = new MediaRecorder(this.mediaStream, {
          mimeType: REALTIME_RECORDING_MIME_TYPE,
        })

        this.mediaRecorder.onerror = () => {
          this._onMediaRecorderStateChange()
          this.setState('error')
        }
        this.mediaRecorder.onpause = () => {
          this._onMediaRecorderStateChange()
          this.setState('paused')
        }
        this.mediaRecorder.onresume = () => {
          this._onMediaRecorderStateChange()
          this.setState('recording')
        }
        this.mediaRecorder.onstart = () => {
          this._onMediaRecorderStateChange()
          this.setState('recording')
        }
        this.mediaRecorder.onstop = () => {
          this._onMediaRecorderStateChange()
          this.setState('paused')
        }

        // create AudioContext graph
        const audioContext = getAudioContext()
        const {destination} = audioContext
        const source = audioContext.createMediaStreamSource(stream)

        await audioContext.audioWorklet.addModule(
          new URL('../../../workers/audioResamplerProcessor.js', import.meta.url),
        )
        const audioResamplerProcessor = new AudioWorkletNode(
          audioContext,
          'audioResamplerProcessor',
          {numberOfInputs: REALTIME_NUM_CHANNELS, numberOfOutputs: REALTIME_NUM_CHANNELS},
        )

        const analyser = audioContext.createAnalyser()
        analyser.fftSize = 32
        source.connect(analyser)
        source.connect(audioResamplerProcessor)
        audioResamplerProcessor.connect(destination)

        function getVolume(dataArray: Uint8Array): number {
          const sum = dataArray.reduce((acc, value) => acc + value, 0)
          const average = sum / dataArray.length
          return average
        }

        this.volumeRangeIntervalId = window.setInterval(() => {
          const dataArray = new Uint8Array(analyser.frequencyBinCount)
          analyser.getByteFrequencyData(dataArray)
          const volume = getVolume(dataArray)

          this.volumes.push(volume)
          if (this.volumes.length > 100) this.volumes.shift()
        }, 100)
        audioResamplerProcessor.port.onmessage = (event) => {
          if (this.state !== 'recording') return
          resampleAudioWorkerServer.postMessage({
            sourceSampleRate: event.data.sampleRate,
            inputBuffer: event.data.inputData,
            targetSampleRate: REALTIME_TRANSCRIPTION_SAMPLE_RATE,
          })
        }

        this.mediaRecorder.start()
      })
      .catch(() => {
        this._onError()
      })
  }

  close(): void {
    this.setState('closed')

    if (this.mediaStream) {
      closeMediaStream(this.mediaStream)
      this.mediaStream = undefined
    }

    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop()
    }

    if (resampleAudioWorkerServer) resampleAudioWorkerServer.postMessage({reset: true})
    if (this.volumeRangeIntervalId) clearInterval(this.volumeRangeIntervalId)
  }

  resume(): void {
    if (this.state === 'closed')
      throw new Error(`RecordingManager: Can't resume a recording that is already closed.`)

    if (!this.mediaRecorder) return

    this.setState('recording')
    if (this.mediaRecorder.state === 'inactive') {
      this.mediaRecorder.start()
    } else {
      this.mediaRecorder.resume()
    }
  }

  pause(): void {
    if (this.state === 'closed')
      throw new Error(`RecordingManager: Can't pause a recording that is already closed.`)

    this.setState('paused')
    this.mediaRecorder?.pause()
  }

  reset(): void {
    this.recordedChunks = []
    if (resampleAudioWorkerServer) resampleAudioWorkerServer.postMessage({reset: true})
  }

  getRecording(): Blob {
    return convertToWAV(this.recordedChunks, REALTIME_TRANSCRIPTION_SAMPLE_RATE)
  }

  /* returns total duration of recording in seconds */
  get duration(): number {
    return (
      this.recordedChunks.reduce((acc, chunk) => acc + chunk.length, 0) /
      REALTIME_TRANSCRIPTION_SAMPLE_RATE
    )
  }

  get volumeLevel(): number {
    if (!this.volumes) return 0
    const current = this.volumes[this.volumes.length - 1]
    const min = Math.min(...this.volumes)
    const max = Math.max(...this.volumes)
    const divisor = max - min
    const percentile = (current - min) / (divisor <= 0 ? 255 : divisor)
    return percentile
  }
}
