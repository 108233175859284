import {capitalize} from 'lodash-es'

export default function formatCase(text: string, shouldCapitalize: boolean): string {
  const isPlainWord = /^[a-z]+$/i.test(text)
  const isNumber = /\d+$/.test(text.replace(/\W+/g, ''))

  if (isNumber) {
    return text
  }

  if (isPlainWord) {
    if (shouldCapitalize) return capitalize(text)
    return text.toLowerCase()
  }

  let count = 0

  return text.replace(/(\W*)(\w+)/g, (match, nonWordChar, word) => {
    // contractions should be lowercase
    if (nonWordChar === "'" && count !== 0) {
      return nonWordChar + word.toLowerCase()
    }

    // return acronym as is
    if (/^[A-Z]+$/.test(word)) {
      count += 1
      return nonWordChar + word
    }

    let capitalizedWord = ''
    if (shouldCapitalize) {
      capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1)
      count += 1

      return nonWordChar + capitalizedWord
    }

    capitalizedWord = word.charAt(0).toLowerCase() + word.slice(1)
    count += 1

    return nonWordChar + capitalizedWord
  })
}
