import {z} from 'zod'

const AddPatchSchema = z.object({
  op: z.literal('add'),
  path: z.string(),
  value: z.any(),
})

const ReplacePatchSchema = z.object({
  op: z.literal('replace'),
  path: z.string(),
  value: z.any(),
})

const RemovePatchSchema = z.object({
  op: z.literal('remove'),
  path: z.string(),
})

const JSONPatchSchema = z.discriminatedUnion('op', [
  AddPatchSchema,
  ReplacePatchSchema,
  RemovePatchSchema,
])

export type JSONPatch = z.infer<typeof JSONPatchSchema>

const TokenSelectionNodeSchema = z.object({
  type: z.literal('token'),
  sliceIndex: z.number(),
  tokenIndex: z.number(),
  textOffset: z.number(),
})
const TokenSpaceSelectionNodeSchema = z.object({
  type: z.literal('token-space'),
  sliceIndex: z.number(),
  tokenIndex: z.number(),
  textOffset: z.number(),
})

const TranscriptSelectionNodeSchema = z.discriminatedUnion('type', [
  TokenSelectionNodeSchema,
  TokenSpaceSelectionNodeSchema,
])

export const MultiplayerServerMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('authenticated'),
    payload: z.object({
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('presence-add'),
    payload: z.object({
      clientId: z.string(),
      sessionId: z.string(),
      clientName: z.string(),
    }),
  }),
  z.object({
    type: z.literal('presence-remove'),
    payload: z.object({
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('error'),
    payload: z.object({
      code: z.string(),
      title: z.string(),
      detail: z.string(),
    }),
  }),
  z.object({
    type: z.literal('patch'),
    payload: z.object({
      patches: z.array(JSONPatchSchema),
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('cursor-update'),
    payload: z.object({
      start: TranscriptSelectionNodeSchema.nullable(),
      end: TranscriptSelectionNodeSchema.nullable(),
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('reconnect'),
    payload: z.object({
      detail: z.string(),
    }),
  }),
])

export type MultiplayerServerMessage = z.infer<typeof MultiplayerServerMessageSchema>

export const MultiplayerClientMessageSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('authenticate'),
    payload: z.object({
      token: z.string(),
    }),
  }),
  z.object({
    type: z.literal('patch'),
    payload: z.object({
      patches: z.array(JSONPatchSchema),
      sessionId: z.string(),
    }),
  }),
  z.object({
    type: z.literal('cursor-update'),
    payload: z.object({
      start: TranscriptSelectionNodeSchema.nullable(),
      end: TranscriptSelectionNodeSchema.nullable(),
      sessionId: z.string(),
    }),
  }),
])

export type MultiplayerClientMessage = z.infer<typeof MultiplayerClientMessageSchema>
