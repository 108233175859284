interface OutputWAVOptions {
  channels: number
  sampleRate: number
}

interface OutputWAVHeaderOptions extends OutputWAVOptions {
  frames: number
}

function getWAVHeader({channels, sampleRate, frames}: OutputWAVHeaderOptions): Uint8Array {
  const bytesPerSample = 4
  const format = 3
  const blockAlign = channels * bytesPerSample
  const byteRate = sampleRate * blockAlign
  const dataSize = frames * blockAlign

  const buffer = new ArrayBuffer(44)
  const dv = new DataView(buffer)

  let offset = 0

  function writeString(s: string): void {
    for (let i = 0; i < s.length; i += 1) {
      dv.setUint8(offset + i, s.charCodeAt(i))
    }
    offset += s.length
  }

  function writeUint32(d: number): void {
    dv.setUint32(offset, d, true)
    offset += 4
  }

  function writeUint16(d: number): void {
    dv.setUint16(offset, d, true)
    offset += 2
  }

  writeString('RIFF') // ChunkID
  writeUint32(dataSize + 36) // ChunkSize
  writeString('WAVE') // Format
  writeString('fmt ') // Subchunk1ID
  writeUint32(16) // Subchunk1Size
  writeUint16(format) // AudioFormat
  writeUint16(channels) // NumChannels
  writeUint32(sampleRate) // SampleRate
  writeUint32(byteRate) // ByteRate
  writeUint16(blockAlign) // BlockAlign
  writeUint16(bytesPerSample * 8) // BitsPerSample
  writeString('data') // Subchunk2ID
  writeUint32(dataSize) // Subchunk2Size

  return new Uint8Array(buffer)
}

/** Returns Uint8Array of WAV bytes */
function getWAVBytes(buffer: ArrayBufferLike, options: OutputWAVOptions): Uint8Array {
  const frames = buffer.byteLength / Float32Array.BYTES_PER_ELEMENT

  const headerBytes = getWAVHeader({...options, frames})
  const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength)

  wavBytes.set(headerBytes, 0)
  wavBytes.set(new Uint8Array(buffer), headerBytes.length)

  return wavBytes
}

export default function convertToWAV(chunks: number[][], sampleRate: number): Blob {
  const audioData = new Float32Array(
    chunks.reduce<number[]>((acc, chunk) => {
      acc.push(...chunk)
      return acc
    }, []),
  )
  const bytes = getWAVBytes(audioData.buffer, {
    channels: 1,
    sampleRate,
  })

  return new Blob([bytes], {type: 'audio/wav'})
}
