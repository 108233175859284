import {LoggerProvider, transportConsole, transportSentry} from '@kensho/lumberjack'

const transports = import.meta.env.PROD
  ? [transportSentry({levels: ['error', 'warning']})]
  : [transportConsole()]

interface AppLoggerProviderProps {
  children: React.ReactNode
}

export default function AppLoggerProvider(props: AppLoggerProviderProps): React.ReactNode {
  const {children} = props
  return <LoggerProvider transports={transports}>{children}</LoggerProvider>
}
