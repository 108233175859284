// sample rate scribe API expects
export const REALTIME_TRANSCRIPTION_SAMPLE_RATE = 16000
// sample rate used as the source of realtime audio data for waveform
export const REALTIME_NUM_CHANNELS = 1
export const REALTIME_RECORDING_MIME_TYPE = 'audio/webm'
export const REALTIME_SRC_MIME_TYPE = 'audio/wav'
export const UNKNOWN_SPEAKER_COLOR = '#999999'
export const VALID_AUDIO_FILE_EXTS = ['.aac', '.flac', '.m4a', '.mp3', '.mp4', '.wav', '.webm']
export const VALID_VIDEO_FILE_EXTS = ['.mp4', '.webm']
// From Kensho design system light theme
// https://www.figma.com/file/uRi4O8iVvy1o65yNDcETuE/Kensho-Design-System?node-id=429%3A1165
// colors will be repeated if there are more speakers
export const SPEAKER_COLORS = [
  '#007a99',
  '#a70daf',
  '#392ad8',
  '#996800',
  '#c61936',
  '#01822c',
  '#00a1c9',
  '#f53bff',
  '#7266ff',
  '#c78800',
  '#eb4460',
  '#00ab39',
  '#005675',
  '#6e0274',
  '#0b008f',
  '#664600',
  '#910018',
  '#005c1f',
  // overflow colors, want to avoid using but here in case we do need in the future
  // and I don't want to type them all out again
  // '#2EBADF',
  // '#8c0693',
  // '#0b0274',
  // '#ffe4a3',
  // '#972034',
  // '#9eff81',
  // '#46cae6',
  // '#500054',
  // '#070054',
  // '#554217',
  // '#55181F',
  // '#32801b',
]
export const TIMELINE_HEIGHT = 35

// calculated by looking at 5 hours of transcripts
export const DURATION_PER_CHAR_MS = 35
export const DURATION_TOKEN_GAP_MS = 150

export interface UserColor {
  color: string
  border: string
  fill: string
}
export const USER_COLORS: UserColor[] = [
  {color: 'pink', border: '#BE185D', fill: '#FBCFE8'},
  {color: 'lime', border: '#4D7C0F', fill: '#D9F99D'},
  {color: 'violet', border: '#6D28D9', fill: '#DDD6FE'},
  {color: 'orange', border: '#C2410C', fill: '#FED7AA'},
  {color: 'blue', border: '#1D4ED8', fill: '#BFDBFE'},
  {color: 'red', border: '#B91C1C', fill: '#FECACA'},
  {color: 'teal', border: '#0F766E', fill: '#99F6E4'},
  {color: 'purple', border: '#7E22CE', fill: '#E9D5FF'},
  {color: 'amber', border: '#B45309', fill: '#FED68A'},
  {color: 'indigo', border: '#4338CA', fill: '#C7D2FE'},
  {color: 'rose', border: '#B91C1C', fill: '#FBCFE8'},
  {color: 'emerald', border: '#047857', fill: '#A7F3D0'},
  {color: 'fuchsia', border: '#A21CAF', fill: '#F0ABFC'},
  {color: 'yellow', border: '#A16200', fill: '#FDE68A'},
  {color: 'stone', border: '#7B7B7B', fill: '#E5E5E5'},
  {color: 'sky', border: '#1E40AF', fill: '#BFDBFE'},
  {color: 'green', border: '#065F46', fill: '#BBF7D0'},
  {color: 'cyan', border: '#0E7490', fill: '#87CEEB'},
]
