import {useEffect, useMemo, useState} from 'react'

export enum ReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}

type WebSocketOnOpen = (event: WebSocketEventMap['open']) => void
type WebSocketOnClose = (event: WebSocketEventMap['close']) => void
type WebSocketOnMessage = (message: WebSocketEventMap['message']) => void
type WebSocketOnError = (event: WebSocketEventMap['error']) => void

/**
 * Creates a WebSocket connection and attaches the given handlers
 */
export default function useWebsocket({
  url,
  onOpen,
  onClose,
  onMessage,
  onError,
}: {
  url: string
  onOpen?: WebSocketOnOpen
  onClose?: WebSocketOnClose
  onMessage?: WebSocketOnMessage
  onError?: WebSocketOnError
}): {readyState: ReadyState; websocket?: WebSocket} {
  const [readyState, setReadyState] = useState<ReadyState>(ReadyState.CLOSED)
  const [websocket, setWebsocket] = useState<WebSocket>()

  // create the websocket
  useEffect(() => {
    const ws = new WebSocket(url)
    // add readyState change handlers here without passed in handlers so that
    // this effect can handle purely creation and cleanup but without missing any state changes
    const onStateChange = (): void => setReadyState(ws ? ws.readyState : ReadyState.CLOSED)
    ws.onopen = onStateChange
    ws.onclose = onStateChange
    ws.onerror = onStateChange

    setWebsocket(ws)

    return () => {
      if (ws.readyState === ws.OPEN) ws.close()
    }
  }, [url])

  // attach the handlers
  useEffect(() => {
    if (!websocket) return

    websocket.onopen = (event: WebSocketEventMap['open']): void => {
      if (onOpen) onOpen(event)
      setReadyState(websocket ? websocket.readyState : ReadyState.CLOSED)
    }
    websocket.onclose = (event: WebSocketEventMap['close']): void => {
      if (onClose) onClose(event)
      setReadyState(websocket ? websocket.readyState : ReadyState.CLOSED)
    }
    websocket.onmessage = (message: WebSocketEventMap['message']): void => {
      if (onMessage) onMessage(message)
    }
    websocket.onerror = (event: WebSocketEventMap['error']): void => {
      if (onError) onError(event)
      setReadyState(websocket ? websocket.readyState : ReadyState.CLOSED)
    }
  }, [onOpen, onClose, onMessage, onError, websocket])

  return useMemo(() => ({readyState, websocket}), [readyState, websocket])
}
