import {useCallback} from 'react'

import useAPIFetch from './useAPIFetch'

export default function useUpdateTranscriptMetadata(): (
  id: string,
  name: string,
) => Promise<Response> {
  const apiFetch = useAPIFetch()

  const updateTranscriptMetadata = useCallback(
    (id: string, name: string) =>
      apiFetch.execute(`/api/v2/transcription/${id}/metadata`, {
        method: 'PATCH',
        body: JSON.stringify({name}),
        headers: {
          'Content-Type': 'application/json',
        },
      }),

    [apiFetch],
  )

  return updateTranscriptMetadata
}
