// can't use custom sampleRate because Firefox doesn't support different sampleRates between
// mediaStream and AudioContext
// (which means we have to reconcile source and desired sampleRates in JS)
type AudioContextOpts = Omit<AudioContextOptions, 'sampleRate'>

// Should be a singleton according to https://developer.mozilla.org/en-US/docs/Web/API/AudioContext
let audioCtx: AudioContext

/** Get the singleton instance of an AudioContext */
export default function getAudioContext(options?: AudioContextOpts): AudioContext {
  if (!audioCtx) {
    audioCtx = new AudioContext(options)
  }
  return audioCtx
}
