import {css} from '@emotion/react'

import logo from '../assets/scribe-logo.svg'
import error from '../assets/error.svg'
import Link from '../components/Link'

import BasePage from './BasePage'

const outerContainerCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 675px;
  margin: 100px auto;

  @media screen and (max-width: 675px) {
    width: 100%;
  }
`

const innerContainerCss = css`
  display: flex;
  gap: 25px;
  margin-bottom: 75px;
`

const errorCodeCss = css`
  margin: 0 0 25px;
  font-size: 18px;
  align-self: flex-start;
`

const logoCss = css`
  height: 98px;
  margin-bottom: 120px;
`

const numberCss = css`
  width: 315px;
`

const returnCss = css`
  display: inline-flex;
  justify-content: center;
  font-size: 16px;
  transition: all 300ms;
  background-color: #007694;
  padding: 15px 60px;
  border: none;
  color: white;
  text-decoration: none;
  align-self: center;

  &:hover {
    background-color: #005368;
  }
`

const infoCss = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const oopsCss = css`
  margin: 0;
  font-size: 48px;
  line-height: 58px;
`

const textCss = css`
  margin: 0;
  font-size: 28px;
  line-height: 34px;
`

export default function ErrorPage(): React.ReactNode {
  return (
    <BasePage>
      <div css={outerContainerCss}>
        <Link css={logoCss} to="/">
          <img src={logo} alt="Kensho Scribe" />
        </Link>
        <h3 css={errorCodeCss}>Error Code</h3>
        <div css={innerContainerCss}>
          <img css={numberCss} src={error} alt="Page Not Found" />
          <div css={infoCss}>
            <h1 css={oopsCss}>Oops…</h1>
            <h2 css={textCss}>Looks like this page doesn’t exist.</h2>
          </div>
        </div>
        <Link css={returnCss} to="/">
          Return Home
        </Link>
      </div>
    </BasePage>
  )
}
