import {useLogger} from '@kensho/lumberjack'
import clsx from 'clsx'
import {useContext, useMemo} from 'react'

import TranscriptContext from '../../../providers/TranscriptContext'
import {APITranscriptSlice, LabelAnnotation, Stage} from '../../../types/types'
import {produceOperation} from '../../../utils/transcriptPatchUtils'
import {TranscriptPermissionsContext} from '../TranscriptPermissionsProvider'

import {useDispatchEditOperation} from './DispatchEditOperationProvider'

interface Props {
  stage: Stage
  slice: APITranscriptSlice
  sliceIndex: number
}
export default function KeyQuestionToggle(props: Props): React.ReactNode {
  const {stage, slice, sliceIndex} = props
  const {dispatchEditOperation} = useDispatchEditOperation()
  const {transcript} = useContext(TranscriptContext)
  const {transcriptPermissions} = useContext(TranscriptPermissionsContext)
  const log = useLogger()

  const keyQuestionLabelAnnotation: LabelAnnotation | null = useMemo(() => {
    if (!transcript?.annotations) return null

    const annotationRef = slice.annotations?.find(
      ({id}) => transcript.annotations?.[id]?.source === 'label',
    )
    if (annotationRef) {
      const labelAnnotation = transcript.annotations[annotationRef.id]
      if (labelAnnotation.label === 'Key Question') {
        return labelAnnotation
      }
    }
    return null
  }, [slice.annotations, transcript?.annotations])

  function addKeyQuestion(): void {
    if (!transcript) return
    if (!transcriptPermissions.edit) return

    const existingLabelAnnotation = Object.values(transcript.annotations || {}).find(
      (annotation) => annotation.source === 'label' && annotation.label === 'Key Question',
    )

    const operation = produceOperation({
      action: {
        type: 'add-annotation',
        annotation: {
          source: 'label',
          knowledgeBase: 'custom',
          label: 'Key Question',
          type: 'manual',
          ...(existingLabelAnnotation ? {id: existingLabelAnnotation.id} : {}),
        },
        annotationId: existingLabelAnnotation?.id,
        ranges: [{start: {sliceIndex}, end: {sliceIndex}}],
      },
      transcript,
      transcriptSelection: null,
      log,
    })
    if (!operation) return

    dispatchEditOperation(operation)
  }

  function removeKeyQuestion(): void {
    if (!transcript || !keyQuestionLabelAnnotation) return
    if (!transcriptPermissions.edit) return

    const operation = produceOperation({
      action: {
        type: 'delete-annotation',
        annotationId: keyQuestionLabelAnnotation.id,
        ranges: [{start: {sliceIndex}, end: {sliceIndex}}],
      },
      transcript,
      transcriptSelection: null,
      log,
    })
    if (!operation) return

    dispatchEditOperation(operation)
  }

  if (stage !== 'POST_TRANSCRIPTION') return null

  return (
    <button
      type="button"
      onClick={() => {
        if (!transcriptPermissions.edit) return
        if (keyQuestionLabelAnnotation) {
          removeKeyQuestion()
        } else {
          addKeyQuestion()
        }
      }}
      className={clsx(
        'inline-flex h-6 flex-none select-none items-center rounded-2xl border px-2 py-1 text-xs transition-colors duration-200',
        keyQuestionLabelAnnotation
          ? `border-transparent bg-cyan-700 text-white`
          : `border border-gray-400 text-gray-400`,
      )}
    >
      Key question
    </button>
  )
}
