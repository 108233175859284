import enUSAffix from '../../assets/dictionaries/en-us.aff'
import enUSDictionary from '../../assets/dictionaries/en-us.dic'
import enUKAffix from '../../assets/dictionaries/en-uk.aff'
import enUKDictionary from '../../assets/dictionaries/en-uk.dic'

/** ISO 639-1 + region codes (en-us, en-uk, etc.) */
export type DictionaryCode = 'en-us' | 'en-uk'

interface DictionaryResource {
  langCode: DictionaryCode
  label: string
  affixFileURL: string
  dictionaryFileURL: string
}

export const DEFAULT_DICTIONARY: DictionaryCode = 'en-us'
export const DICTIONARY_STORE_KEY = 'dictionary'
export const CUSTOM_DICTIONARY_STORE_KEY = 'dictionary:custom'
export const DEFAULT_CUSTOM_DICTIONARY: string[] = []

/**
 * To add a new dictionary:
 *
 * 1. download the hunspell style dictionary (.dic) + affix (.aff) file (can use link below)
 * https://github.com/LibreOffice/dictionaries/tree/master
 * 2. add the dictionary (.dic) + affix (.aff) file to src/assets/dictionaries/<key>/*
 * 3. add the dictionary to the DICTIONARIES array below with matching key
 *
 * NOTE: only English language dictionaries are supported in SpellCheckProvider
 */
export const DICTIONARIES: DictionaryResource[] = [
  {
    langCode: 'en-us',
    label: 'US',
    affixFileURL: enUSAffix,
    dictionaryFileURL: enUSDictionary,
  },
  {
    langCode: 'en-uk',
    label: 'UK',
    affixFileURL: enUKAffix,
    dictionaryFileURL: enUKDictionary,
  },
]

export interface SpellCheckDictionary {
  langCode: DictionaryCode | null
  /** returns true if the word is spelled correctly, false otherwise */
  check: (word: string) => boolean
  /** returns a list of suggested spellings */
  suggest: (word: string) => string[]
  /** add a word to the dictionary */
  addWord: (word: string) => void
}

export const defaultDictionary = {
  langCode: null,
  check: () => true,
  suggest: () => [],
  addWord: () => {},
}
