/**
 * Converts seconds to [hh:m]m:ss
 *
 * @param [duration] seconds
 * @returns [hh:m]m:ss or --:-- if undefined
 */
export default function prettyDuration(
  duration?: number, // seconds
): string {
  if (duration === undefined) return '--:--'

  const remainder = Math.ceil(duration)
  const hours = Math.floor(remainder / 3600)
  const minutes = Math.floor((remainder - hours * 3600) / 60)
  const seconds = Math.ceil(remainder - hours * 3600 - minutes * 60)

  const hoursStr = hours < 10 ? `0${hours}` : hours
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes
  const secondsStr = seconds < 10 ? `0${seconds}` : seconds

  return `${hours > 0 ? `${hoursStr}:` : ''}${minutesStr}:${secondsStr}`
}
