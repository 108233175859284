import {AlertDialog, Dialog} from '@kensho/neo'

import SignInPrompt from './SignInPrompt'

interface SignInDialogProps {
  isOpen: boolean
  onClose?: () => void
}

export default function SignInDialog({isOpen, onClose}: SignInDialogProps): React.ReactNode {
  return onClose ? (
    <Dialog backdrop="light" isOpen={isOpen} title="Sign in" onClose={onClose}>
      <SignInPrompt />
    </Dialog>
  ) : (
    <AlertDialog backdrop="light" isOpen={isOpen} title="Sign in">
      <SignInPrompt />
    </AlertDialog>
  )
}
