import {Button, Popover} from '@kensho/neo'
import {useState} from 'react'

import VolumeSlider from '../../../components/VolumeSlider'

interface VolumeButtonProps {
  disabled: boolean
  volume: number
  onClick: () => void
  onChange: (volume: number) => void
}

export default function VolumeButton(props: VolumeButtonProps): React.ReactNode {
  const {disabled, volume, onChange, onClick} = props

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={disabled ? 'pointer-events-none' : 'pointer-events-auto'}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onBlur={() => setIsOpen(false)}
      onFocus={() => setIsOpen(true)}
    >
      <div className="absolute -mt-2 h-2 w-10" />
      <Popover
        content={
          <div className="cursor-pointer px-5 py-4">
            <VolumeSlider
              min={0}
              max={1}
              step={0.05}
              onChange={(event) => onChange(event)}
              value={volume}
              fill
            />
          </div>
        }
        position="top"
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        isModal={false}
      >
        <Button
          disabled={disabled}
          onClick={() => {
            if (isOpen) setIsOpen(true)
            onClick()
          }}
          icon={volume !== 0 ? 'SpeakerWaveIcon' : 'SpeakerXMarkIcon'}
          minimal
        />
      </Popover>
    </div>
  )
}
