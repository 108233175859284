/** Renders an offscreen textarea and copies the provided text from it */
export default function copyToClipboard(text: string): void {
  // save the current window selection
  const previousSelection = window.getSelection()
  const ranges = []
  if (previousSelection) {
    for (let i = 0; i < previousSelection.rangeCount; i += 1) {
      ranges.push(previousSelection.getRangeAt(i))
    }
  }

  const el = document.createElement('textarea')
  el.value = text
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  // restore the previous window selection
  const nextSelection = window.getSelection()
  if (nextSelection) {
    nextSelection.removeAllRanges()
    ranges.forEach((range) => {
      nextSelection.addRange(range)
    })
  }
}
