import {useCallback} from 'react'

/**
 * Create and returns a single callback ref composed from two other Refs.
 *
 * @param refA Callback or mutable Ref
 * @param refB Callback or mutable Ref
 * @example
 * const Button = React.forwardRef((props, ref) => {
 *   const [element, attachRef] = useCallbackRef<HTMLButtonElement>();
 *   const mergedRef = useMergedRefs(ref, attachRef);
 *
 *   return <button ref={mergedRef} {...props}/>
 * })
 */
export default function useMergedRef<T>(
  refA: React.Ref<T | HTMLElement>,
  refB: React.Ref<T | HTMLElement>,
): React.RefCallback<T> {
  const mergedRef = useCallback(
    (element: T) => {
      ;[refA, refB].forEach((ref) => {
        if (typeof ref === 'function') {
          ref(element)
        } else if (ref && typeof ref === 'object') {
          /* eslint-disable-next-line no-param-reassign */
          ;(ref as React.MutableRefObject<T>).current = element
        }
      })
    },
    [refA, refB],
  )

  return mergedRef
}
