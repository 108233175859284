import Cookies from 'js-cookie'
import {useState, useCallback} from 'react'

export default function useCookie<T>(name: string, defaultValue: T): [T, (newValues: T) => void] {
  const [cookieValue, setCookieState] = useState(() => {
    const cookie = Cookies.get(name)
    return cookie === undefined ? defaultValue : JSON.parse(cookie)
  })

  const setCookieValue = useCallback(
    (nextValue: T): void => {
      Cookies.set(name, JSON.stringify(nextValue), {expires: 365})
      setCookieState(nextValue)
    },
    [name],
  )

  return [cookieValue, setCookieValue]
}
