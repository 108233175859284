import './styles.css'
import {createRoot} from 'react-dom/client'
import {enablePatches} from 'immer'
import {RouterProvider} from 'react-router-dom'
import {StrictMode} from 'react'

import {wrappedCreateBrowserRouter} from './sentry'
import routes from './routes'
import AppLoggerProvider from './AppLoggerProvider'

const router = wrappedCreateBrowserRouter(routes)

const app = (
  <StrictMode>
    <AppLoggerProvider>
      <RouterProvider router={router} />
    </AppLoggerProvider>
  </StrictMode>
)

enablePatches()

const rootElement = document.createElement('div')
document.body.appendChild(rootElement)

const root = createRoot(rootElement)
root.render(app)
