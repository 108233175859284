import {Patch} from 'immer'
import {reverse, snakeCase} from 'lodash-es'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'

import useMultiplayerContext from '../../../hooks/useMultiplayerContext'
import {JSONPatch, MultiplayerServerMessage} from '../../../types/schemas'
import {ScribeError} from '../../../types/types'
import isPrimitive from '../../../utils/isPrimitive'
import snakeCaseDeep from '../../../utils/snakeCaseDeep'

const SAVE_DELAY_MS = 1000

export interface TranscriptEditQueue {
  push: (patches: Patch[]) => void
}

/**
 * Keeps a buffer of transcript edit operations and flushes them to the server every SAVE_DELAY_MS
 */
export default function useTranscriptEditQueue(options: {
  onError: (err: ScribeError) => void
  transcriptId?: string
  /* whether patches are flushed to the server */
  disabled?: boolean
}): TranscriptEditQueue {
  const {onError, transcriptId, disabled = false} = options
  const [prevTranscriptId, setPrevTranscriptId] = useState(transcriptId)
  const [queue, setQueue] = useState<Patch[]>([])
  const {authenticated, error, sessionId, sendMessage} = useMultiplayerContext(
    (message: MultiplayerServerMessage): void => {
      if (
        message.type !== 'error' ||
        (message.payload.code !== 'S2T-EDT-002' && message.payload.code !== 'S2T-EDT-003')
      )
        return

      onError({
        type:
          message.payload.code === 'S2T-EDT-002' ? 'saveTranscriptError' : 'saveTranscriptConflict',
        title: message.payload.title,
        detail: message.payload.detail,
      })
    },
  )

  // clear queue if transcriptId changes to avoid flushing patches to the wrong transcript
  if (transcriptId !== prevTranscriptId) {
    setPrevTranscriptId(transcriptId)
    setQueue([])
  }

  const flushQueue = useCallback(() => {
    if (error || disabled || !transcriptId || !sessionId || !authenticated || queue.length === 0)
      return

    const immerPatches = queue
    setQueue([])

    // Optimize patches sent over the wire, by removing intermediary "replace" patches for the same path
    // WARNING - this optimization is only safe because of 2 guarantees:
    // 1) Immer restricts patches to a subset of what the JSONPatch spec allows
    //    which does not include the "move" or "copy" operations.
    // 2) The transcript object does not have any paths that could be either a primitive or non-primitive value

    // reverse so it's easier to keep the last patch
    reverse(immerPatches)
    const replacedPaths: Record<string, boolean> = {}
    const optimizedPatches = immerPatches.reduce<Patch[]>((acc, patch) => {
      const path = patch.path.join('/')

      if (replacedPaths[path]) return acc

      if (patch.op === 'replace' && !replacedPaths[path] && isPrimitive(patch.value)) {
        replacedPaths[path] = true
      }

      acc.push(patch)
      return acc
    }, [])
    // reverse the patches back so that they will be applied in the correct order
    reverse(optimizedPatches)

    // three differences that we need to transform for patches stored on the client vs what the server expects:
    // 1. path keys need to be converted to snake_case
    // 2. paths need to be converted to JSON pointer strings instead of arrays
    // 3. values that are objects/arrays need to be converted to snake_case
    const optimizedJSONPatches = optimizedPatches.map((patch) =>
      snakeCaseDeep({
        ...patch,
        path: Array.isArray(patch.path)
          ? `/${patch.path
              .map((part) => (!Number(part) ? snakeCase(`${part}`) : `${part}`))
              .join('/')}`
          : patch.path,
      }),
    ) as JSONPatch[]

    sendMessage({
      type: 'patch',
      payload: {
        patches: optimizedJSONPatches,
        sessionId,
      },
    })
  }, [authenticated, disabled, error, queue, sendMessage, sessionId, transcriptId])

  const flushRef = useRef<() => void>(flushQueue)
  useEffect(() => {
    flushRef.current = flushQueue
  }, [flushQueue])

  // periodically flush the queue
  useEffect(() => {
    if (disabled) return undefined

    const intervalId = window.setInterval(() => {
      flushRef.current()
    }, SAVE_DELAY_MS)

    return () => {
      window.clearInterval(intervalId)
      flushRef.current()
    }
  }, [disabled])

  return useMemo(
    () => ({
      push: (patches: Patch[]): void => {
        setQueue((prev) => [...prev, ...patches])
      },
    }),
    [],
  )
}
